// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-dialog-container {
  padding: 35px;
}

button .material-icons {
  height: auto;
  width: auto;
}

.header-title {
  display: grid;
  background: #C8C8C8;
  font-weight: 600;
  height: 45px;
}

.header-title .title-text {
  padding: 10px;
}

.close-button {
  width: 30px !important;
  height: 30px !important;
}

.message-content {
  margin-top: 30px;
  text-align: center;
}

.dialog-message-title {
  font-size: 2.5rem;
  color: #FA8C17;
  padding-top: 3.5rem;
  padding-bottom: 2.5rem;
  font-weight: 700;
}

.mat-dialog-content {
  margin: 0 100px;
  padding: 0;
  font-size: 1rem;
  font-weight: 400;
}

.mat-dialog-actions {
  justify-content: center;
}

.message-container {
  position: relative;
}

.actions-wrapper {
  display: grid;
  gap: 1em;
  grid-template-columns: repeat(2, 160px);
  margin-top: 1em;
  justify-content: center;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
