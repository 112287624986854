import { createAction, props } from '@ngrx/store';
import { GeoViewVehicleDto } from '@services/api.service';
import { VehicleCoords } from '@models/overview';

export const setVehicles = createAction(
  'setVehicles',
  props<{ value: GeoViewVehicleDto[]; }>(),
);

export const setLoadingGeoview = createAction(
  'setLoadingGeoview',
  props<{ value: boolean }>(),
);

export const setTotalVehicles = createAction(
  'setTotalVehicles',
  props<{ value: number; }>(),
);
export const setSelectVehicle = createAction(
  'setSelectVehicle',
  props<{ value: GeoViewVehicleDto; }>(),
);
export const setActiveVehicles = createAction(
  'setActiveVehicles',
  props<{ value: number; }>(),
);

export const setVehiclesCoords = createAction(
  'setVehiclesCoords',
  props<{ value: VehicleCoords[]; }>(),
);
export const setGeoviewFilters = createAction(
  'setGeoviewFilters',
  props<{ filters: any; }>(),
);

export const getGeoviewVehicles = createAction(
  'getGeoviewVehicles',
  props<{ user_id: string }>(),
);
