import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EquipMgmtComponent } from '@portal/equip-mgmt/equip-mgmt.component';
import { EquipCardComponent } from '@portal/equip-mgmt/equip-card/equip-card.component';
import { EditDialogComponent } from '@portal/equip-mgmt/edit-dialog/edit-dialog.component';
import { CreateDialogComponent } from '@portal/equip-mgmt/create-dialog/create-dialog.component';
import { EquipFormComponent } from '@portal/equip-mgmt/equip-form/equip-form.component';
import { EquipDetailComponent } from '@portal/equip-mgmt/equip-detail/equip-detail.component';
import { EquipEditComponent } from '@portal/equip-mgmt/equip-edit/equip-edit.component';
import { EquipMapComponent } from '@portal/equip-mgmt/equip-map/equip-map.component';
import { EquipListComponent } from '@portal/equip-mgmt/equip-list/equip-list.component';
import { EquipMgmtRoutingModule } from '@portal/equip-mgmt/equip-mgmt-routing.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from '@helpers/error.interceptor';
import { MatIconModule } from '@angular/material/icon';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { CustomRouteReuseStrategy } from '@portal/equip-mgmt/reoute-invalidator';


@NgModule({
  declarations: [
    EquipListComponent,
    EquipEditComponent,
    EquipDetailComponent,
    EquipMgmtComponent,
    EditDialogComponent,
    EquipFormComponent,
    CreateDialogComponent,
    EquipMapComponent,
  ],
  imports: [
    EquipCardComponent,
    EquipMgmtRoutingModule,
    MatIconModule,
    CommonModule,
    LeafletModule,
    LeafletMarkerClusterModule,
    FormsModule,
    TranslateModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatDatepickerModule,
    RouterModule,
    MatProgressSpinner,
  ],
  exports: [
    EquipMgmtComponent,
    EquipListComponent,
    EquipEditComponent,
    EquipDetailComponent,
    EquipMgmtComponent,
    EditDialogComponent,
    EquipFormComponent,
    CreateDialogComponent,
    EquipMapComponent,
    MatProgressSpinner,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    CustomRouteReuseStrategy,
  ],

})
export class EquipMgmtModule {
}
