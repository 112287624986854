import { createAction, props } from '@ngrx/store';
import { Sort } from '@angular/material/sort';
import { UserNotificationPreferencesDto } from '@services/notification-api.service';

export const setNotificationsLoading = createAction(
  'setNotificationsLoading',
  props<{ value: boolean }>(),
);

export const setNotificationsFilters = createAction(
  'setNotificationsFilters',
  props<{ filters: any }>(),
);

export const getNotificationsList = createAction(
  'getNotificationsList',
  props<{
    pageIndex: number;
    pageSize: number;
    sort?: Sort;
  }>(),
);
export const getNotificationsSettings = createAction(
  'getNotificationsSettings',
);

export const setNotificationsSettings = createAction(
  'setNotificationsSettings',
  props<{ settings: UserNotificationPreferencesDto }>());
export const updateNotificationsSettings = createAction(
  'updateNotificationsSettings',
  props<{ settings: UserNotificationPreferencesDto }>());

export const setNotificationsList = createAction(
  'setNotificationsList',
  props<{
    notifications: any[],
    page: number,
    pageSize: number,
    totalNumber: number
  }>(),
);
