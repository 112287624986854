import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MaintenanceService, MaintenanceStatusDto, VehicleAlarmActiveDto } from '@services/api.service';
import { Store } from '@ngrx/store';
import { selectIsActiveAlarmsLoading, selectVehicleAlarmsActive } from '@redux/modules/tractorDetail';
import { getTractorActiveAlarms, setTractorActiveAlarms } from '@redux/modules/tractorDetail/actions';
import { filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { localeMap } from '@models/user';

@Component({
  selector: 'app-alarms-summary',
  templateUrl: './alarms-summary.component.html',
  styleUrls: ['./alarms-summary.component.scss'],
})
export class AlarmsSummaryComponent implements OnInit, OnDestroy {
  @Input() element: PeriodicElement;
  showMaintenanceTable: boolean;
  data: MaintenanceStatusDto;
  leftBar = undefined;
  doneBar = undefined;
  doneStatus = undefined;
  @Output() openModalEvent = new EventEmitter();
  displayedColumns: string[] = ['alarmStartDateTime', 'engineHours', 'ecuDisplayName', 'spn', 'fmi', 'count', 'severity', 'alarmDescription'];
  genericColumns: string[] = ['spn', 'fmi'];
  isLoading = true;
  locale;
  vehicleAlarmsActive: VehicleAlarmActiveDto;

  constructor(private maintenanceService: MaintenanceService,
              private store: Store,
              private translate: TranslateService,
              public dialog: MatDialog) {

    this.locale = localeMap[this.translate.currentLang];
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.store.select(selectVehicleAlarmsActive).pipe(filter(vehicleAlarmsActive => vehicleAlarmsActive != null))
      .subscribe(vehicleAlarmsActive => {
        this.vehicleAlarmsActive = vehicleAlarmsActive;
      });
    this.store.select(selectIsActiveAlarmsLoading).pipe(filter(isLoading => isLoading != null)).subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.store.dispatch(getTractorActiveAlarms({ vin: this.element.vin }));
  }

  ngOnDestroy(): void {
    this.store.dispatch(setTractorActiveAlarms({ vehicleAlarmsActive: [] }));
  }


  openModalLast() {
  }

  openModalNext() {
  }

  openModal() {
  }

  updateGauge(result) {
    if (result) {
      const hoursLeft = result.hoursLeft;
      const ratio = result.ratio || 100;
      const status = result.status;
      console.log('ratio: ', ratio);
      console.log('status: ', status);
      if (status === 'OK') {
        this.doneStatus = 'green';
      }
      if (status === 'EXPIRING') {
        this.doneStatus = 'yellow';
      }
      if (status === 'EXPIRED') {
        this.doneStatus = 'red';
      }
      this.leftBar = ratio;
      this.doneBar = 100 - ratio;
    }
  }
}

export interface PeriodicElement {
  vin: string;
  customer?: string;
  brand?: string;
  model?: string;
  engineHours?: number;
  maintenanceHours?: number;
  alarms?: number;
  alarmsSeverity?: string;
  lastMaintenanceDate?: string;
  lastMaintenanceHours?: number;
  nextMaintenaceDate?: string;
  missingHoursToNextMaintenance?: number;
  nextMaintenanceDays?: number;
}
