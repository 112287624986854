import { createReducer, on } from '@ngrx/store';
import * as Actions from './actions';
import * as RootActions from '../../actions';
import { GeoViewVehicleDto } from '@services/api.service';
import { VehicleCoords } from '@models/overview';

export interface State {
  vehicles: GeoViewVehicleDto[];
  totalVehicles: number;
  activeVehicles: number;
  vehiclesCoords: VehicleCoords[];
  selectedVehicle: GeoViewVehicleDto;
  loading: boolean;
  filters: any;
}


export const initialState: State = {
  vehicles: null,
  totalVehicles: null,
  activeVehicles: null,
  loading: true,
  selectedVehicle: null,
  vehiclesCoords: [],
  filters: {
    opened: true,
  },
};

export const selectGeoviewVehicles = state => state.geoview.vehicles;
export const selectTotalVehicles = state => state.geoview.totalVehicles;
export const selectActiveVehicles = state => state.geoview.activeVehicles;
export const selectVehiclesCoords = state => state.geoview.vehiclesCoords;
export const selectFilters = state => state.geoview.filters;
export const selectLoadingGeoview = state => state.geoview.loading;
export const selectSelectedVehicle = state => state.geoview.selectedVehicle;


export const geoviewReducer = createReducer(
  initialState,
  on(RootActions.resetState, () => ({ ...initialState })),
  on(Actions.setVehicles, (state, { value }) => ({ ...state, vehicles: value })),
  on(Actions.setTotalVehicles, (state, { value }) => ({ ...state, totalVehicles: value })),
  on(Actions.setActiveVehicles, (state, { value }) => ({ ...state, activeVehicles: value })),
  on(Actions.setVehiclesCoords, (state, { value }) => ({ ...state, vehiclesCoords: value })),
  on(Actions.setGeoviewFilters, (state, { filters }) => ({ ...state, filters: { ...state.filters, ...filters } })),
  on(Actions.setLoadingGeoview, (state, { value }) => ({ ...state, loading: value })),
  on(Actions.setSelectVehicle, (state, { value }) => ({ ...state, selectedVehicle: value })),
);


