import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, tap } from 'rxjs/operators';
import * as fromActions from './actions';
import { Store } from '@ngrx/store';
import { VehicleService } from '@services/api.service';
import { promiseWrapper } from '@shared/_funtions/promiseWarapper.function';
import { VehicleCoords } from '@models/overview';
import * as L from 'leaflet';
import * as _ from 'lodash';

@Injectable()
export class GeoviewEffects {
  private filters: any;
  private userId: string;
  private shouldTrigger: boolean;

  public getData$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getGeoviewVehicles),
    tap(action => {
      this.userId = action.user_id;
      this.store.dispatch(fromActions.setLoadingGeoview({ value: true }));
    }),
    switchMap(async () => {
      this.shouldTrigger = false;
      const hasAlarms = this.filters.vehicleStatus?.alarm;
      const dueMaintenance = this.filters.vehicleStatus?.maintenance;
      const isRunning = this.filters.vehicleStatus?.active;
      const dataTraffic = this.filters.dataTraffic;
      const vehiclesData = await promiseWrapper(this.vehicleService
        .getListByDealerQ(this.filters.q, isRunning, hasAlarms, dueMaintenance, dataTraffic),
      );

      const vehicles = vehiclesData.vehicles;
      const activeVehicles = vehiclesData.activeVehicles;
      const totalVehicles = vehiclesData.totalVehicles;
      const vehiclesCoords = vehiclesData.vehicles.map(vehicle =>
        new VehicleCoords(vehicle, new L.LatLng(vehicle.latitude, vehicle.longitude)),
      );
      return [
        fromActions.setVehicles({ value: (vehicles || []) }),
        fromActions.setActiveVehicles({ value: activeVehicles }),
        fromActions.setTotalVehicles({ value: totalVehicles }),
        fromActions.setVehiclesCoords({ value: vehiclesCoords }),
        fromActions.setLoadingGeoview({ value: false }),
      ];
    }),
    switchMap((actions) => [...actions]),
  ));

  public $setFilters = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.setGeoviewFilters),
      tap(action => {

        this.filters = { ...this.filters, ...action.filters };
        if (_.has(action.filters, 'vehicleStatus') || action.filters.q !== undefined || _.has(action.filters, 'dataTraffic')) {
          this.shouldTrigger = true;
        }
      }),
      switchMap((actions) => this.shouldTrigger ?
        [fromActions.getGeoviewVehicles({ user_id: this.userId }), fromActions.setLoadingGeoview({ value: true })]
        : []),
    );
  });


  constructor(
    private actions$: Actions,
    private store: Store,
    private vehicleService: VehicleService,
  ) {
    this.filters = {};
    this.userId = undefined;
  }
}
