import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, tap } from 'rxjs/operators';
import * as fromActions from './actions';
import { Store } from '@ngrx/store';
import { CustomerService } from '@services/api.service';
import { promiseWrapper } from '@shared/_funtions/promiseWarapper.function';

@Injectable()
export class GeoviewCustomerEffects {
  private filters: any;
  private shouldTrigger: boolean;

  public getData$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getGeoviewCustomerVehicles),
    tap(action => {
      if (action.filters.q != null) {
        this.filters.q = action.filters.q;
      }
      if (action.filters.vehicles != null) {
        this.filters.vehicles = action.filters.vehicles;
      }
      if (action.filters.equipments != null) {
        this.filters.equipments = action.filters.equipments;
      }
      if (action.filters.fields != null) {
        this.filters.fields = action.filters.fields;
      }

      this.store.dispatch(fromActions.setLoadingGeoviewCustomer({ value: true }));
    }),
    switchMap(async () => {
      this.shouldTrigger = false;
      let vehicles = true;
      let equipments = true;
      let fields = true;
      if (this.filters.vehicles != null) {
        vehicles = this.filters.vehicles;
        equipments = false;
        fields = false;
      }
      if (this.filters.equipments != null) {
        equipments = this.filters.equipments;
        vehicles = this.filters.vehicles || false;
        fields = false;
      }
      if (this.filters.fields != null) {
        fields = this.filters.fields;
        vehicles = this.filters.vehicles || false;
        equipments = this.filters.equipments || false;
      }
      if (!vehicles && !equipments && !fields) {
        vehicles = true;
        equipments = true;
        fields = true;
      }
      const vehiclesData = await promiseWrapper(this.customerService
        .getVehiclesFieldsEquipments(this.filters.q, vehicles, equipments, fields));
      // const vehicles = vehiclesData.vehicles;
      const entries = [
        ...vehiclesData.vehicles?.map(vehicle => ({
          ...vehicle,
          listType: 'vehicle',
        })) || [],
        ...vehiclesData.equipments?.map(equipment => ({
          ...equipment,
          listType: 'equipment',
        })) || [],
        ...vehiclesData.fields?.map(field => ({ ...field, listType: 'field' })) || [],
      ];
      const totalVehicles = entries.length;

      const geometries = [...vehiclesData.fields?.map(field => ({
        type: 'field',
        geometry: field.geometry.map(coord => [coord.latitude, coord.longitude]),
        original: field,
      })) || [],
        ...vehiclesData.vehicles?.map(vehicle => ({
          type: 'vehicle',
          geometry: [vehicle.latitude, vehicle.longitude],
          original: vehicle,
        })) || [],
        ...vehiclesData.equipments?.map(equipment => ({
          type: 'equipment',
          geometry: [equipment.latitude, equipment.longitude],
          original: equipment,
        })) || [],

      ];
      return [
        fromActions.setCustomerEntries({ value: (entries || []) }),
        fromActions.setTotalCustomerVehicles({ value: totalVehicles }),
        fromActions.setCustomerGeometries({ value: geometries }),
        fromActions.setLoadingGeoviewCustomer({ value: false }),
      ];
    }),
    switchMap((actions) => [...actions]),
  ));

  public $setFilters = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.setGeoviewCustomerFilters),
      tap(action => {
        if (action.filters.q != null) {
          this.filters.q = action.filters.q;
        }
        if (action.filters.vehicles != null) {
          this.filters.vehicles = action.filters.vehicles;
        }
        if (action.filters.equipments != null) {
          this.filters.equipments = action.filters.equipments;
        }
        if (action.filters.fields != null) {
          this.filters.fields = action.filters.fields;
        }
        this.shouldTrigger = true;
      }),
      switchMap((actions) => this.shouldTrigger ?
        [fromActions.getGeoviewCustomerVehicles({ filters: {} }), fromActions.setLoadingGeoviewCustomer({ value: true })]
        : []),
    );
  });


  constructor(
    private actions$: Actions,
    private store: Store,
    private customerService: CustomerService,
  ) {
    this.filters = {};
  }
}
