import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MaintenanceDialogComponent } from '@components/maintenance-dialog/maintenance-dialog.component';
import { MaintenanceService } from '@services/api.service';
import { ConfirmDialogComponent } from '@components/confirm-dialog/confirm-dialog.component';
import { getTractorMaintenanceHistory } from '@redux/modules/tractorDetail/actions';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { localeMap } from '@models/user';

@Component({
  selector: 'app-maintenance-table',
  templateUrl: './maintenance-table.component.html',
  styleUrls: ['./maintenance-table.component.scss'],
})
export class MaintenanceTableComponent implements OnInit {
  @Input() dataSource: any[];
  @Input() vin: string;
  displayedColumns: string[] = [
    'maintenanceDate',
    'maintenanceHours',
    'doneBy',
    'dealerSapCode',
    'notes',
    'actions',
  ];
  locale;
  constructor(
    private dialog: MatDialog,
    private maintenanceService: MaintenanceService,
    private store: Store,
    private translate: TranslateService,
  ) {

    this.locale = localeMap[this.translate.currentLang];
  }

  ngOnInit(): void {
  }

  openMaintenance(maintenance: any, view: boolean) {
    this.dialog.open(MaintenanceDialogComponent, {
      data: { maintenance, view },
    }).afterClosed().subscribe((result) => {
      this.store.dispatch(getTractorMaintenanceHistory({ vin: this.vin }));

    });
  }

  delete(maintenance: any) {
    console.log('delete', maintenance);
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Delete Maintenance',
        message: 'Are you sure you want to delete this maintenance?',
      },
    }).afterClosed().subscribe((result) => {
      if (result) {
        this.maintenanceService.deleteMaintenanceOperationsDone(maintenance.id).subscribe(() => {
          this.dataSource = this.dataSource.filter(item => item.id !== maintenance.id);
        });
      }
    });
  }
}
