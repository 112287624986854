import { createAction, props } from '@ngrx/store';
import { Sort } from '@angular/material/sort';

export const getAlarmList = createAction(
  'getVehicxlesAlarmsList',
  props<{
    pageIndex: number;
    pageSize: number;
    sort?: Sort;
  }>(),
);

export const setAlarmList = createAction(
  'setVehiclesAlarmsList',
  props<{
    alarmsList: any[],
    page: number,
    pageSize: number,
    totalNumber: number
  }>(),
);

export const setAlarmLoading = createAction(
  'setVehiclesAlarmsLoading',
  props<{ value: boolean }>(),
);

export const setSummaryData = createAction(
  'setVehiclesAlarmsSummaryData',
  props<{ summaryData: any }>(),
);

export const setVehiclesAlarmsFilters = createAction(
  'setVehiclesAlarmsFilters',
  props<{ filters: any }>(),
);