import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MessageService } from '../_services/message.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private messageService: MessageService,
        private translate: TranslateService,
        private router: Router,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {

            if (err.status === 401) {
            }
          if (err.status === 403) {
            this.router.navigate(['/portal/error/403']);
          }
            const error = err.error.error || err.statusText;

            if (err.status !== 409 && (request.url.includes('sdf_protected') || request.url.includes('ar_protected'))) {
                const message = error;
                this.messageService.openDialog(this.translate.instant('MessageDialog.Titles.Attention').toUpperCase(), message, false);
            }
            return throwError(err);
        }));
    }
}
