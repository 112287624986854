import { createAction, props } from '@ngrx/store';
import { ActiveAlarmCounterDto, ActiveAlarmDto } from '@services/api.service';
import { Sort } from '@angular/material/sort';

export const setActiveAlarmSummary = createAction(
  'setActiveAlarmSummary',
  props<{
    value: ActiveAlarmCounterDto
  }>(),
);

export const setActiveAlarmLoading = createAction(
  'setActiveAlarmLoading',
  props<{ value: boolean }>(),
);
export const setActiveAlarmFilters = createAction(
  'setActiveAlarmFilters',
  props<{ filters: any }>(),
);
export const getActiveAlarmList = createAction(
  'getActiveAlarmList',
  props<{
    pageIndex: number;
    pageSize: number;
    sort?: Sort;
  }>(),
);

export const getActiveAlarmSummary = createAction(
  'getActiveAlarmSummary',
);

export const setActiveAlarmList = createAction(
  'setActiveAlarmList',
  props<{
    vehicles: ActiveAlarmDto[],
    page: number,
    pageSize: number,
    totalNumber: number
  }>(),
);
