import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { userHierarchy, UserRole } from '@models/user';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@auth0/auth0-angular';
import { rolesCanCreate } from '../_utils/users';
import { MessageService } from './message.service';
import { Auth0UserDetailDto, DealerService, UserManagementService } from './api.service';
import { selectUser } from '@redux/index';
import { setHasDealer, setRolesCanCreate, setUser } from '@redux/actions';

@Injectable({ providedIn: 'root' })
export class UserService {
  userPopulate: Promise<boolean>;
  apiUrl = environment.apiURL;
  private user: Auth0UserDetailDto;

  constructor(
    private http: HttpClient,
    private storeService: Store,
    private store: Store,
    private translate: TranslateService,
    private auth: AuthService,
    private messageService: MessageService,
    private userManagementService: UserManagementService
  ) {
    let res;
    this.userPopulate = new Promise((resolve, reject) => res = resolve);
    this.store.select(selectUser).subscribe((user) => {
      this.user = user;
      res(true);
    });
  }

  createNewUser(user: Auth0UserDetailDto): Observable<any> {
    return this.http.post(this.apiUrl + `/sdf_protected/protected_create_user`, user)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(error => { throw new Error(error); })
      );
  }

  assignParentToChild(userId: string, parent: string): Observable<any> {
    const body = {
      user_id: userId,
      parent,
    };
    return this.http.put(this.apiUrl + `/sdf_protected/protected_assign_parent_to_child`, body)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(error => { throw new Error(error); })
      );
  }

  disassociateParentFromChild(userId: string): Observable<any> {
    const params = {
      user_id: userId
    }
    return this.http.delete(this.apiUrl + `/sdf_protected/protected_disassociate_parent_from_child`, { params })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(error => { throw new Error(error); })
      );
  }

  blockUser(userId: string, block: boolean): Observable<any> {
    const body = {
      user_id: userId,
      block,
    };
    return this.http.put(this.apiUrl + `/sdf_protected/protected_block_user`, body)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(error => { throw new Error(error); })
      );
  }

  deleteUser(userId: string): Observable<any> {
    const params = {
      user_id: userId
    }
    return this.http.delete(this.apiUrl + `/sdf_protected/protected_delete_user`, { params })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(error => { throw new Error(error); })
      );
  }

  updateUserInfo(user: Auth0UserDetailDto): Observable<any> {
    return this.http.put(this.apiUrl + `/sdf_protected/protected_user_update_info`, user)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(error => { throw new Error(error); })
      );
  }

  getUsers(userId: string, role?: string, email?: string, isOrphan?: string, parent?: string, page?: number): Observable<any> {
    const limit = 100;

    const params = {
      ...(userId) && { user_id: userId },
      ...(role) && { role },
      ...(email) && { email },
      ...(isOrphan) && { is_orphan: isOrphan },
      ...(parent) && { parent },
      ...(page && page !== null) && { page: page.toString() },
      ...(limit && limit !== null) && { limit: limit.toString() },
    };
    return this.http.get(this.apiUrl + `/sdf_protected/protected_search_users`, { params })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(error => { throw new Error(error); })
      );
  }

  resendVerificationEmail(email: string): Observable<any> {
    const body = {
      email,
    };
    return this.http.post(this.apiUrl + `/sdf_open/open_user_resend_email`, body)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(error => { throw new Error(error); })
      );
  }

  setUserInfo(userId: string) {
    return new Promise<any>((resolve, reject) => {
      this.userManagementService.getUserInfo().subscribe((userInfo) => {
          this.storeService.dispatch(setUser({ value: userInfo }));
        if (userInfo.user_metadata.language) {
          this.translate.use(userInfo.user_metadata.language).subscribe(() => {
            resolve(userInfo);
          }, _ => {
            this.translate.use('en');
            resolve(userInfo);
          })
        } else {
          this.translate.use('en');
          /*let message = "Set language in your profile";
          this.messageService.openDialog(this.translate.instant('MessageDialog.Titles.Attention').toUpperCase(), message, false);*/
          resolve(userInfo);
        }
      },
        error => {
          reject(error);
        });
    });
  }

  setUserHasDealerInfo(userId: string) {
    return new Promise<any>((resolve, reject) => {
      this.userManagementService.getHasDealerByUserId(userId).subscribe((hasDealerInfo) => {
          this.storeService.dispatch(setHasDealer({ value: hasDealerInfo }));
          resolve(hasDealerInfo);
        },
        error => {
          reject(error);
        });
    });
  }


  async hasRole(roles: string[]) {
    await this.userPopulate;
    return this.user && roles.includes(this.user.roles[0]);

  }

  async isChild(role: string) {
    await this.userPopulate;
    if (this.user) {
      const currentUserRole = this.user.roles[0];
      if (userHierarchy[currentUserRole] === role) {
        return true;
      }
    }
    return false;
  }

  checkIsAuth() {
    return new Promise<any>((resolve, reject) => {
      this.auth.isLoading$.subscribe((loading) => {
        if (loading === false) {
          this.auth.isAuthenticated$.subscribe((data) => {
            resolve(data);
            },
            error => {
              reject(error);
            })
        }
      })

    });
  }

  async setRolesCanCreate() {
    await this.userPopulate;
    const userType = this.user.roles[0];
    this.store.dispatch(setRolesCanCreate({ value: rolesCanCreate[userType] }));
  }

}
