import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import * as L from 'leaflet';
import { Marker, marker } from 'leaflet';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-equip-map',
  templateUrl: './equip-map.component.html',
  styleUrls: ['./equip-map.component.scss'],
})
export class EquipMapComponent implements OnInit, OnChanges, OnDestroy {

  @Input() position: any

  ngOnChanges(changes: SimpleChanges) {
  }

  map;
  options: any;
  positionMarker: Marker;
  constructor(
    public store: Store,
  ) {
  }

  ngOnInit() {
    this.showMap();
  }

  ngOnDestroy() {
  }

  /*
  * Show map.
  */
  showMap() {
    const centerLat = this.position.latitude;
    const centerLon = this.position.longitude;
    this.positionMarker = marker([centerLat, centerLon], {
      icon: L.divIcon({
        className: 'css-icon',
        html: '<div class="gps_ring"></div>',
        // iconSize: [30, 30]
      }),
    });
    this.options = {
      layers: [
        L.tileLayer('https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
          maxZoom: 20,
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        }),
        this.positionMarker
      ],
      zoom: 16,
      center: L.latLng(51.505, -0.09),
    };
  }

  onMapReady(map: L.Map): void {
    const centerLat = this.position.latitude;
    const centerLon = this.position.longitude;
    setTimeout(() => {
      map.invalidateSize();
    }, 100);
    this.map = map;
    const center = this.position;
    this.map.panTo(L.latLng(centerLat,centerLon));

  }

}
