// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-legals {
  background-color: #ededed;
  width: 100%;
  padding: 15px;
}

.logo-legals {
  width: 60px;
}

.text-legals {
  padding: 20px 50px 50px 50px;
}

.text-legals p {
  margin-bottom: 0;
}

.spinner-legals {
  margin: 50px auto auto;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
