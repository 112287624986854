// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  max-height: 98vh;
  overflow-y: auto !important;
  display: flex;
  flex-direction: column;
  padding: 0;
  position: relative;
}

.mat-mdc-form-field {
  margin-top: 16px;
}

.mat-form-field {
  width: 100% !important;
}

.header-title {
  background: #C8C8C8;
  font-weight: 600;
  height: 45px;
}

.header-title .title-text {
  padding: 10px;
}

.close-button {
  width: 30px !important;
  height: 30px !important;
}

.row {
  display: grid;
  gap: 3em;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin: 1em 0;
}

.row > * {
  padding: 0;
}

.icon-form-group {
  display: grid;
  gap: 1em;
  grid-template-columns: 30px 1fr;
  align-items: end;
}

.checks-wrapper {
  color: #555;
  padding: 1em 0.5em 0;
}

.edit-label.plain-label {
  margin-left: 0 !important;
}

.select-doneBy {
  padding-left: 25px;
}

.submit {
  margin-top: 2em;
}

.stepper-buttons-wrapper {
  display: flex;
  justify-content: center;
  gap: 1em;
  margin-top: 2em;
}

.option {
  display: grid;
  gap: 0.5em;
  grid-template-columns: 0.5fr 2fr 1fr 1fr;
  margin-block: 0.2em;
  align-items: center;
}

.option > div {
  white-space: break-spaces;
}

.options-headers {
  margin-left: 27px;
}

.options-headers > div {
  font-weight: 700;
  margin-top: 1em;
}

.option-selected-headers {
  margin-top: 1em;
}

.option-selected-headers > div {
  font-weight: 700;
}

.checkbox-headers {
  font-weight: 500;
  background: #D9D9D9;
  padding: 32px 16px !important;
}

.checkbox-selected-headers > div {
  font-weight: 700;
}

.check-option {
  color: #000;
  display: grid;
  gap: 0.5em;
  grid-template-columns: 80px 80px 2fr 80px 1fr;
  border-bottom: 1px solid #DEDEDE;
  font-size: 15px;
  padding: 16px !important;
}
.check-option:last-child {
  border-bottom: none;
}
.check-option .title-bold {
  font-weight: 700;
  display: flex;
  gap: 16px;
}
.check-option .option-description {
  display: flex;
  flex-direction: column;
  pointer-events: bounding-box;
}
.check-option .lubricant {
  display: grid;
  gap: 0.5em;
  grid-template-columns: 1fr 0.5fr 0.5fr;
}
.check-option .spare-part {
  display: grid;
  gap: 0.5em;
  grid-template-columns: 1fr 0.5fr 0.5fr;
}

::ng-deep .mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #FF8C17;
}

::ng-deep .mat-checkbox-checked.mat-checkbox-disabled.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: rgba(0, 0, 0, 0.2588235294);
}

::ng-deep .mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  border-radius: 4px;
}

::ng-deep .mat-mdc-checkbox {
  width: 100%;
  margin-top: -6px;
}
::ng-deep .mat-mdc-checkbox .mat-mdc-checkbox-layout {
  width: 100%;
}
::ng-deep .mat-mdc-checkbox .mat-mdc-checkbox-layout .mat-mdc-checkbox-label {
  width: 100%;
}

::ng-deep .mat-checkbox-inner-container {
  width: 24px;
  height: 24px;
}

::ng-deep .mat-checkbox-frame {
  border-radius: 4px;
}

::ng-deep .mat-mdc-checkbox {
  --mdc-checkbox-selected-checkmark-color: white;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
