import { createAction, props } from '@ngrx/store';
import { Sort } from '@angular/material/sort';
import { AssignedVehicleDto } from '@shared/_services/api.service';


export const setUserTractorsLoading = createAction(
  'setUserTractorsLoading',
  props<{ value: boolean }>(),
);
export const setUserTractorsFilters = createAction(
  'setUserTractorsFilters',
  props<{ filters: any }>(),
);
export const getUserTractorsList = createAction(
  'getUserTractorsList',
  props<{
    pageIndex: number;
    pageSize: number;
    sort?: Sort;
  }>(),
);

export const getUserTractorsSummary = createAction(
  'getUserTractorsSummary',
);

export const setUserTractorsList = createAction(
  'setUserTractorsList',
  props<{
    vehicles: AssignedVehicleDto[],
    page: number,
    pageSize: number,
    totalNumber: number,
    totalPages: number,
  }>(),
);
