import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectSbdFilters as selectSbdFilters, selectSbdSelectedVehicle, selectSbdVehicles } from '@redux/modules/sbd';
import { setSbdFilters, setSbdSelectVehicle } from '@redux/modules/sbd/actions';
import { SbdDto } from '@shared/_services/api.service';
import { StatusValue } from '@shared/_types/StatusMatrix';
import { Datasource } from 'ngx-ui-scroll';
import * as _ from 'lodash';
import { filter } from 'rxjs';
import { Workflow } from 'vscroll';
import { DateTime, Duration } from 'luxon';
import { selectUser } from '@redux/index';

@Component({
  selector: 'app-sbd-list',
  templateUrl: './sbd-list.component.html',
  styleUrls: ['./sbd-list.component.scss'],
})
export class SbdListComponent implements OnInit, OnDestroy {

  vehicles;
  queryValue: string;
  totalVehicles: number;
  @Input() isLoading: boolean;
  @Output() zoomOnThisVehicleEvent = new EventEmitter<SbdDto>();
  @Output() selectedVehicleStatusEvent = new EventEmitter<StatusValue>();
  selectedItem: SbdDto;
  allVehicles: SbdDto[];
  selectedVin: string;
  minValue: number = 0;
  maxValue: number = 100;
  workflow: Workflow;
  updateQueryTimeout: any;
  queryVehicles: SbdDto[] = null;
  private language: any;

  constructor(
    private router: Router,
    private store: Store,
  ) {
    this.vehicles = new Datasource({
      get: (index, count, success) => {
        console.log('get: ' + index + ' ' + count);
        const data = _.slice(this.allVehicles, index, index + count);
        success(data);

      },
      settings: {
        startIndex: 0,
        bufferSize: 10,
        minIndex: 0,
        padding: 0.5,

      },
    });

  }

  ngOnDestroy(): void {
    this.store.dispatch(setSbdSelectVehicle({ value: null }));
  }

  ngOnInit(): void {


    this.store.select(selectSbdFilters).pipe(filter(d => d)).subscribe((filters) => {
      this.minValue = filters.minValue || 0;
      this.maxValue = filters.maxValue || 100;
    });
    this.store.select(selectSbdVehicles).pipe(filter(vehicles => vehicles)).subscribe(vehicles => {

      this.totalVehicles = vehicles.length;
      this.allVehicles = vehicles;
      //this.vehicles.adapter.reload(0);
      this.isLoading = false;
    });
    this.store.select(selectUser).pipe(filter(user => user)).subscribe(user => {
      this.language = user.user_metadata.language;
    });
    this.store.select(selectSbdSelectedVehicle).pipe(filter(v => v)).subscribe((vehicle) => {
      console.log('selectedItem: ' + vehicle.vin);

      this.selectedItem = vehicle;
      if (vehicle) {
        setTimeout(() => {
          this.vehicles.adapter.reload(this.allVehicles.findIndex(v => v.vin === vehicle.vin));

        }, 10);
      }
    });
  }

  // setFilter(clear: boolean = false) {
  //   if (clear) {
  //     this.queryValue = '';
  //   }
  //   if (this.queryVehicles == null) {
  //     this.queryVehicles = this.allVehicles;
  //   }
  //   if (this.queryValue != null && this.queryValue != '') {
  //     this.allVehicles = this.queryVehicles.filter(v => v.customerBusinessName != null && v.vin.toLowerCase().includes(this.queryValue.toLowerCase()) || v.customerBusinessName != null && v.customerBusinessName.toLowerCase().includes(this.queryValue.toLowerCase()));
  //   } else {
  //     this.allVehicles = this.queryVehicles;
  //   }
  //   this.vehicles.adapter.reload(0);
  // }
  updateQuery(clear: boolean = false) {
    if (clear) {
      this.queryValue = '';
    }
    if (this.updateQueryTimeout) {
      clearTimeout(this.updateQueryTimeout);
    }
    this.updateQueryTimeout = setTimeout(() => {
      this.store.dispatch(setSbdFilters({ filters: { q: this.queryValue } }));
    }, 500);
  }

  onDetailClick(vehicle) {
    this.router.navigate([`/portal/tractor-mgmt/tractor-detail/${vehicle.vin}`]);
  }

  onVehicleSelection(vehicle: SbdDto) {
    this.selectedItem = vehicle;
    this.zoomOnThisVehicleEvent.emit(vehicle);
  }

  onRangeFilterSelection() {
    this.store.dispatch(setSbdFilters({ filters: { minValue: this.minValue, maxValue: this.maxValue } }));
  }

  getCircleColor(vehicle: SbdDto): string {
    const maxTime = 3 * 24 * 60;
    const startColor = '#6ed86e';
    const endColor = '#E9E95D';

    const timeLeft = maxTime - Math.abs(DateTime.fromJSDate(new Date(vehicle.sbdEndDateTime)).diffNow('minute').minutes);
    //gradient from green to yellow when time left is 0 full green, 3 days full yellow
    return `linear-gradient(90deg, ${startColor} ${100 - (timeLeft / maxTime) * 100}%, ${endColor} ${100 - (timeLeft / maxTime) * 100}%)`;
  }

  getDiffValue(vehicle: SbdDto) {
    const maxTime = 3 * 24 * 60;
    const diff = (Duration.fromObject({ minutes: maxTime }).minus(DateTime.local().diff(DateTime.fromJSDate(new Date(vehicle.sbdEndDateTime)), 'minute'))).reconfigure({
      locale: this.language,
    });
    return diff.shiftTo('days').days >= 1 ?
      diff.shiftTo('days').toHuman({
        maximumFractionDigits: 1,
      }) :
      diff.shiftTo('hours').toHuman({
        maximumFractionDigits: 0,
      });
  }
}
