// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-container {
  padding: 1em;
  position: relative;
  min-height: 40vh;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: auto;
  height: auto;
  z-index: 1;
}

.always-mobile .spinner-container {
  background: rgb(255, 255, 255);
}

.actions {
  margin-bottom: 2em;
}

.desktop-button {
  display: none;
}

.mat-dark-primary {
  background-color: #FF8C17;
  color: white;
  width: 200px;
}

.mat-dark-primary.mat-mdc-button-disabled {
  background-color: #C8C8C8;
}

.mat-primary {
  color: #FF8C17;
}

.section {
  margin-bottom: 2em;
}
.section h2 {
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}
.section .count-circle {
  display: grid;
  justify-content: center;
  align-items: center;
  margin-inline: 0.5em;
  padding: 0 4px;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  font-weight: 600;
  font-size: 13px;
  background: #ccc;
}
.section .cards {
  display: grid;
  gap: 0.6em 1em;
}

.page-container.always-mobile .section {
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .mobile-button {
    display: none;
  }
  .desktop-button {
    display: block;
  }
  .page-container:not(.always-mobile) .cards {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 1140px) {
  .page-container:not(.always-mobile) .cards {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (min-width: 1400px) {
  .page-container:not(.always-mobile) .cards {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
