import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap } from 'rxjs/operators';
import * as fromActions from './actions';
import { Store } from '@ngrx/store';
import {
  DataTraffic,
  DealerService,
  MaintenanceService,
  SbdService,
  UserManagementService,
  VehiclePositionDto,
  VehicleService,
  VehicleStatus,
} from '@services/api.service';
import { promiseWrapper } from '@shared/_funtions/promiseWarapper.function';
import { selectUser } from '@redux/index';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { selectVehicle } from '@redux/modules/tractorDetail/index';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from '@env';

@Injectable()
export class TractorDetailEffects {

  private isInDelay: boolean;
  private dataTraffic: DataTraffic;
  private vin: string;
  private language: string;
  private noPolling: boolean;
  private hubConnection: HubConnection;
  public getTractorActiveAlarms$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getTractorActiveAlarms),
    switchMap(async (action) => {
      this.store.dispatch(fromActions.setTractorActiveAlarmsLoading({ value: true }));
        let vehicleAlarmsActive;
        try {
          try {
            vehicleAlarmsActive = await promiseWrapper(this.vehicleService
              .getVehicleAlarmsByVin(action.vin, this.language));
          } catch (e) {
            console.log(e);
          }

        } catch (e) {

        }
      return [fromActions.setTractorActiveAlarms({ vehicleAlarmsActive }),
        fromActions.setTractorActiveAlarmsLoading({ value: false })];
      },
    )
    , switchMap((actions) => [...actions]),
  ))
  public startPolling$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.startPollingTractorDetail),
    tap(action => {
      this.isPollingActive = true;
      this.vin = action.vin;
      this.dataTraffic = action.dataTraffic;
      console.log('startPollingTractorDetail');
    }),
    map(() => fromActions.obtainTractorDetailJwt()),
  ));

  public getMaintenanceHistory$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getTractorMaintenanceHistory),
    switchMap(async (action) => {
      try {
        const vehicleMaintenanceHistory = await promiseWrapper(this.maintenanceService.getAllMaintenanceHistory(action.vin));
        return fromActions.setTractorMaintenanceHistory({ vehicleMaintenanceHistory });
      } catch (e) {
        return fromActions.setTractorMaintenanceHistory({ vehicleMaintenanceHistory: null });
      }
    }),
  ));

  public getMaintenance$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getTractorMaintenance),
    switchMap(async (action) => {
      try {
        const vehicleMaintenanceStatus = await promiseWrapper(this.maintenanceService
          .getMaintenanceStatus(action.vin, this.language));
        return fromActions.setTractorMaintenanceStatus({ vehicleMaintenanceStatus });

      } catch (e) {
        return fromActions.setTractorMaintenanceStatus({ vehicleMaintenanceStatus: null });

        }
      },
    )));
  public stopPolling$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.stopPollingTractorDetail),
    tap(_ => {
      this.isPollingActive = false;
      this.vin = undefined;
      this.dataTraffic = undefined;
      this.isInDelay = false;
      this.hubConnection.stop();
    }),
    switchMap(() => [
      fromActions.setTractorsDetailSummary({ vehiclePosition: undefined, meteo: undefined, data: undefined }),
    ]),
  ));
  private lastPosition: VehiclePositionDto;
  private isPollingActive: boolean;
  public getData$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getTractorDetail),
    tap(action => {
      this.vin = action.vin;
      this.noPolling = action.noPolling;
    }),
    tap(() => this.store.dispatch(fromActions.setTractorDetailLoading({ value: true }))),
    switchMap(async () => {
      let vehicle;
      let dataTraffic: DataTraffic;
      let vehicleDTechInfo;
      let vehicleMaintenance;
      let vehicleAlarmsActive;
      let vehicleWarranty;
      let vehicleOwner;
      let alarmHistoryCounter;
      let vehicleSummary;
      let vehiclePosition;
      let hasDealer;
      let sbd;
      try {
        const vin = this.vin;
        vehicle = await promiseWrapper(this.vehicleService
          .getVehicleDetailByVin(vin));
        dataTraffic = DataTraffic[vehicle.dataTraffic as keyof typeof DataTraffic];
        this.dataTraffic = dataTraffic;
        if (!this.noPolling) {
          this.store.dispatch(fromActions.startPollingTractorDetail({
            vin: this.vin,
            dataTraffic,
          }));
        }
        try {
          vehicleDTechInfo = await promiseWrapper(this.vehicleService
            .getVehicleDtechInfoByVin(vin));
        } catch (e) {
          console.log(e);
        }
        try {
          vehicleMaintenance = await promiseWrapper(this.vehicleService
            .getVehicleMaintenancesByVin(vin));
        } catch (e) {
          console.log(e);
        }
        try {
          vehicleAlarmsActive = await promiseWrapper(this.vehicleService
            .getVehicleAlarmsByVin(vin, this.language));
        } catch (e) {
          console.log(e);
        }
        try {
          hasDealer = await promiseWrapper(this.userManagementService
            .getHasDealerByUserId(vehicle.auth0UserId));
        } catch (e) {
          console.log(e);
        }
        try {

          const vehicleStatus = await promiseWrapper(this.vehicleService
            .getVehicleAlarmsCount(vin));
          alarmHistoryCounter = vehicleStatus.counter;
        } catch (e) {
          console.log(e);
        }
        try {
          vehicleWarranty = await promiseWrapper(this.vehicleService
            .getVehicleWarranty(vin));
        } catch (e) {
          console.log(e);
        }
        try {
          vehicleOwner = await promiseWrapper(this.dealerService
            .getCustomerInfo(vin));
        } catch (e) {
          console.log(e);
        }
        try {
          vehicleSummary = await promiseWrapper(this.vehicleService.getVehicleLastParamByVin(vin));
        } catch (e) {
          console.log(e);
        }
        try {
          vehiclePosition = await promiseWrapper(this.vehicleService.getVehicleLastPositionByVin(vin));
          this.lastPosition = vehiclePosition;
          this.syncData();
        } catch (e) {
          console.log(e);
        }
        try {
          sbd = await promiseWrapper(this.sbdService.getSbdScore(vin));
        } catch (e) {
          console.log(e);
        }
      } catch (e) {
        console.log(e);
      }
      return [
        fromActions.setTractorDetail({
          vehicle,
          vehicleAlarmsActive,
          vehicleOwner,
          vehicleMaintenance,
          sbd,
          vehicleDTechInfo,
          vehicleWarranty,
          alarmHistoryCounter,
          hasDealer,
        }),
        fromActions.setTractorsDetailSummary({ data: vehicleSummary, vehiclePosition }),
      ];
    }),
    switchMap((actions) => [...actions, fromActions.setTractorDetailLoading({ value: false })]),
  ));
  private telemetriesUrls = {
    0: environment.topconWsUrl,
    1: environment.btmWsUrl,
    2: environment.sdfWsUrl,
  };
  public obtainTractorDetailJwt$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.obtainTractorDetailJwt),
    tap(async () => {
      const accessToken = await promiseWrapper(this.auth.getAccessTokenSilently({
          cacheMode: 'off',
          timeoutInSeconds: 10,
        }),
      );
      this.hubConnection = new HubConnectionBuilder()
        .withUrl(`${this.telemetriesUrls[this.dataTraffic]}?access_token=${accessToken}`)
        .build();
      this.hubConnection.on('ReceivedMessage', (tractorData) => {

        const telemetries: any = {};
        tractorData.telemetries.forEach((item) => {
          telemetries[item.category] = item.physicalValue;
        });
        if (telemetries.engineSpeed != null && telemetries.engineSpeed < 600) {
          telemetries.vehicleStatus = VehicleStatus.NotRunning;
        }
        telemetries.paramsDateTime = tractorData.timeStamp;
        this.lastPosition = {
          latitude: tractorData.latitude,
          longitude: tractorData.longitude,
          messageReceivedDateTime: tractorData.timeStamp,
          altitude: tractorData.altitude,
        };
        this.store.dispatch(fromActions.setTractorsDetailSummary({ data: telemetries, vehiclePosition: this.lastPosition }));
      });
      this.hubConnection.start()
        .then(() => this.hubConnection.invoke('JoinVinGroup', {
          vin: this.vin,
        }))
        .catch((err) => console.log('error while establishing signalr connection: ' + err));
    }),
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private store: Store,
    private maintenanceService: MaintenanceService,
    private vehicleService: VehicleService,
    private sbdService: SbdService,
    private userManagementService: UserManagementService,
    private dealerService: DealerService,
    private httpClient: HttpClient,
    private auth: AuthService,
  ) {
    this.isPollingActive = false;
    this.isInDelay = false;
    this.dataTraffic = undefined;
    this.vin = undefined;
    this.store.select(selectUser).subscribe(user => {
      if (!user?.user_metadata) {
        return;
      }
      this.language = user?.user_metadata?.language || 'en';

    });
    this.store.select(selectVehicle).subscribe(vehicle => {
      if (!vehicle) {
        return;
      }
      this.dataTraffic = DataTraffic[vehicle.dataTraffic as keyof typeof DataTraffic];

    });
  }

  private async syncData() {
    console.log(this.isPollingActive, this.isInDelay);
    if (this.isPollingActive && !this.isInDelay && this.lastPosition) {
      let meteo;
      try {
        try {
          const
            data
              = await promiseWrapper<any>(this.httpClient.get(
              `https://api.open-meteo.com/v1/forecast`, {
                params: {
                  longitude: this.lastPosition.longitude,
                  latitude: this.lastPosition.latitude,
                  current: ['temperature_2m', 'relative_humidity_2m', 'weather_code', 'wind_speed_10m', 'wind_direction_10m', 'precipitation'].join(','),
                  hourly: ['relative_humidity_2m', 'wind_speed_10m', 'wind_direction_10m'].join(','),
                  daily: ['weather_code', 'precipitation_sum', 'rain_sum', 'showers_sum'].join(','),
                  timezone: 'Europe/Berlin',
                  past_days: 30,
                },
              }));
          meteo = {
            ...data.current,
            monthly_precipitations: Math.round(
              data.daily.precipitation_sum.slice(0, -6).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
              * 100) / 100,
          };
        } catch (e) {
          console.log(e);
        }
      } catch (e) {
        console.log(e);
      }
      this.store.dispatch(fromActions.setTractorsDetailSummary({ meteo }));
    }
  }
}
