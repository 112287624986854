import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerDto, GeoViewVehicleDto, VehicleDto } from '@services/api.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { OverviewService } from '@services/overview.service';
import { Store } from '@ngrx/store';
import { selectFiltersCustomer, selectGeoviewCustomerEntries } from '@redux/modules/geoview-customer';
import { setGeoviewCustomerFilters, setGeoviewCustomerSelected } from '@redux/modules/geoview-customer/actions';
import { StatusValue } from '@shared/_types/StatusMatrix';
import * as _ from 'lodash';
import { Item, makeDatasource, Workflow } from 'vscroll';
import { UserRole } from '@models/user';
import { typesIcons } from '@shared/_types/EquipDTO';

const Datasource = makeDatasource();

@Component({
  selector: 'app-search-tractor-customer',
  templateUrl: './search-tractor.component.html',
  styleUrls: ['./search-tractor.component.scss'],
})
export class SearchTractorCustomerComponent implements OnInit, OnDestroy {

  vehicles;
  items: Item<GeoViewVehicleDto>[] = [];
  @Input() activeVehicles: number;
  @Input() totalVehicles: number;
  // @Input() customers: CustomerDto[];
  @Input() selectedVehicle: VehicleDto;
  @Input() isLoading: boolean;
  @Output() zoomOnThisVehicleEvent = new EventEmitter<VehicleDto>();
  @Output() selectedCustomerEvent = new EventEmitter<CustomerDto>();
  @Output() selectedVinEvent = new EventEmitter<string>();
  @Output() selectedVehicleStatusEvent = new EventEmitter<StatusValue>();
  opened = true;
  selectedItem: any;
  allVehicles: GeoViewVehicleDto[];
  myControl = new FormControl('');
  filteredVins: Observable<VehicleDto[]>;

  enableSelectByCustomer = new FormControl(false);

  form = new FormGroup({
    auth0UserId: new FormControl(null),
    auth0UserEmail: new FormControl(null),
  });
  initialCustomer = '';
  selectedVin: string;
  subscription: Subscription;
  runningOnly = undefined;
  alarmsOnly = undefined;
  maintenanceOnly = undefined;
  vehiclesSelected = false;
  implementsSelected = false;
  fieldsSelected = false;
  workflow: Workflow;
  dataTraffic: string;
  Role = UserRole;

  constructor(
    private router: Router,
    private overviewService: OverviewService,
    private store: Store,
  ) {
    this.vehicles = new Datasource({
      get: (index, count, success) => {
        console.log('get: ' + index + ' ' + count);
        const data = _.slice(this.allVehicles, index, index + count);
        success(data);

      },
      settings: {
        startIndex: 0,
        bufferSize: 10,
        minIndex: 0,
        padding: 0.5,

      },
    });

  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {

    this.store.select(selectFiltersCustomer).pipe(filter(d => d)).subscribe((filters) => {
      if (!_.isNil(filters.vehicleStatus)) {
        this.vehiclesSelected = filters.vehicleStatus?.vehicles;
        this.implementsSelected = filters.vehicleStatus?.equipments;
        this.fieldsSelected = filters.vehicleStatus?.fields;
      }
      this.opened = filters.opened;
      this.selectedVin = filters.q;
    });
    this.store.select(selectGeoviewCustomerEntries).pipe(filter(vehicles => vehicles)).subscribe(vehicles => {

      this.totalVehicles = vehicles.length;
      this.allVehicles = vehicles;
      this.vehicles.adapter.reload(0);
      this.isLoading = false;
    });
    this.myControl.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      this.store.dispatch(setGeoviewCustomerFilters({ filters: { q: value } }));
    });
  }

  getEquipementIcon(type: string): string {
    const iconObj = typesIcons[type];
    return iconObj && iconObj.icon ? `fa-regular ${iconObj.icon}` : 'fa-regular fa-question-circle';
  }
  onEdit(event, item) {
    event.stopPropagation();
    this.selectedItem = item;

  }

  onDetailClick(vehicle) {
    this.router.navigate([`/portal/tractor-mgmt/tractor-detail/${vehicle.vin}`]);
  }

  onCenterZoomClick() {
    this.zoomOnThisVehicleEvent.emit(this.selectedItem);
  }

  onVehicleSelection(vehicle: GeoViewVehicleDto) {
    this.selectedItem = vehicle;
    this.store.dispatch(setGeoviewCustomerSelected({ value: vehicle }));
  }

  saveOpen(event) {
    this.store.dispatch(setGeoviewCustomerFilters({ filters: { opened: event } }));
  }

  onCustomerFilterSelection(customer: CustomerDto) {
    this.selectedCustomerEvent.emit(customer);
  }

  onVinFilterSelection(vin: string) {
    this.selectedVinEvent.emit(vin);
  }

  // onVehicleStatusFilterSelection($event: MatSlideToggleChange) {
  onVehicleStatusFilterSelection(val, key) {
    this[key] = val;

    this.store.dispatch(setGeoviewCustomerFilters({
      filters: {
        vehicles: this.vehiclesSelected,
        equipments: this.implementsSelected,
        fields: this.fieldsSelected,
      },
    }));
  }


  resetFilters() {
    this.vehiclesSelected = false;
    this.implementsSelected = false;
    this.fieldsSelected = false;
    this.store.dispatch(setGeoviewCustomerFilters({
      filters: {
        vehicles: this.vehiclesSelected,
        equipments: this.implementsSelected,
        fields: this.fieldsSelected,
      },
    }));
  }


  deselectCustomerFilter($event: MatCheckboxChange) {
    if (!$event.checked) {
      // emit a null select for disabling the Customer Filter
      this.initialCustomer = '';
      this.selectedCustomerEvent.emit(null);
    }
  }

  onVinFilterClear() {
    this.selectedVin = '';
    this.store.dispatch(setGeoviewCustomerFilters({ filters: { q: null } }));
  }

  isUnknown(severity) {
    return severity === 'Unknown';
  }

}
