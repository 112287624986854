import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, tap } from 'rxjs/operators';
import * as fromActions from './actions';
import { Store } from '@ngrx/store';
import { SbdService } from '@services/api.service';
import { promiseWrapper } from '@shared/_funtions/promiseWarapper.function';
import { VehicleSbdCoords } from '@models/overview';
import * as L from 'leaflet';
import * as _ from 'lodash';

@Injectable()
export class SbdEffects {
  private filters: any;
  private userId: string;
  private shouldTrigger: boolean;

  public getData$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getSbdVehicles),
    tap(action => {
      this.userId = action.user_id;
      this.store.dispatch(fromActions.setSbdLoading({ value: true }));
    }),
    switchMap(async () => {
      this.shouldTrigger = false;
      const minValue = this.filters.minValue;
      const maxValue = this.filters.maxValue;
      const query = this.filters.q;
      // TODO: Add query to the service function
      const vehicles = await promiseWrapper(this.sbdService
        .getSbdList(minValue, maxValue)
      );
      return [
        fromActions.setSbdVehicles({ value: (vehicles || []) }),
        fromActions.setSbdVehiclesCoords({ value: vehicles.map(vehicle =>
          new VehicleSbdCoords(vehicle, new L.LatLng(vehicle.latitude, vehicle.longitude)),
        ) }),
        fromActions.setSbdTotalVehicles({ value: vehicles.length }),
        fromActions.setSbdLoading({ value: false }),
      ];
    }),
    switchMap((actions) => [...actions]),
  ));

  public $setFilters = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.setSbdFilters),
      tap(action => {

        this.filters = { ...this.filters, ...action.filters };
        if (_.has(action.filters, 'minValue') || _.has(action.filters, 'maxValue') || action.filters.q !== undefined) {
          this.shouldTrigger = true;
        }
      }),
      switchMap((actions) => this.shouldTrigger ?
        [fromActions.getSbdVehicles({ user_id: this.userId }), fromActions.setSbdLoading({ value: true })]
        : []),
    );
  });


  constructor(
    private actions$: Actions,
    private store: Store,
    private sbdService: SbdService,
  ) {
    this.filters = {};
    this.userId = undefined;
  }
}
