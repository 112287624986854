import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MaintenanceHistoryDto, MaintenanceService, MaintenanceStatusDto } from '@services/api.service';
import { Store } from '@ngrx/store';
import { selectVehicleMaintenanceHistory, selectVehicleMaintenanceStatus } from '@redux/modules/tractorDetail';
import { getTractorMaintenance, getTractorMaintenanceHistory, setTractorMaintenanceStatus } from '@redux/modules/tractorDetail/actions';
import { filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MaintenanceAddModalComponent } from '../maintenance-add-modal/maintenance-add-modal.component';
import { MaintenanceStepsModalComponent } from '@components/maintenance-steps-modal/maintenance-steps-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { localeMap } from '@models/user';

@Component({
  selector: 'app-maintenance-summary',
  templateUrl: './maintenance-summary.component.html',
  styleUrls: ['./maintenance-summary.component.scss'],
})
export class MaintenanceSummaryComponent implements OnInit, OnDestroy {
  @Input() element: PeriodicElement;
  maintenanceTableData: MaintenanceHistoryDto[];
  showMaintenanceTable: boolean;
  data: MaintenanceStatusDto;
  leftBar = undefined;
  doneBar = undefined;
  doneStatus = undefined;
  @Output() openModalEvent = new EventEmitter();
  isLoading: boolean;
  locale;
  constructor(private maintenanceService: MaintenanceService,
              private store: Store,
              private translate: TranslateService,
    public dialog: MatDialog) {

    this.locale = localeMap[this.translate.currentLang];
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.store.select(selectVehicleMaintenanceStatus).pipe(filter((data) => data !== undefined)).subscribe(
      result => {
        this.data = result;
        this.updateGauge(result);
        this.isLoading = false;
      });
    this.store.select(selectVehicleMaintenanceHistory).pipe(filter((data) => data != null)).subscribe(
      result => {
        this.maintenanceTableData = result;
        this.showMaintenanceTable = true;
      });
    this.store.dispatch(getTractorMaintenance({ vin: this.element.vin }));
    this.store.dispatch(getTractorMaintenanceHistory({ vin: this.element.vin }));
  }

  ngOnDestroy(): void {
    this.store.dispatch(setTractorMaintenanceStatus({ vehicleMaintenanceStatus: undefined }));
  }

  openModal() {
    // this.showModal = true;
    let dialogRef;
    dialogRef = this.dialog.open(MaintenanceAddModalComponent, {
      width: '100%',
      height: 'auto',
      panelClass: 'add-modal',
      disableClose: true,
      data: { vin: this.element.vin, engineHours: this.element.engineHours },
    });
    dialogRef.afterClosed().subscribe(result => {
      this.openModalEvent.emit();
      this.store.dispatch(getTractorMaintenance({ vin: this.element.vin }));
      this.store.dispatch(getTractorMaintenanceHistory({ vin: this.element.vin }));
      // this.showModal = false;
    });
  }

  openModalLast() {
    // this.showModal = true;
    let dialogRef;
    dialogRef = this.dialog.open(MaintenanceStepsModalComponent, {
      width: '100%',
      height: 'auto',
      panelClass: 'add-modal',
      disableClose: true,
      data: {
        interval: this.data.pastExpiredMaintenancePlanCodeId,
        maintenanceHistoryId: this.data.pastExpiredMaintenanceHistoryId,
        maintenancePlanCodeId: this.data.pastExpiredMaintenancePlanCodeId,
        maintenancePlanCode: this.data.pastExpiredMaintenancePlanCode,
        maintenancePlanCodeDescription: this.data.pastExpiredMaintenancePlanCodeDescription,
        maintenanceHour: this.data.hoursPastExpiredMaintenance,
        maintenanceDate: this.data.datePastExpiredMaintenance,
      },
    });

  }

  openModalNext() {
    let dialogRef;
    dialogRef = this.dialog.open(MaintenanceStepsModalComponent, {
      width: '100%',
      height: 'auto',
      panelClass: 'add-modal',
      disableClose: true,
      data: {
        interval: this.data.maintenancePlanCodeId,
        maintenanceHistoryId: this.data.maintenanceHistoryId,
        maintenancePlanCode: this.data.maintenancePlanCode,
        maintenancePlanCodeDescription: this.data.maintenancePlanCodeDescription,
        maintenanceHour: this.data.hoursNext,
        maintenanceDate: this.data.dateNext,


      },
    });
  }

  updateGauge(result) {
    if (result) {
      const hoursLeft = result.hoursLeft;
      const ratio = result.ratio || 100;
      const status = result.status;
      console.log('ratio: ', ratio)
      console.log('status: ', status)
      if (status === 'OK') {
        this.doneStatus = 'green';
      }
      if (status === 'EXPIRING') {
        this.doneStatus = 'yellow';
      }
      if (status === 'EXPIRED') {
        this.doneStatus = 'red';
      }
      this.leftBar = ratio;
      this.doneBar = 100 - ratio;
    }
  }
}

export interface PeriodicElement {
  vin: string;
  customer?: string;
  brand?: string;
  model?: string;
  engineHours?: number;
  maintenanceHours?: number;
  alarms?: number;
  alarmsSeverity?: string;
  lastMaintenanceDate?: string;
  lastMaintenanceHours?: number;
  nextMaintenaceDate?: string;
  missingHoursToNextMaintenance?: number;
  nextMaintenanceDays?: number;
}
