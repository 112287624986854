import { createReducer, on } from '@ngrx/store';
import * as Actions from './actions';
import * as RootActions from '../../actions';
import { SbdDto } from '@services/api.service';
import { VehicleCoords } from '@models/overview';

export interface State {
  vehicles: SbdDto[];
  totalVehicles: number;
  vehiclesCoords: VehicleCoords[];
  selectedVehicle: SbdDto;
  loading: boolean;
  filters: any;
}


export const initialState: State = {
  vehicles: null,
  totalVehicles: null,
  loading: true,
  selectedVehicle: null,
  vehiclesCoords: [],
  filters: {
    opened: true,
  },
};

export const selectSbdVehicles = state => state.sbd.vehicles;
export const selectSbdTotalVehicles = state => state.sbd.totalVehicles;
export const selectSbdVehiclesCoords = state => state.sbd.vehiclesCoords;
export const selectSbdFilters = state => state.sbd.filters;
export const selectSbdLoading = state => state.sbd.loading;
export const selectSbdSelectedVehicle = state => state.sbd.selectedVehicle;


export const sbdReducer = createReducer(
  initialState,
  on(RootActions.resetState, () => ({ ...initialState })),
  on(Actions.setSbdVehicles, (state, { value }) => ({ ...state, vehicles: value })),
  on(Actions.setSbdTotalVehicles, (state, { value }) => ({ ...state, totalVehicles: value })),
  on(Actions.setSbdVehiclesCoords, (state, { value }) => ({ ...state, vehiclesCoords: value })),
  on(Actions.setSbdFilters, (state, { filters }) => ({ ...state, filters: { ...state.filters, ...filters } })),
  on(Actions.setSbdLoading, (state, { value }) => ({ ...state, loading: value })),
  on(Actions.setSbdSelectVehicle, (state, { value }) => ({ ...state, selectedVehicle: value })),
);


