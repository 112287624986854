import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { getMaintenanceList, getMaintenanceSummary } from '@redux/modules/maintenance/actions';
import {
  selectIsMaintenanceLoading,
  selectMaintenancePage,
  selectMaintenanceTotalNumber,
  selectMaintenanceVehicles,
} from '@redux/modules/maintenance';
import { MaintenanceDto } from '@shared/_services/api.service';
import { Sort } from '@angular/material/sort';
import { PaginatorComponent } from '@components/paginator.component';
import { TranslateService } from '@ngx-translate/core';
import { localeMap } from '@models/user';

@Component({
  selector: 'app-maintenance-list',
  templateUrl: './maintenance-list.component.html',
  styleUrls: ['./maintenance-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MaintenanceListComponent implements OnInit, AfterViewInit {
  expandedElement: PeriodicElement | null;
  dataSource = new MatTableDataSource<MaintenanceDto>();
  currentVin: string = null;
  locale;
  @ViewChild('paginator', { static: false }) paginator: PaginatorComponent;
  page = 1;
  maintenanceHighSeverity = 'EXPIRED';
  maintenanceMediumSeverity = 'EXPIRING';
  maintenanceLowSeverity = 'OK';
  totalNumber: number;
  showModal: boolean;
  maintenanceStatusData: object;
  columnDefs: string[] = [
    'vin',
    'businessName',
    'engineHours',
    'missingHoursToNextMaintenance',
    'actions'
  ];
  columnsToDisplayWithExpand: string[] = [
    'vin',
    'businessName',
    'engineHours',
    'missingHoursToNextMaintenance',
    'actions'
  ];
  isLoading: boolean;
  sort: Sort;

  constructor(
    private store: Store,
    private translate: TranslateService,
  ) {

    this.locale = localeMap[this.translate.currentLang];
  }

  ngOnInit(): void {
  }

  closeModal(event) {
    if (event.target.classList.contains('modal-overlay')) {
      this.showModal = false;
    }
  }

  pageChanged(event) {
    this.store.dispatch(getMaintenanceList({ pageIndex: event, pageSize: 10, sort: this.sort }));
  }

  ngAfterViewInit() {

    this.store.dispatch(getMaintenanceList({ pageIndex: 1, pageSize: 10 }));
    this.store.dispatch(getMaintenanceSummary());

    this.store.select(selectIsMaintenanceLoading).subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
    this.store.select(selectMaintenancePage).subscribe((page) => {
      console.log('page', page);
      this.page = page;
      if (page === 1) {
        this.paginator.resetPage();
      }
    });
    this.store.select(selectMaintenanceVehicles).subscribe((vehicles) => {
      this.dataSource.data = vehicles;

    });

    this.store.select(selectMaintenanceTotalNumber).subscribe((totalNumber) => {
      console.log('totalNumber', totalNumber);
      this.totalNumber = totalNumber;
    });
  }

  isUnknown(severity) {
    return severity === 'Unknown';
  }

  updateList() {
    this.store.dispatch(getMaintenanceList({ pageIndex: this.page, pageSize: 10, sort: this.sort }));
  }

  sortTable(sort: Sort) {
    this.paginator.resetPage();
    this.sort = sort;
    this.store.dispatch(getMaintenanceList({ pageIndex: 1, pageSize: 10, sort }));
  }
}

export interface PeriodicElement {
  vin: string;
  customer: string;
  brand: string;
  model: string;
  engineHours: number;
  maintenanceHours: number;
  alarms: number;
  alarmsSeverity: string;
  lastMaintenanceDate: string;
  lastMaintenanceHours: number;
  nextMaintenaceDate: string;
  missingHoursToNextMaintenance: number;
  nextMaintenanceDays: number;
}
