// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputs-grid {
  display: grid;
  gap: 2em;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
}

.input-wrapper {
  background: #F2F2F2;
  padding: 0.95em;
  width: 100%;
}

.input-wrapper > div {
  position: relative;
}

.basic-input {
  border: none;
  padding: 0.3em;
  font-size: 14px;
  background: #fff;
  width: 100%;
}

.add-account-container {
  padding: 35px;
  height: 100%;
}

select.form-control.is-invalid {
  background-image: none;
}

.mat-dialog-content {
  padding: 20px;
  font-size: 1rem;
  font-weight: 400;
}

.dialog-content {
  padding: 0;
}

.is-selected .mat-select-value {
  color: #757d85;
}

.section-title {
  background: none;
  font-weight: 600;
  font-size: 24px;
  margin: 1em 0;
}

.form-control {
  font-size: 1rem;
}

.edit-label {
  margin-bottom: 0;
  text-align: left;
  width: 100%;
  font-size: 0.6rem;
  margin-left: 1.5rem;
  font-weight: 600;
  color: #666466;
  text-transform: uppercase;
}

.mat-dialog-actions {
  justify-content: center;
}

.action-button {
  padding: 0.5rem 3rem;
  margin-left: 8px;
}

.add-account-dialog-content {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.restore-language-icon {
  margin-top: 40px;
  cursor: pointer;
}

.user-icon {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
}

.user-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 70px;
  height: 70px;
}

.user-error-file-size {
  font-size: 80%;
  color: #dc3545;
  position: absolute;
}

.checkbox-description {
  margin-left: 1em;
}

button svg {
  display: block;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: white;
}

.header-title {
  background: #C8C8C8;
  color: black;
  font-weight: 600;
  height: 45px;
}

.header-title .title-text {
  padding: 10px;
}

.close-button {
  padding: 6px !important;
  width: 30px !important;
  height: 30px !important;
}

.material-icons {
  display: block;
  height: auto;
  width: auto;
}

.form-group {
  align-items: center;
}

.checkbox-description {
  margin-bottom: 0;
}

.btn-primary, .btn-secondary {
  border-radius: 0;
}

.stepper-buttons {
  display: grid;
  gap: 1em;
  grid-template-columns: repeat(2, 190px);
  margin-top: 1em;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
