// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-container {
  padding: 0.6em 1em 0.6em;
}

.form-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}

.form-wrapper > div {
  width: 100%;
  max-width: 100%;
}

.input-wrapper label {
  display: block;
  font-size: 14.2px;
}
.input-wrapper input, .input-wrapper select {
  background: #fff;
  border: none;
  padding: 0.4em 0.7em;
  margin: 0.5em 0 0;
  height: 40px;
  width: 100%;
}

::ng-deep .mat-mdc-form-field-infix {
  min-height: unset !important;
  padding: 0 !important;
}

.in-card .input-wrapper {
  margin: 0;
}

input:disabled, select:disabled {
  background: #f2f2f2;
}

.map-element {
  background: rgba(0, 0, 0, 0.2);
  height: 500px;
  width: 100%;
  display: grid;
  align-items: end;
  position: relative;
}
.map-element ::ng-deep .leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  height: 100%;
}

.input-with-button {
  display: flex;
  gap: 1em;
  align-items: flex-end;
}
.input-with-button input {
  height: 40px;
}

.keys {
  display: flex;
  gap: 1em;
  margin: 1em 0 1.5em;
}
.keys .circle {
  background-color: #ccc;
  border-radius: 50%;
  height: 18px;
  width: 18px;
}
.keys .actual {
  border: 1px solid #FF8C17;
}
.keys .actual .circle {
  background: #FF8C17;
}
.keys .imported {
  border: 1px solid #0099FF;
}
.keys .imported .circle {
  background: #0099FF;
}

.keys > div {
  display: flex;
  gap: 0.5em;
  align-items: center;
  border-radius: 6px;
  font-size: 0.8em;
  padding: 0.5em;
}

.sdf-button {
  border-radius: 3px;
  padding: 0.5em 1em;
}

.btn-negative {
  background: #fff;
  color: #D50B0F;
  border: 1px solid #D50B0F;
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

.action-buttons {
  text-align: center;
  display: flex;
  gap: 1em;
  justify-content: center;
}
.action-buttons .sdf-button {
  width: 230px;
  height: 32px;
}

@media screen and (min-width: 768px) {
  .form-wrapper > div {
    width: calc(50% - 1rem);
  }
}
@media screen and (min-width: 1024px) {
  .action-buttons {
    text-align: center;
  }
  .form-wrapper > div {
    width: calc(33% - 1rem);
  }
}
@media screen and (min-width: 1200px) {
  .in-card .form-wrapper > div {
    width: calc(33% - 1rem);
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
