import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchTractorCustomerComponent } from './search-tractor/search-tractor.component';
import { EquipMgmtModule } from '@portal/equip-mgmt/equip-mgmt.module';
import { FieldsMgmtModule } from '@portal/fields-mgmt/fields-mgmt.module';
import { MapCustomerComponent } from './map/map.component';
import { GeoviewCustomerComponent } from './geoview/geoview.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from '@helpers/error.interceptor';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatTableModule } from '@angular/material/table';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCardModule } from '@angular/material/card';
import { MatSortModule } from '@angular/material/sort';
import { MatExpansionModule } from '@angular/material/expansion';
import { SharedModule } from '@shared/shared.module';
import { UiScrollModule } from 'ngx-ui-scroll';
import { MatLineModule } from '@angular/material/core';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
  declarations: [
    SearchTractorCustomerComponent,
    MapCustomerComponent,
    GeoviewCustomerComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    MatDividerModule,
    MatToolbarModule,
    RouterModule,
    MatButtonModule,
    MatSelectModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSkeletonLoaderModule,
    MatIconModule,
    MatMenuModule,
    MatListModule,
    LeafletModule,
    LeafletMarkerClusterModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatCardModule,
    InfiniteScrollModule,
    MatProgressSpinnerModule,
    ScrollingModule,
    MatTableModule,
    AngularSvgIconModule,
    MatPaginatorModule,
    MatCardModule,
    MatSortModule,
    MatExpansionModule,
    UiScrollModule,
    MatLineModule,
    EquipMgmtModule,
    FieldsMgmtModule,
    MatTooltipModule
  ], providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }],

  exports: [],
})
export class OverviewCustomerModule {
}
