// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  height: 100%;
  width: 100%;
}

.map {
  height: 100%;
  width: 100%;
}

.overlay {
  border-radius: 25px;
  height: 60%;
  z-index: 1000;
  position: absolute;
  top: 250px;
  width: 385px;
  margin: 0 15px;
  background: #F8F8FA;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
