import { createAction, props } from '@ngrx/store';
import { SbdDto } from '@services/api.service';
import { VehicleCoords } from '@models/overview';

export const setSbdVehicles = createAction(
  'setSbdVehicles',
  props<{ value: SbdDto[]; }>(),
);

export const setSbdLoading = createAction(
  'setSbdLoading',
  props<{ value: boolean }>(),
);

export const setSbdVehiclesCoords = createAction(
  'setSbdVehiclesCoords',
  props<{ value: VehicleCoords[]; }>(),
);

export const setSbdTotalVehicles = createAction(
  'setSbdTotalVehicles',
  props<{ value: number; }>(),
);
export const setSbdSelectVehicle = createAction(
  'setSbdSelectVehicle',
  props<{ value: SbdDto; }>(),
);
export const setSbdFilters = createAction(
  'setSbdFilters',
  props<{ filters: any; }>(),
);

export const getSbdVehicles = createAction(
  'getSbdVehicles',
  props<{ user_id: string }>(),
);
