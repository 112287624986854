import { NgModule } from '@angular/core';
import { EquipEditComponent } from '@portal/equip-mgmt/equip-edit/equip-edit.component';
import { EquipDetailComponent } from '@portal/equip-mgmt/equip-detail/equip-detail.component';
import { EquipListComponent } from '@portal/equip-mgmt/equip-list/equip-list.component';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    component: EquipListComponent,
    data: {
      title: 'Equip Management',
      reuse: true,
    }
  },
  {
    data: {
    },
    path: 'equip-detail/:id',
    component: EquipDetailComponent,
  },
  {
    data: {
    },
    path: 'equip-edit/:id',
    component: EquipEditComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EquipMgmtRoutingModule {
}
