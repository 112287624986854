import { createReducer, on } from '@ngrx/store';
import * as Actions from './actions';
import * as RootActions from '../../actions';
import { GeoViewVehicleDto } from '@services/api.service';

export interface CustomerGeometry {
  type: string;
  geometry: number[] | number[][];
  original: any;
}
export interface State {
  customerEntries: GeoViewVehicleDto[];
  totalCustomerEntries: number;
  customerGeometries: CustomerGeometry[];
  loadingCustomer: boolean;
  filtersCustomer: any;
  selectedEntry: any;
}


export const initialState: State = {
  customerEntries: [],
  totalCustomerEntries: null,
  loadingCustomer: true,
  selectedEntry: null,
  customerGeometries: [],
  filtersCustomer: {
    opened: true,
  },
};

export const selectGeoviewCustomerEntries = state => state.geoviewCustomer.customerEntries;
export const selectTotalCustomerEntries = state => state.geoviewCustomer.totalCustomerEntries;
export const selectCustomerGeometries = state => state.geoviewCustomer.customerGeometries;
export const selectCustomerGeoviewSelectedEntry = state => state.geoviewCustomer.selectedEntry;
export const selectFiltersCustomer = state => state.geoviewCustomer.filtersCustomer;
export const selectLoadingGeoviewCustomer = state => state.geoviewCustomer.loadingCustomer;


export const geoviewCustomerReducer = createReducer(
  initialState,
  on(RootActions.resetState, () => ({ ...initialState })),
  on(Actions.setCustomerEntries, (state, { value }) => ({ ...state, customerEntries: value })),
  on(Actions.setTotalCustomerVehicles, (state, { value }) => ({ ...state, totalCustomerEntries: value })),
  on(Actions.setGeoviewCustomerSelected, (state, { value }) => ({ ...state, selectedEntry: value })),
  on(Actions.setCustomerGeometries, (state, { value }) => ({ ...state, customerGeometries: value })),
  on(Actions.setGeoviewCustomerFilters, (state, { filters }) => ({ ...state, filtersCustomer: { ...state.filtersCustomer, ...filters } })),
  on(Actions.setLoadingGeoviewCustomer, (state, { value }) => ({ ...state, loadingCustomer: value })),
);


