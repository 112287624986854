import _ from 'lodash';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Auth0UserDetailDto, MaintenanceService, MissingMaintenanceDto } from '@services/api.service';
import { Store } from '@ngrx/store';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { selectUser } from '@redux/index';
import { filter } from 'rxjs/operators';
import { UserRole } from '@models/user';

@Component({
  selector: 'app-maintenance-add-modal',
  templateUrl: './maintenance-add-modal.component.html',
  styleUrls: ['./maintenance-add-modal.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class MaintenanceAddModalComponent implements OnInit {
  // @Input() vin: string;
  vin: string;
  engineHours: string;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  submitted = false;
  maintenancePlanCode: string;
  missingMaintenances: MissingMaintenanceDto[];
  groupedChecks: any;
  checksSummary = [];
  mappedChecks = [];
  currentMaintenanceInterval = undefined;
  dealerSelected = false;
  dealersList = [
    { value: 'Dealer', label: 'Dealer'},
    { value: 'Customer', label: 'Customer'},
  ];
  today: Date = new Date();
  userRole: string;
  user: Auth0UserDetailDto;
  language: string;

  constructor(
    public dialogRef: MatDialogRef<MaintenanceAddModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder, private maintenanceService: MaintenanceService, private store: Store) {
  }

  get f() {
    return this.firstFormGroup.controls;
  }

  ngOnInit(): void {
    this.vin = this.data.vin;
    this.engineHours = this.data.engineHours;
    this.store.select(selectUser).pipe(filter(user => user != null)).subscribe(user => {
      this.user = user;
      this.userRole = this.user.roles[0];
      this.language = this.user.user_metadata.language;
    });

    this.firstFormGroup = this.formBuilder.group({
      vin: [this.vin, Validators.required],
      doneBy: ['', Validators.required],
      date: [this.today, Validators.required],
      engineHours: [this.engineHours, Validators.required],
      maintenanceInterval: ['', Validators.required],
    });
    this.secondFormGroup = this.formBuilder.group({
      notes: [''],
    });
    this.thirdFormGroup = this.formBuilder.group({});
    this.maintenanceService.getMissingMaintenance(this.vin, this.language).subscribe(result => {
      this.missingMaintenances = result;
      this.firstFormGroup.controls.maintenanceInterval.setValue(this.missingMaintenances[0].maintenancePlanCodeId);
    });
  }

  getChecks() {
    const currentInterval = this.firstFormGroup.controls.maintenanceInterval.value;
    this.currentMaintenanceInterval = this.missingMaintenances.find((v) => v.maintenancePlanCodeId === currentInterval);
    this.maintenanceService.getMaintenanceOperations(this.firstFormGroup.controls.maintenanceInterval.value, this.language)
      .subscribe(result => {
        console.log(result);
        if (result) {
          const groupedChecks = _.groupBy(result, 'type');
          this.secondFormGroup = this.formBuilder.group(
            {
              ..._.reduce(groupedChecks, (acc, v, k) => ({ ...acc, [k]: this.formBuilder.array(v.map(() => true)) }), {}),
              notes: [''],
            },
          );
          this.groupedChecks = groupedChecks;
        }
    });
  }
  gotoSummary() {
    console.log(this.secondFormGroup.value);
    this.mappedChecks = _.reduce(this.groupedChecks, (acc, v, k) =>
        [...acc, ...this.secondFormGroup.value[k].map((bool, index) => ({ bool, index }))
          .filter((val) => val.bool).map(val => v[val.index])]
      , []);
    this.checksSummary = _.groupBy(this.mappedChecks, 'type');
    console.log(this.mappedChecks);
    console.log(this.checksSummary);
  }

  setdoneBy() {
    console.log('doneBy selected')
    this.dealerSelected = true;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  copy(text: string) {
    navigator.clipboard.writeText(text);
  }
  onSubmit() {
    this.submitted = true;

    if (this.firstFormGroup.invalid) {
      return;
    }
    if (this.secondFormGroup.invalid) {
      return;
    }
    const checkCodes = this.mappedChecks.map((v) => v.maintenanceOperationCode);
    const body = {
      maintenanceHistoryId: this.currentMaintenanceInterval.maintenanceHistoryId,
      maintenanceHours: this.firstFormGroup.controls.engineHours.value,
      maintenanceDate: this.firstFormGroup.controls.date.value,
      doneBy: this.firstFormGroup.controls.doneBy.value,
      notes: this.secondFormGroup.controls.notes.value,
      maintenanceOperationCodes: checkCodes,
    };
    console.log('body: ', body);
    this.maintenanceService.createMaintenanceOperationsDone(body).subscribe(
      (result) => {
        console.log('result: ', result);
        this.dialogRef.close();
      },
    );
  }

}
