// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img {
  max-width: 100%;
}

.field-card {
  background: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1490196078);
  display: grid;
  grid-template-columns: 1fr;
  gap: 1em;
  min-height: 140px;
  cursor: pointer;
}
.field-card .details {
  padding: 1em;
}
.field-card .title {
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  height: 24px;
  text-align: left;
  margin-bottom: 6px;
  word-break: break-word;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}
.field-card .found-vins-label {
  word-wrap: break-word;
  word-break: break-all;
}
.field-card .vins {
  display: flex;
  padding-left: 15px;
}
.field-card .vin-circle {
  background: #fff;
  border: 2px solid #ccc;
  border-radius: 50%;
  box-shadow: 16px 4px 24px 0px rgba(23, 33, 38, 0.2);
  box-shadow: 0px 0px 1px 0px rgba(23, 33, 38, 0.1607843137);
  overflow: hidden;
  padding: 4px;
  width: 40px;
  height: 40px;
  margin-left: -15px;
}
.field-card .map {
  background: #ccc;
  overflow: hidden;
}
.field-card .map img {
  object-fit: cover;
  height: 100%;
}

::ng-deep .very-small .field-card .details {
  transform: scale(0.82);
  padding: 0 !important;
}

@media screen and (min-width: 600px) {
  .field-card {
    grid-template-columns: 1fr 35%;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
