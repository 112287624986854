import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LegalsService {
  apiUrl = environment.apiURL;

  constructor(
    private http: HttpClient
  ) { }


  getLegalDocument(type: number, language: string): Observable<any> {
    const params = {
      type: type.toString(),
      language: language
    };
    return this.http
      .get(this.apiUrl + `/privacy_terms/read_privacy_terms`, { params })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(error => { throw error; })
      );
  }
}
