import { createAction, props } from '@ngrx/store';
import { CustomerGeometry } from '@redux/modules/geoview-customer/index';

export const setCustomerEntries = createAction(
  'setCustomerEntries',
  props<{ value: any[]; }>(),
);

export const setLoadingGeoviewCustomer = createAction(
  'setLoadingGeoviewCustomer',
  props<{ value: boolean }>(),
);

export const setTotalCustomerVehicles = createAction(
  'setTotalCustomerVehicles',
  props<{ value: number; }>(),
);
export const setCustomerGeometries = createAction(
  'setCustomerGeometries',
  props<{ value: CustomerGeometry[]; }>(),
);
export const setGeoviewCustomerFilters = createAction(
  'setGeoviewCustomerFilters',
  props<{ filters: any; }>(),
);
export const setGeoviewCustomerSelected = createAction(
  'setGeoviewCustomerSelected',
  props<{ value: any; }>(),
);

export const getGeoviewCustomerVehicles = createAction(
  'getGeoviewCustomerVehicles',
  props<{ filters: any }>(),
);
