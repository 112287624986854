import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserRole } from '../../_models/user';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { UserDialogComponent } from '../../components/header/user-dialog/user-dialog.component';
import { selectHasDealer, selectUser, selectUserPreferences } from '@redux/index';
import { Store } from '@ngrx/store';
import { UserPreferences } from '@shared/_types/UserPreferences';
import { setUserPreferences } from '@redux/actions';
import { environment } from '@env';
import { Auth0UserDetailDto, HasDealerDto } from '@services/api.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  menuOpen: boolean;
  user: Auth0UserDetailDto;
  userRole: string;
  Role = UserRole;
  hasDealer: HasDealerDto
  novuUrl = environment.novuURL;
  novuWsUrl = environment.novuWsURL;
  menuactive = false
  private preferences: UserPreferences;
  subscriberId: string;
  constructor(
    public dialog: MatDialog,
    public store: Store,
    public router: Router,
    public translate: TranslateService
  ) {
  }

  applicationIdentifier = environment.novuApplicationIdentifier;

  get popoverConfig() {
    return {
      offset: this.menuOpen ? 150 : 10,

      position: 'right-start',
    };


  }

  openNotifications(event) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    (document.querySelector('[data-test-id="notification-bell"]') as HTMLElement)?.click();
  }

  ngOnInit() {
    this.store.select(selectUser).pipe(filter(user => user != null)).subscribe(user => {
      this.user = user;
      this.subscriberId = user.user_id + '-' + environment.novuSubEnv;
      this.userRole = this.user.roles[0];
    });
    this.store.select(selectUserPreferences).subscribe(preferences => {
      this.preferences = preferences;
      this.menuOpen = preferences.sidebar;
    });
    this.store.select(selectHasDealer).pipe(filter(hasDealer => hasDealer != null)).subscribe(hasDealer => {
      this.hasDealer = hasDealer;
    });
  }

  clickToggleMenu () {
    this.store.dispatch(setUserPreferences({ value: { ...this.preferences, sidebar: !this.menuOpen } }));
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }

  openLink(link:string){
    window.open(link, '_blank');
  }

  openUserDialog(): void {
    const dialogRef = this.dialog.open(UserDialogComponent, {
      height: '150px',
      position: {
        top: '160px',
        left: '10px',
      },
      backdropClass: 'dialog-backdrop',
      panelClass: 'no-padding-dialog',
    });
    dialogRef.afterClosed().subscribe(() => {});
  }

  navigateToDealerMgmt() {
    this.router.navigate(['/portal/dealer-mgmt']);
  }

}
