import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CreateDialogComponent } from '@portal/equip-mgmt/create-dialog/create-dialog.component';
import { LoaderService } from '@services/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from '@services/message.service';
import { CustomerEquipmentService } from '@services/api.service';
import { CustomRouteReuseStrategy } from '@portal/equip-mgmt/reoute-invalidator';

@Component({
  selector: 'app-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss'],
})
export class EditDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<EditDialogComponent>,
    private router: Router,
    private dialog: MatDialog,
    private loaderService: LoaderService,
    private messageService: MessageService,
    private translate: TranslateService,
    private equipmentService: CustomerEquipmentService,
    private routeReuseStrategy: CustomRouteReuseStrategy,
    @Inject(MAT_DIALOG_DATA) public data: { equip: any }
  ) {}

  closeDialog(): void {
    this.dialogRef.close();
  }
  onDelete(): void {
    this.dialogRef.close();
    const title = this.translate.instant('EquipManagement.Dialogs.Messages.Warning');
    const text = this.translate.instant('EquipManagement.Dialogs.RemoveEquipment') + ` '${this.data?.equip?.name}' Nr.${this.data?.equip?.macAddress}?`;
    const dialogRef = this.messageService.openDialog(title, text, true);
    dialogRef.afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        this.loaderService.show();
        this.equipmentService.deleteEquipment(this.data.equip).subscribe((response) => {
          this.loaderService.hide();

          this.routeReuseStrategy.invalidateRoute('/portal/equip-mgmt');
          this.router.navigate(['/portal/equip-mgmt']);
        }, _ => {
          this.loaderService.hide();
        });
      }
    });
  }
  goToEquipDetails() {
    console.log('id: ', this.data.equip.macAddress)
    this.router.navigate([`/portal/equip-mgmt/equip-detail/${this.data.equip.macAddress}`]);
    this.closeDialog();
  }
  goToEquipEdit() {
    console.log('id: ', this.data.equip.macAddress)
    this.router.navigate([`/portal/equip-mgmt/equip-edit/${this.data.equip.macAddress}`]);
    this.closeDialog();
  }
  openEditDialog(): void {
    this.closeDialog();
    const id = this.data.equip.macAddress
    const dialogRef = this.dialog.open(CreateDialogComponent, {
      width: '50em',
      maxHeight: 'calc(100vh - 40px)',
      backdropClass: 'dialog-backdrop',
      panelClass: 'no-padding-dialog',
      data: { id: id }
    });

    dialogRef.afterClosed().subscribe(result => {
      // Handle any actions after the dialog is closed, if needed
      console.log('Dialog closed');
    });
  }
}
