import { createReducer, on } from '@ngrx/store';
import * as Actions from './actions';
import * as RootActions from '../../actions';
import { AssignedVehicleDto } from '@shared/_services/api.service';

export interface State {
  page: number;
  pageSize: number
  isLoading: boolean,
  vehicles: AssignedVehicleDto[];
  totalNumber: number;
  totalPages?: number;
  filters: any
}


export const initialState: State = {
  isLoading: false,
  page: 1,
  pageSize: 10,
  vehicles: [],
  totalNumber: 0,
  totalPages: 0,
  filters: {},
};

export const selectIsUserTractorsLoading = state => state.userTractors.isLoading;
export const selectUserTractorsVehicles = state => state.userTractors.vehicles;
export const selectUserTractorsPage = state => state.userTractors.page;
export const selectUserTractorsPageSize = state => state.userTractors.pageSize;
export const selectUserTractorsTotalNumber = state => state.userTractors.totalNumber;
export const selectUserTractorsTotalPages = state => state.userTractors.totalPages;
export const selectUserTractorsFilters = state => state.userTractors.filters;


export const userTractorsReducer = createReducer(
  initialState,
  on(RootActions.resetState, () => ({ ...initialState })),
  on(Actions.setUserTractorsLoading, (state, { value }) => ({ ...state, isLoading: value })),
  on(Actions.setUserTractorsFilters, (state, { filters }) => ({ ...state, filters })),
  on(Actions.setUserTractorsList, (state, {
    vehicles,
    page,
    pageSize,
    totalNumber,
    totalPages,
  }) => ({
    ...state, vehicles,
    page,
    pageSize,
    totalNumber,
    totalPages,
  })),
);


