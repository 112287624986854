import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class FilesService {
  apiUrl = environment.apiURL;

  constructor(
    private http: HttpClient
  ) { }

  getPresignedLink(fileType: string): Observable<any> {
    const params = {
      file_type: fileType
    };
    return this.http
      .get(this.apiUrl + `/sdf_protected/protected_obj_storage_put_file`, { params })
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(error => { throw throwError(error); })
      );
  }

  downloadFile(fileId: string): Observable<any> {
    const params = {
      ...(fileId) && { file_id: fileId }
    };
    return this.http
      .get(this.apiUrl + `/sdf_protected/protected_obj_storage_get_file`, { params })
      .pipe(
        map((response: any) => response.url),
        catchError(error => { throw throwError(error); })
      );
  }

  getFiles(fileId?: string, vin?: string, filename?: string, fileType?: string, fromDate?: string, toDate?: string, page?: number): Observable<any> {
    const limit = 100;

    const params = {
      ...(fileId) && { file_id: fileId },
      ...(vin) && { vin: vin },
      ...(filename) && { filename: filename },
      ...(fileType) && { file_type: fileType },
      ...(fromDate) && { from_date: fromDate },
      ...(toDate) && { to_date: toDate },
      ...(page && page !== null) && { page: page.toString() },
      ...(limit && limit !== null) && { limit: limit.toString() }
    };
    return this.http
      .get(this.apiUrl + `/sdf_protected/protected_obj_storage_list_files`, { params })
      .pipe(
        map((response: any) => response),
        catchError(error => { throw throwError(error); })
      );
  }

  deleteFile(fileId: string): Observable<any> {
    const params = {
      ...(fileId) && { file_id: fileId }
    };
    return this.http
      .delete(this.apiUrl + `/sdf_protected/protected_obj_storage_delete_file`, { params })
      .pipe(
        map((response: any) => { return response }),
        catchError(error => { throw throwError(error); })
      );
  }

  uploadFileToBucket(presigned_link: string, file: File): Observable<any> {
    return this.http.put(presigned_link, file, {
      reportProgress: true,
      observe: "events",
      responseType: 'json'
    });

  }

  /*uploadFile(vin: string, filename: string, fileType: string, fileData: File) {
    const formData = new FormData();
    formData.append('file_data', fileData);
    formData.append('vin', vin);
    formData.append('file_type', fileType);
    formData.append('filename', filename);

    return this.http
      .post(this.apiUrl + `/sdf_protected/files/upload`, formData)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(error => { throw throwError(error); })
      );
  }*/

  confirmFileUpload(vin: string, filename: string, file_id: string, fileType: string) {
    const body = {
      vin: vin,
      filename: filename,
      file_id: file_id,
      file_type: fileType
    };
    return this.http
      .post(this.apiUrl + `/sdf_protected/protected_obj_storage_confirm_file`, body)
      .pipe(
        map((response: any) => {
          return response;
        }),
        catchError(error => { throw throwError(error); })
      );
  }

  uploadFileProcess(vin: string, file: File, fileType: string) {
    return new Promise<any>((resolve, reject) => {
      this.getPresignedLink(fileType).subscribe((objPresigned) => {
        const presignedUrl = objPresigned.url;
        const fileId = objPresigned.file_id;
        return this.uploadFileToBucket(presignedUrl, file).subscribe(() => {
          return this.confirmFileUpload(vin, file.name, fileId, fileType).subscribe(() => {
            resolve(true);
          })
        })
      }, _ => {
        reject();
      })
    });
    /*
        return new Promise<any>((resolve, reject) => {
      this.uploadFile(vin, file.name, fileType, file).subscribe(() => {
        resolve(true);
      }, (error) => {
        reject(error);
      })
    });
    */
  }


  blobToBase64(blob: Blob): Observable<any> {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(blob);
    return new Observable(observer => {
      fileReader.onloadend = () => {
        observer.next(fileReader.result);
        observer.complete();
      };
    });
  }

  assetToBase64(asset: string): Observable<any> {
    return this.http.get(asset, { responseType: 'blob' })
      .pipe(
        mergeMap((response: any) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(response);
          return new Observable(observer => {
            fileReader.onloadend = () => {
              observer.next(fileReader.result);
              observer.complete();
            };
          });
        }),
        catchError(error => { throw throwError(error); })
      );
  }

}
