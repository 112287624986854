import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, tap } from 'rxjs/operators';
import * as fromActions from './actions';
import { Store } from '@ngrx/store';
import { Sort } from '@angular/material/sort';
import { selectUser } from '@redux/index';
import { NotificationService } from '@services/notification-api.service';
import { promiseWrapper } from '@shared/_funtions/promiseWarapper.function';

@Injectable()
export class NotificationsEffects {
  public $getSettings = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getNotificationsSettings),
      switchMap(async () => {
        const settings = await promiseWrapper(this.notificationService.getUserNotificationPreferences());
        return [fromActions.setNotificationsSettings({ settings })];
      }),
      switchMap((actions) => [...actions]),
    );
  });
  public $setSettings = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.updateNotificationsSettings),
      switchMap(async (action) => {
        await promiseWrapper(this.notificationService.updateUserNotificationPreferences(action.settings));
        return [fromActions.setNotificationsSettings(action)];
      }),
      switchMap((actions) => [...actions]),
    );
  });
  private pageSize: number;
  private pageIndex: number;
  private filters: any;
  public $setFilters = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.setNotificationsFilters),
      tap(action => {
        this.filters = action.filters;
      }),
      switchMap((actions) => [fromActions.getNotificationsList({ pageIndex: 1, pageSize: this.pageSize || 10 })]),
    );
  });
  private sort: Sort;
  public getData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getNotificationsList),
      tap(action => {
        this.pageIndex = action.pageIndex;
        this.pageSize = action.pageSize;
        this.sort = action.sort || {
          active: 'alarmStartDateTime',
          direction: 'desc',
        };
      }),
      tap(() => this.store.dispatch(fromActions.setNotificationsLoading({ value: true }))),
      switchMap(async () => {
        const { q, ...filterModel } = this.filters || {};
        // const notificationsData = await promiseWrapper(this.vehicleService
        //   .getVehicleNotifications({
        //     startRow: (this.pageIndex - 1) * this.pageSize,
        //     endRow: this.pageIndex * this.pageSize - 1,
        //     language: this.language,
        //     qSearch: q,
        //     filterModel,
        //     ...(this.sort ? {
        //       sortModel: [
        //         {
        //           colId: this.sort.active,
        //           sort: this.sort.direction,
        //         },
        //       ],
        //
        //     } : {}),
        //   }));
        const notificationsData = {
          data: [],
          currentPage: 1,
          totalCount: 0,
        };

        return [fromActions.setNotificationsList({
          notifications: notificationsData.data,
          page: notificationsData.currentPage,
          pageSize: this.pageSize,
          totalNumber: notificationsData.totalCount,
        })];
      }),
      switchMap((actions) => [...actions, fromActions.setNotificationsLoading({ value: false })]),
    );
  });
  private language: string;

  constructor(
    private actions$: Actions,
    private store: Store,
    private notificationService: NotificationService,
  ) {
    this.pageIndex = undefined;
    this.pageSize = undefined;
    this.store.select(selectUser).subscribe(user => {
      if (!user?.user_metadata) {
        return;
      }
      this.language = user?.user_metadata?.language || 'en';

    });
  }
}
