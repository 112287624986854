// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 999;
}
:host mat-card {
  min-width: 450px;
  height: calc(100vh - 80px - 48px);
}
:host mat-card-header {
  background: hsl(30, 100%, 90%);
  padding: 0;
  border-radius: 10px 10px 0 0;
  flex-direction: column;
  border-bottom: 1px solid #ddd;
}
:host mat-card-content {
  height: -webkit-fill-available;
  max-height: -webkit-fill-available;
  overflow-y: auto;
  overflow-anchor: none;
  padding: 0;
}
:host mat-card-actions {
  display: flex;
  justify-content: flex-end;
  background-color: #efefef;
  border-radius: 0 0 10px 10px;
  min-height: auto;
}
:host .container {
  padding: 0;
}
:host .fb-item {
  width: auto;
  height: 100px;
  border: 1px solid #eaeaea;
  padding: 5px;
}
:host .gravatar {
  width: 50px;
  height: 40px;
}
:host .gravatar-title {
  width: 400px;
  padding: 5px;
  padding-left: 60px;
  height: 50px;
}
:host .first-section-wrapper,
:host .second-section-wrapper {
  width: 100%;
  height: auto;
  flex: 1;
}
:host .first-section-wrapper {
  display: inline-flex;
}
:host .cdk-virtual-scroll-viewport {
  height: 100%;
  width: 100%;
  max-width: 100%;
}
:host .example-viewport {
  height: 100%;
  width: 100%;
}
:host .example-viewport .cdk-virtual-scroll-content-wrapper {
  max-width: 100% !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background: none !important;
}

mat-card-header div {
  display: flex;
  align-items: center;
  padding: 0 1em 1em 1em;
  gap: 1em;
  width: 100%;
}

.vehicle-wrapper {
  border: solid #AFAFAF;
  border-width: 0px 0px 1px 0px;
}
.vehicle-wrapper.selected {
  border: solid #FF8C17;
  border-width: 4px;
}

.list-status-bar {
  font-size: 11px;
}

.vehicle-item {
  display: grid;
  gap: 0.5em;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: start;
  padding: 8px;
}

.vehicle-item .pic {
  border-radius: 50%;
  border: solid 3px;
  overflow: hidden;
  padding: 4px;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vehicle-item .pic img {
  width: 100%;
}

.details {
  font-size: 0.7em;
  text-align: center;
}

.details b {
  font-size: 0.8rem;
  word-break: break-all;
}

.actions > div {
  display: grid;
  justify-content: center;
}

.actions button {
  width: auto !important;
  height: auto !important;
  line-height: 1.7;
  padding: 0;
  font-size: inherit;
}
.actions button svg {
  width: initial;
  height: 1em;
}

.circle {
  background-color: red;
  color: white;
  border-radius: 50%;
  display: inline-block;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
}

@media screen and (min-width: 640px) {
  .vehicle-item {
    display: grid;
    gap: 0.5em;
    grid-template-columns: 80px 1fr 50px;
    grid-template-rows: 1fr 40px;
    justify-items: start;
    padding: 10px 8px;
  }
  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;
    grid-column: 1/4;
    grid-row: 2/3;
  }
  .tags .tag {
    border-radius: 25px;
    padding: 4px 12px;
    display: flex;
    gap: 8px;
    font-size: 12px;
    font-weight: 700;
    align-items: center;
    background: #C8C8C8;
  }
  .details {
    font-size: 0.8em;
    text-align: left;
  }
  .details b {
    font-size: 0.9rem;
  }
  .actions {
    padding-right: 3px;
    display: block;
    margin: 0 0 0 auto;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
