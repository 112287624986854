import { Component, Inject, Input, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ImplementDto } from '@shared/_types/EquipDTO';
import { CustomerEquipmentService } from '@services/api.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { CustomRouteReuseStrategy } from '@portal/equip-mgmt/reoute-invalidator';

@Component({
  selector: 'app-equip-form',
  templateUrl: './equip-form.component.html',
  styleUrl: './equip-form.component.scss'
})
export class EquipFormComponent {
  @Input() inCard: boolean = false;
  @Input() allowSave: boolean = false;
  defaultImplement: ImplementDto = { name: '', macAddress: '', purchaseDate: '', type: '', model: '', brand: ''};
  @Input() implement: ImplementDto = this.defaultImplement;
  id: string;
  typesList = [
    { value: 'Backhoe', key: 'Backhoe' },
    { value: 'Baler', key: 'Baler' },
    { value: 'Cultivator', key: 'Cultivator' },
    { value: 'Disc Harrow', key: 'DiscHarrow' },
    { value: 'Harrow', key: 'Harrow' },
    { value: 'Loader', key: 'Loader' },
    { value: 'Mower', key: 'Mower' },
    { value: 'Mulcher', key: 'Mulcher' },
    { value: 'Planter', key: 'Planter' },
    { value: 'Plough', key: 'Plough' },
    { value: 'Post Hole Digger', key: 'PostHoleDigger' },
    { value: 'Rear Blade', key: 'RearBlade' },
    { value: 'Ridger', key: 'Ridger' },
    { value: 'Rotary Tiller', key: 'RotaryTiller' },
    { value: 'Rotavator', key: 'Rotavator' },
    { value: 'Seed Drill', key: 'SeedDrill' },
    { value: 'Slurry Tank', key: 'SlurryTank' },
    { value: 'Snowblower', key: 'Snowblower' },
    { value: 'Sprayer', key: 'Sprayer' },
    { value: 'Spreader', key: 'Spreader' },
    { value: 'Straw Reaper', key: 'StrawReaper' },
    { value: 'Trailer', key: 'Trailer' }
  ];
  brandsList = [
    { value: 'Ferrari', key: 'ferrari' },
    { value: 'Audi', key: 'audi' }
  ];
  modelsList = [
    { value: 'GTO 250', key: 'gto-250', model: 'ferrari' },
    { value: 'A4', key: 'a4', model: 'audi' }
  ];
  filteredModels = this.modelsList;
  constructor (
    public route: ActivatedRoute,
    public router: Router,
    public store: Store,
    private equipmentService: CustomerEquipmentService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: { id: any },
    @Optional() private dialogRef: MatDialogRef<EquipFormComponent>,
    private routeReuseStrategy: CustomRouteReuseStrategy) {
    this.route.params.subscribe(params => {
      this.id = params.id;

    });
  }

  ngOnInit() {
    if (this.data?.id) this.id = this.data.id
    if (this.id) this.getCurrentEquip()
  }
  ngOnDestroy() {
  }
  getCurrentEquip() {
    this.equipmentService.getEquipment(this.id).subscribe((equipment) => {
      this.implement = equipment as ImplementDto;
    });
  }
  onDeleteClick () {
    console.log('delete');
  }
  formatDateToISO(date) {
    return DateTime.fromJSDate(new Date(date)).toUTC().toISO()
  }
  onAssociate() {
    console.log('associate implement: ', this.implement);
    const body = this.implement
    body.purchaseDate = this.formatDateToISO(this.implement.purchaseDate)
    console.log('body: ', body);
    this.equipmentService.createEquipment(body).subscribe(
      (result) => {
        console.log('result: ', result);
        this.router.navigate(['/portal/equip-mgmt']);
        if (this.dialogRef) {
          this.dialogRef.close();
        }
      },
      (error) => {
        console.error('Error creating equipment: ', error);
      }
    );
  }
  onSave() {
    console.log('save');
    console.log('implement: ', this.implement);
    const body = this.implement
    body.purchaseDate = this.formatDateToISO(this.implement.purchaseDate)
    this.equipmentService.updateEquipment(body).subscribe(
      (result) => {
        console.log('result: ', result);
        this.routeReuseStrategy.invalidateRoute('/portal/equip-mgmt');
        this.router.navigate(['/portal/equip-mgmt']);
        if (this.dialogRef) {
          this.dialogRef.close();
        }
      },
      (error) => {
        console.error('Error creating equipment: ', error);
      }
    );
  }
  setType () {
    console.log('setType');
  }
  setBrand () {
    console.log('setBrand');
    this.implement.model = '';
    this.filteredModels = this.modelsList.filter(model => model.model === this.implement.brand);

  }
  setModel () {
    console.log('setModel');
  }
}
