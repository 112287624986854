import { createReducer, on } from '@ngrx/store';
import * as Actions from './actions';
import { CounterUser, UserRole } from '@models/user';
import { AssignedVehicleDto, Auth0UserDetailDto, HasDealerDto } from '@services/api.service';
import { ARAccount } from '@models/ar-account';
import { UserPreferences } from '@shared/_types/UserPreferences';
import { Auth0AgrirouterAccountDto } from '@services/sdf-agrirouter-api.service';

export interface State {
  dataMgmtLoaded: boolean;
  fleetMgmtLoaded: boolean;
  dealerMgmtLoaded: boolean;
  userMgmtLoaded: boolean;
  user: Auth0UserDetailDto;
  hasDealer: HasDealerDto;
  agriRouterInfo: Auth0AgrirouterAccountDto[];
  userPreferences: UserPreferences;
  tractors: AssignedVehicleDto[];
  rolesCanCreate: UserRole[];
  children: Auth0UserDetailDto[];
  counters: CounterUser;
  factories: Auth0UserDetailDto[],
  radicals: string[],
  nextVehiclePage: number;
  nextAccountsPage: number;
  nextChildrenPage: number;
  nextParentPage: number;
  tractorsCounter: number;
}


export const initialState: State = {
  fleetMgmtLoaded: false,
  dataMgmtLoaded: false,
  dealerMgmtLoaded: false,
  userMgmtLoaded: false,
  user: null,
  hasDealer: null,
  agriRouterInfo: [],
  tractors: [],
  tractorsCounter: -1,
  rolesCanCreate: [],
  children: null,
  userPreferences: {
    metrics: {
      engineSpeed: true,
      fuelRate: true,
      actualEngineTorque: true,
      engineOilTemperature: true,
      engineCoolantTemperature: true,
      intakeManifoldTemperature: true,
      exhaustGasTemperature: true,
      vehicleSpeed: true,
      frontPtoSpeed: true,
      rearPtoSpeed: true,
      rearLiftPosition: true,
      powerReductionPercentage: true,
      engineOilPressure: true,
      ambientTemperature: true,
      fourWheelDriveState: true,
      diffLockState: true,
      backupBatteryVoltage: true,
      transmissionOilTemperature: true,
      cabinTemperature: true,
      acStatus: true,
    },
    selectedLayer: 'Google Satellite',
    tractorDetails: {
      metrics: true,
      alarms: true,
      graphs: true,
    },
    sidebar: true,
  },
  counters: {
    customer: -1,
    dealer: -1,
    subsidiary: -1,
    factory: -1,
  },
  factories: [],
  radicals: null,
  nextVehiclePage: -1,
  nextAccountsPage: -1,
  nextChildrenPage: -1,
  nextParentPage: -1,
};

export const selectDataMgmtLoaded = state => state.root.dataMgmtLoaded;
export const selectFleetMgmtLoaded = state => state.root.fleetMgmtLoaded;
export const selectDealerMgmtLoaded = state => state.root.dealerMgmtLoaded;
export const selectUserMgmtLoaded = state => state.root.userMgmtLoaded;
export const selectUser = state => state.root.user;
export const selectUserPreferences = state => state.root.userPreferences;
export const selectHasDealer = state => state.root.hasDealer;
export const selectAgriRouterInfo = state => state.root.agriRouterInfo;
export const selectTractors = state => state.root.tractors;
export const selectRolesCanCreate = state => state.root.rolesCanCreate;
export const selectChildren = state => state.root.children;
export const selectCounters = state => state.root.counters;
export const selectFactories = state => state.root.factories;
export const selectRadicals = state => state.root.radicals;
export const selectNextVehiclePage = state => state.root.nextVehiclePage;
export const selectNextAccountsPage = state => state.root.nextAccountsPage;
export const selectNextChildrenPage = state => state.root.nextChildrenPage;
export const selectNextParentPage = state => state.root.nextParentPage;
export const selectTractorsCounter = state => state.root.tractorsCounter;


export const rootReducer = createReducer(
  initialState,
  on(Actions.resetState, () => ({ ...initialState })),
  on(Actions.setDataMgmtLoaded, (state, { value }) => ({ ...state, dataMgmtLoaded: value })),
  on(Actions.setFleetMgmtLoaded, (state, { value }) => ({ ...state, fleetMgmtLoaded: value })),
  on(Actions.setDealerMgmtLoaded, (state, { value }) => ({ ...state, dealerMgmtLoaded: value })),
  on(Actions.setUserMgmtLoaded, (state, { value }) => ({ ...state, userMgmtLoaded: value })),
  on(Actions.setUser, (state, { value }) => ({ ...state, user: value })),
  on(Actions.setUserPreferences, (state, { value }) => ({
    ...state,
    userPreferences: {
      ...state.userPreferences, ...value,
      metrics: { ...state.userPreferences.metrics, ...value?.metrics || {} },
      hasChanged: true,
    },
  })),
  on(Actions.setHasDealer, (state, { value }) => ({ ...state, hasDealer: value })),
  on(Actions.setAgriRouterInfo, (state, { value }) => ({ ...state, agriRouterInfo: value })),
  on(Actions.setTractors, (state, { value }) => ({ ...state, tractors: value })),
  on(Actions.setRolesCanCreate, (state, { value }) => ({ ...state, rolesCanCreate: value })),
  on(Actions.setChildren, (state, { value }) => ({ ...state, children: value })),
  on(Actions.setCounters, (state, { value }) => ({ ...state, counters: value })),
  on(Actions.setFactories, (state, { value }) => ({ ...state, factories: value })),
  on(Actions.setRadicals, (state, { value }) => ({ ...state, radicals: value })),
  on(Actions.setNextVehiclePage, (state, { value }) => ({ ...state, nextVehiclePage: value })),
  on(Actions.setNextAccountsPage, (state, { value }) => ({ ...state, nextAccountsPage: value })),
  on(Actions.setNextChildrenPage, (state, { value }) => ({ ...state, nextChildrenPage: value })),
  on(Actions.setNextParentPage, (state, { value }) => ({ ...state, nextParentPage: value })),
  on(Actions.setTractorsCounter, (state, { value }) => ({ ...state, tractorsCounter: value })),
);


