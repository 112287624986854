import { createAction, props } from '@ngrx/store';
import { VehicleAlarmHistoryViewDto } from '@services/api.service';
import { Sort } from '@angular/material/sort';

export const setAlarmLoading = createAction(
  'setAlarmLoading',
  props<{ value: boolean }>(),
);

export const setAlarmFilters = createAction(
  'setAlarmFilters',
  props<{ filters: any }>(),
);

export const getAlarmList = createAction(
  'getAlarmList',
  props<{
    pageIndex: number;
    pageSize: number;
    sort?: Sort;
  }>(),
);

export const setAlarmList = createAction(
  'setAlarmList',
  props<{
    vehicles: VehicleAlarmHistoryViewDto[],
    page: number,
    pageSize: number,
    totalNumber: number
  }>(),
);
