import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MaintenanceHistoryWithOperationDoneDto, MaintenanceService, UpdateMaintenanceOperationsDoneRequest } from '@services/api.service';
import { Store } from '@ngrx/store';
import { selectUser } from '@redux/index';
import { filter } from 'rxjs/operators';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'app-maintenance-dialog',
  templateUrl: './maintenance-dialog.component.html',
  styleUrls: ['./maintenance-dialog.component.scss'],
})
export class MaintenanceDialogComponent implements OnInit {
  language: string;
  dealerSelected: boolean;
  secondFormGroup: FormGroup;
  groupedChecks: any;
  isLoading = false;
  currentMaintenanceInterval: MaintenanceHistoryWithOperationDoneDto;
  dealersList = [
    { value: 'Dealer', label: 'Dealer' },
    { value: 'Customer', label: 'Customer' },
  ];

  constructor(public dialogRef: MatDialogRef<MaintenanceDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private maintenanceService: MaintenanceService,
              private formBuilder: FormBuilder,
              private store: Store,
  ) {
  }

  setdoneBy() {
    console.log('doneBy selected');
    this.dealerSelected = true;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    console.log(this.data);

    this.secondFormGroup = this.formBuilder.group({
      notes: [''],
      vin: [''],
      doneBy: [''],
      date: [''],
      engineHours: [''],
      maintenanceInterval: [''],
    });
    this.store.select(selectUser).pipe(filter(user => user != null)).subscribe(user => {
      this.isLoading = true;
      this.language = user.user_metadata.language;
      this.maintenanceService.getMaintenanceHistoryById(this.data.maintenance.id, this.language).subscribe(data => {
        console.log(data);
        this.currentMaintenanceInterval = data;
        this.getChecks(data);
      });
    });

  }

  getChecks(data) {
    const previouslyDone = data.maintenanceOperationsDones.map(m => m.maintenanceOperationCode);
    console.log(previouslyDone);
    this.maintenanceService.getMaintenanceOperations(data.maintenancePlanCodeId, this.language)
      .subscribe(result => {
        console.log(result);
        if (result) {
          const groupedChecks = _.groupBy(result, 'type');
          this.secondFormGroup = this.formBuilder.group(
            {
              ..._.reduce(groupedChecks, (acc, v, k) => {
                console.log({ v, k });
                return ({
                  ...acc,
                  [k]: this.formBuilder.array(v.map((val) => ({
                    value: previouslyDone.includes(val.maintenanceOperationCode),
                    disabled: this.data.view,
                  }))),
                });
              }, {}),
              notes: { value: data.notes, disabled: this.data.view },
              vin: { value: data.vin, disabled: this.data.view },
              doneBy: { value: data.doneBy, disabled: this.data.view },
              date: { value: data.maintenanceDate, disabled: this.data.view },
              engineHours: { value: data.maintenanceHours, disabled: this.data.view },
              maintenanceInterval: { value: data.maintenancePlanCodeId, disabled: this.data.view },
            },
          );
          console.log(this.secondFormGroup);
          this.groupedChecks = groupedChecks;
          this.isLoading = false;
        }
      });
  }

  copy(text: string) {
    navigator.clipboard.writeText(text);
  }
  save() {
    const data = this.secondFormGroup.value;
    const mappedChecks = _.reduce(this.groupedChecks, (acc, v, k) =>
        [...acc, ...this.secondFormGroup.value[k].map((bool, index) => ({ bool, index }))
          .filter((val) => val.bool).map(val => v[val.index])]
      , []);
    console.log(mappedChecks);
    const maintenanceHistory: UpdateMaintenanceOperationsDoneRequest = {
      maintenanceDate: data.date,
      maintenanceHours: data.engineHours,
      maintenanceHistoryId: this.data.maintenance.id,
      doneBy: data.doneBy,
      notes: data.notes,
      maintenanceOperationCodes: mappedChecks.map(m => m.maintenanceOperationCode),
    };
    this.isLoading = true;
    this.maintenanceService.updateMaintenanceOperationsDone(maintenanceHistory).subscribe(result => {
      console.log(result);
      this.dialogRef.close();
    });
  }

}
