// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    .paginator {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1em;
    }

    button {
      padding: 0.5em 1em;
      margin: 0 0.5em;
      border: 1px solid #333;
      border-radius: 5px;
      background-color: #fff;
      color: #333;
      cursor: pointer;
    }

    button[disabled] {
      cursor: not-allowed;
      opacity: 0.5;
    }

    .paginator-info {
      margin: 0 1em;
    }
  `, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
