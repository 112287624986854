import { createAction, props } from '@ngrx/store';
import { MaintenanceCounterDto, MaintenanceDto } from '@services/api.service';
import { Sort } from '@angular/material/sort';

export const setMaintenanceSummary = createAction(
  'setMaintenanceSummary',
  props<{
    value: MaintenanceCounterDto
  }>(),
);

export const setMaintenanceLoading = createAction(
  'setMaintenanceLoading',
  props<{ value: boolean }>(),
);
export const setMaintenanceFilters = createAction(
  'setMaintenanceFilters',
  props<{ filters: any }>(),
);
export const getMaintenanceList = createAction(
  'getMaintenanceList',
  props<{
    pageIndex: number;
    pageSize: number;
    sort?: Sort;
  }>(),
);

export const getMaintenanceSummary = createAction(
  'getMaintenanceSummary',
);

export const setMaintenanceList = createAction(
  'setMaintenanceList',
  props<{
    vehicles: MaintenanceDto[],
    page: number,
    pageSize: number,
    totalNumber: number
  }>(),
);
