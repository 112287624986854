import { Component, OnInit } from '@angular/core';
import { Auth0UserDetailDto, CustomerDto, GeoViewVehicleDto, LookupService, VehicleDto, VehicleService, VehicleStatus } from '@services/api.service';
import { Store } from '@ngrx/store';
import { filter, finalize } from 'rxjs/operators';
import { OverviewService } from '@services/overview.service';
import { VehicleCoords } from '@models/overview';
import { selectUser } from '@redux/index';
import { getGeoviewVehicles, setGeoviewFilters, setLoadingGeoview } from '@redux/modules/geoview/actions';
import {
  selectActiveVehicles,
  selectGeoviewVehicles,
  selectLoadingGeoview,
  selectTotalVehicles,
  selectVehiclesCoords,
} from '@redux/modules/geoview';
import { StatusValue } from '@shared/_types/StatusMatrix';

@Component({
  selector: 'app-geoview',
  templateUrl: './geoview.component.html',
  styleUrls: ['./geoview.component.scss'],
})

export class GeoviewComponent implements OnInit {


  vehicles: GeoViewVehicleDto[];
  activeVehicles: number;
  totalVehicles: number;
  customers: CustomerDto[];
  vehiclesCoords: VehicleCoords[];
  isLoadingCustomers: boolean;
  isLoading: boolean;
  user: Auth0UserDetailDto;
  vehicleToZoom: VehicleDto;
  selectedCustomer: CustomerDto;
  selectedVehicleStatus: VehicleStatus;
  selectedVin: string;

  constructor(
    private vehicleService: VehicleService,
    private lookupService: LookupService,
    private overviewService: OverviewService,
    private store: Store,
  ) {
  }


  ngOnInit() {
    this.isLoading = true;
    this.store.select(selectUser).pipe(filter(user => user != null)).subscribe(user => {
      this.user = user;
    });
    // this.isLoadingCustomers = true;
    // this.lookupService.getCustomerByAuth0UserDealerIdQuery(this.user.user_id)
    //   .pipe(
    //     finalize(() => {
    //       this.isLoadingCustomers = false;
    //     }),
    //   )
    //   .subscribe((customers: CustomerDto[]) => {
    //     this.customers = customers;
    //   });

    this.store.dispatch(getGeoviewVehicles({ user_id: this.user.user_id }));
    this.store.dispatch(setLoadingGeoview({ value: true }));

    this.store.select(selectLoadingGeoview).subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.store.select(selectGeoviewVehicles).pipe(filter(vehicles => vehicles)).subscribe(vehicles => {
      this.vehicles = vehicles;
      this.isLoading = false;
    });
    this.store.select(selectTotalVehicles).subscribe(totalVehicles => {
      this.totalVehicles = totalVehicles;
    });
    this.store.select(selectActiveVehicles).subscribe(activeVehicles => {
      this.activeVehicles = activeVehicles;
    });
    this.store.select(selectVehiclesCoords).subscribe(vehiclesCoords => {
      this.vehiclesCoords = vehiclesCoords;
    });
  }

  zoomOnVehicle(vehicle: VehicleDto) {
    this.vehicleToZoom = vehicle;
  }

  filterByCustomer(customer: CustomerDto) {
    // this.selectedCustomer = customer;
    // if (this.selectedCustomer !== undefined && this.selectedCustomer !== null && this.selectedCustomer!==''){
    //   this.getVehicles(this.user.user_id,null,this.selectedCustomer.auth0UserId,null)
    // }
    // else this.getVehicles(this.user.user_id,null,null,null)
  }

  filterByVin(vin: string) {
    // this.selectedVin = vin;
    // if (this.selectedCustomer !== undefined && this.selectedCustomer !== null && this.selectedCustomer!==''){
    //   this.getVehicles(this.user.user_id,this.selectedVin ??= null,this.selectedCustomer.auth0UserId,null)
    // }
    // else this.getVehicles(this.user.user_id,this.selectedVin ??= null,null,null)
  }

  filterByVehicleStatus(vehicleStatus: StatusValue) {
    this.store.dispatch(setGeoviewFilters({ filters: { vehicleStatus } }));
  }


}
