import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, tap } from 'rxjs/operators';
import * as fromActions from './actions';
import { Store } from '@ngrx/store';
import { VehicleService } from '@services/api.service';
import { promiseWrapper } from '@shared/_funtions/promiseWarapper.function';
import { Sort } from '@angular/material/sort';

@Injectable()
export class UserTractorsEffects {
  private pageSize: number;
  private pageIndex: number;
  private filters: any;
  private sort: Sort;

  public $setFilters = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.setUserTractorsFilters),
      tap(action => {
        this.filters = action.filters;
      }),
      switchMap((actions) => [fromActions.getUserTractorsList({
        pageIndex: 1,
        pageSize: this.pageSize,
      })]),
    );
  });


  public getData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getUserTractorsList),
      tap(action => {
        this.pageIndex = action.pageIndex;
        this.pageSize = action.pageSize;
        this.sort = action.sort;
      }),
      tap(() => this.store.dispatch(fromActions.setUserTractorsLoading({ value: true }))),
      switchMap(async () => {
        const { q, ...filterModel } = this.filters || {};
        const vehiclesData = await promiseWrapper(this.vehicleService
          .getAssignedUserVehicles({
            startRow: (this.pageIndex - 1) * this.pageSize,
            endRow: this.pageIndex * this.pageSize - 1,
            filterModel: {
              ...filterModel,
            },
            ...(this.sort ? {
              sortModel: [
                {
                  colId: this.sort.active,
                  sort: this.sort.direction,
                },
              ],

            } : {}),
          }));

        return [fromActions.setUserTractorsList({
          vehicles: vehiclesData.data,
          page: vehiclesData.currentPage,
          pageSize: this.pageSize,
          totalNumber: vehiclesData.totalCount,
          totalPages: vehiclesData.totalPages,
        })];
      }),
      switchMap((actions) => [...actions, fromActions.setUserTractorsLoading({ value: false })]),
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store,
    private vehicleService: VehicleService,
  ) {
    this.pageIndex = undefined;
    this.pageSize = undefined;
  }
}
