import { Component, OnInit } from '@angular/core';
import { FileResponse, MaintenanceCounterDto, VehicleService } from '@services/api.service';
import { setMaintenanceFilters } from '@redux/modules/maintenance/actions';
import { Store } from '@ngrx/store';
import { selectMaintenanceFilters, selectMaintenanceSummaryData } from '@redux/modules/maintenance';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent implements OnInit {
  currentFilter = 'totalVehicles';
  isExportDownloading = false;
  summaryData: MaintenanceCounterDto;
  vin = new FormControl();
  storeFilters: any;
  constructor(
    private vehicleService: VehicleService,
    private store: Store,
  ) {
  }

  ngOnInit(): void {
    this.store.select(selectMaintenanceSummaryData).subscribe((data) => {
      this.summaryData = data;
    });
    this.store.select(selectMaintenanceFilters).subscribe((filters) => {
      this.storeFilters = filters;
    });
    this.vin.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      this.store.dispatch(setMaintenanceFilters({
        filters: {
          ...this.storeFilters,
          q: value || undefined,
        },
      }));
    });
  }


  updateCurrentFilter(value) {
    if (this.currentFilter === value) {
      value = 'totalVehicles';
    }
    console.log(this.storeFilters);
    this.currentFilter = value;
    switch (value) {
      case 'totalVehicles':
        this.store.dispatch(setMaintenanceFilters({
          filters: {
            ...this.storeFilters,
            maintenanceStatus: undefined,
            activeAlarmsCounter: undefined,

          },
        }));
        break;
      case 'expiring':
        this.store.dispatch(setMaintenanceFilters({
          filters: {
            ...this.storeFilters,
            maintenanceStatus: {
              filterType: 'string',
              type: 'equals',
              filter: 'EXPIRING',
            },
            activeAlarmsCounter: undefined,
          },
        }));
        break;
      case 'expired':
        this.store.dispatch(setMaintenanceFilters({
          filters: {
            ...this.storeFilters,
            maintenanceStatus: {
              filterType: 'string',
              type: 'equals',
              filter: 'EXPIRED',
            },
            activeAlarmsCounter: undefined,
          },
        }));
        break;
      case 'alarms':
        this.store.dispatch(setMaintenanceFilters({
          filters: {
            ...this.storeFilters,
            activeAlarmsCounter: {
              filterType: 'number',
              type: 'greaterThan',
              filter: '0',
            },
            maintenanceStatus: undefined,
          },
        }));
        break;

    }

  }

  getExport() {
    this.isExportDownloading = true;
    this.vehicleService.getAllVehicleAlarmsExportXlsx('it').subscribe((response: FileResponse) => {
      this.isExportDownloading = false;
      const dataType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const binaryData = [];
      binaryData.push(response.data);
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
      const filename = 'export_' + new Date().toLocaleString();
      downloadLink.setAttribute('download', filename);
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });
  }
}
