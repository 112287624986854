// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img {
  max-width: 100%;
}

.equip-card {
  background: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1490196078);
  color: #7D7D7D;
  cursor: pointer;
  display: grid;
  font-size: 13px;
  grid-template-columns: 50px 1fr 24px;
  gap: 1em;
  padding: 1em;
  align-items: center;
}
.equip-card .title {
  color: #212121;
  font-family: Helvetica Neue;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
}
.equip-card .icon-circle {
  background: #C4C4C4;
  border-radius: 50%;
  box-shadow: 16px 4px 24px 0px rgba(23, 33, 38, 0.2);
  box-shadow: 0px 0px 1px 0px rgba(23, 33, 38, 0.1607843137);
  color: #fff;
  overflow: hidden;
  padding: 4px;
  width: 50px;
  height: 50px;
  display: grid;
  align-items: center;
  justify-content: center;
  font-size: 22px;
}
.equip-card .icon-circle.linked {
  background: #47C20A;
}
.equip-card .menu-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.equip-card .status {
  font-size: 0.9em;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
