
export interface ImplementDto {
  id?: string | null;
  name?: string | null;
  type?: string | null;
  brand?: string | null;
  model?: string | null;
  macAddress?: string | null;
  purchaseDate?: any | null;
  vehicles?: any | null;
  position?: any | null;
}

export const typesIcons = {
  RotaryTiller: { icon: 'fa-solid fa-sharp fa-galaxy' }, // fa-sharp fa-galaxy, fa-trillium, fa-wind-turbine, fa-group-arrows-rotate, fa-fan
  Plough: { icon: 'fa-solid fa-sharp fa-shovel rotate-45ccw' }, // fa-starship rotate-90, fa-shovel, fa-record-vinyl, fa-bracket-curly rotate-270, fa-hurricane, fa-fan, fa-sun, fa-viruses, fa-sharp fa-download
  Baler: { icon: 'fa-solid fa-ball-pile' }, // fa-ball-pile, fa-bullseye, fa-columns-3 rotate-90
  Loader: { icon: 'fa-solid fa-truck-plow' },
  Trailer: { icon: 'fa-solid fa-trailer' },
  Snowblower: { icon: 'fa-solid fa-snowflake' },
  Cultivator: { icon: 'fa-solid fa-sharp fa-star-of-life' },
  PostHoleDigger: { icon: 'fa-solid fa-bore-hole' }, // fa-shish-kebab, fa-trillium
  SeedDrill: { icon: 'fa-solid fa-bag-seedling' }, // fa-seedling, fa-bag-seedling, fa-ruler-horizontal
  DiscHarrow: { icon: 'fa-solid fa-record-vinyl' },
  RearBlade: { icon: 'fa-solid fa-pipe-section' }, // fa-road-barrier rotate-180, fa-bracket-square, fa-solid fa-scythe rotate-180
  Backhoe: { icon: 'fa-solid fa-excavator' },
  Spreader: { icon: 'fa-solid fa-sprinkler' }, // fa-shower, fa-sun-dust
  Mulcher: { icon: 'fa-solid fa-hurricane' }, // fa-hurricane, fa-wind-turbine rotate-180
  Harrow: { icon: 'fa-solid fa-sharp fa-gear-complex' }, // fa-shredder
  Mower: { icon: 'fa-solid fa-sickle' },
  Sprayer: { icon: 'fa-solid fa-sprinkler-ceiling' }, // fa-spray-can
  Ridger: { icon: 'fa-solid fa-bars rotate-90' }, // fa-angles-down, fa-sharp fa-bracket-curly rotate-270, fa-pompebled
  Planter: { icon: 'fa-solid fa-seedling' },
  StrawReaper: { icon: 'fa-road-barrier rotate-180' }, // fa-brands fa-stack-overflow, fa-chopsticks rotate-90, fa-signal-good
  Rotavator: { icon: 'fa-solid fa-starship-freighter' }, // fa-road-barrier rotate-180
  SlurryTank: { icon: 'fa-solid fa-pipe-valve' }
};