// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tag {
  border-radius: 25px;
  padding: 4px 12px;
  display: flex;
  gap: 4px;
  color: white;
  font-size: 12px;
  font-weight: 700;
  align-items: center;
}

.grid-container {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 20px;
  justify-items: start;
  padding: 10px 16px;
}
.grid-container .title {
  font-size: 0.8rem;
  font-weight: 700;
}
.grid-container .value {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.grid-container .true {
  color: #4CAF50;
}
.grid-container .false {
  color: #f44336;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
