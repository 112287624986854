import { createReducer, on } from '@ngrx/store';
import * as Actions from './actions';
import * as RootActions from '../../actions';
import { MaintenanceCounterDto, MaintenanceDto } from '@services/api.service';

export interface State {
  page: number;
  pageSize: number
  isLoading: boolean,
  vehicles: MaintenanceDto[];
  totalNumber: number;
  summaryData: MaintenanceCounterDto
  filters: any
}


export const initialState: State = {
  isLoading: false,
  page: 1,
  summaryData: {},
  pageSize: 10,
  vehicles: [],
  totalNumber: 0,
  filters: {},
};

export const selectIsMaintenanceLoading = state => state.maintenance.isLoading;
export const selectMaintenanceSummaryData = state => state.maintenance.summaryData;
export const selectMaintenanceVehicles = state => state.maintenance.vehicles;
export const selectMaintenancePage = state => state.maintenance.page;
export const selectMaintenancePageSize = state => state.maintenance.pageSize;
export const selectMaintenanceTotalNumber = state => state.maintenance.totalNumber;
export const selectMaintenanceFilters = state => state.maintenance.filters;


export const maintenanceReducer = createReducer(
  initialState,
  on(RootActions.resetState, () => ({ ...initialState })),
  on(Actions.setMaintenanceSummary, (state, { value }) => ({ ...state, summaryData: value })),
  on(Actions.setMaintenanceLoading, (state, { value }) => ({ ...state, isLoading: value })),
  on(Actions.setMaintenanceFilters, (state, { filters }) => ({ ...state, filters })),
  on(Actions.setMaintenanceList, (state, {
    vehicles,
    page,
    pageSize,
    totalNumber,
  }) => ({
    ...state, vehicles,
    page,
    pageSize,
    totalNumber,
  })),
);


