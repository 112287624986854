// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  background: rgba(150, 150, 150, 0.7) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

::ng-deep .no-padding-dialog {
  margin: 0;
}

.dialog-content {
  background: #F2F2F2;
}

.title-text {
  font-size: 18px;
  font-weight: 600;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
