// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  grid-column: span 2;
}

.alarms {
  display: grid;
  height: 100%;
}

.title {
  display: flex;
  gap: 0.7rem;
  margin-bottom: 4px;
}

.icon {
  margin-top: -3px;
}

.icon svg {
  width: 24px;
  height: 27px;
}

.title h2 {
  font-size: 20px;
}

.label {
  flex-grow: 1;
}

.body {
  background: #fff;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  align-self: center;
  justify-self: center;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 8px;
}

.hours h2 {
  font-size: 2.8rem;
}

.mat-expansion-panel-header-title, .mat-expansion-panel-header-description {
  flex-grow: 0;
}

.mat-expansion-panel-header-title {
  font-size: 0.9em;
  min-width: 160px !important;
}

.mat-table {
  cursor: pointer;
  width: 100%;
}

.mat-table.table-fill {
  display: none;
}

.mat-header-row {
  min-height: 36px;
}

.mat-row, .mat-footer-row {
  min-height: 36px;
}

.table-img {
  width: 24px;
  display: block;
  margin: 2px 9px;
}

.description-cell {
  font-size: 0.8em;
  word-break: break-word;
}

.mat-orange {
  background-color: #fa8c17;
  color: #FFFFFF;
}

tr.detail-row {
  height: 0;
}

tr.element-row:not(.expanded-row):hover {
  background: whitesmoke;
}

tr.element-row:not(.expanded-row):active {
  background: #efefef;
}

.element-row td {
  border-bottom-width: 0;
}

.element-detail {
  overflow: hidden;
  display: flex;
}

.element-diagram {
  min-width: 80px;
  border: 2px solid black;
  padding: 8px;
  font-weight: lighter;
  margin: 8px 0;
  height: 104px;
}

.element-symbol {
  font-weight: bold;
  font-size: 40px;
  line-height: normal;
}

.element-description {
  padding: 16px;
  white-space: pre-wrap;
}

.element-description-attribution {
  opacity: 0.5;
}

@media screen and (min-width: 768px) {
  :host {
    grid-column: span 2;
  }
}
@media screen and (min-width: 1024px) {
  :host {
    grid-column: span 4;
  }
}
@media screen and (min-width: 1200px) {
  :host {
    grid-column: span 12;
  }
}
@media screen and (min-width: 1800px) {
  :host {
    grid-column: span 16;
  }
}
.high-severity {
  color: red;
}

.medium-severity {
  color: #efcb00;
}

.low-severity {
  color: green;
}

.unknown-severity {
  color: darkgrey;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
