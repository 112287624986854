// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `svg {
  max-width: 100%;
}

button svg {
  display: block;
}

.column {
  display: flex;
  flex-direction: column;
}

.mat-dark-primary {
  color: #C8C8C8;
}

.faicon {
  color: #7D7D7D;
}

:host {
  grid-column: span 2;
}

@media screen and (min-width: 768px) {
  :host {
    grid-column: span 2;
  }
}
@media screen and (min-width: 1024px) {
  :host {
    grid-column: span 4;
  }
}
@media screen and (min-width: 1200px) {
  :host {
    grid-column: span 12;
  }
}
@media screen and (min-width: 1800px) {
  :host {
    grid-column: span 16;
  }
}
tr.detail-row {
  height: 0;
}

tr.element-row:not(.expanded-row):hover {
  background: whitesmoke;
}

tr.element-row:not(.expanded-row):active {
  background: #efefef;
}

.element-row td {
  border-bottom-width: 0;
}

.element-detail {
  overflow: hidden;
}

.element-diagram {
  min-width: 80px;
  border: 2px solid black;
  padding: 8px;
  font-weight: lighter;
  margin: 8px 0;
  height: 104px;
}

.element-symbol {
  font-weight: bold;
  font-size: 40px;
  line-height: normal;
}

.element-description {
  padding: 16px;
}

.element-description-attribution {
  opacity: 0.5;
}

.summary {
  min-height: 135px;
  position: relative;
}

.grid {
  display: grid;
  gap: 0.5em;
  width: 100%;
  margin-block: 1em;
}

.grid > div {
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
  padding: 1em;
}

.title {
  font-weight: 500;
  font-size: 17px;
}

.last-maintenance {
  display: grid;
  gap: 1em;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  margin-top: 1em;
  grid-template-columns: 48px 2fr;
  justify-items: start;
}
.last-maintenance svg {
  width: 25px !important;
}

.next-maintenance .row {
  display: grid;
  gap: 0.5em;
  align-items: start;
  font-weight: 500;
  font-size: 16px;
  margin-top: 1em;
  grid-template-columns: 49px 120px 121px 1fr;
  grid-template-rows: 1fr 1fr;
  justify-items: start;
}
.next-maintenance .row .meter-bar {
  grid-column: 4/5;
  grid-row: 1/span 2;
  margin-top: 4px;
}

.total-value {
  color: #7D7D7D;
  font-size: 0.75em;
}

.maintenance-estimate {
  display: inline-block;
  padding: 0.3em;
  font-size: 0.8em;
  border: 1px solid #ededed;
  margin-left: 0;
  display: inline-flex;
  align-items: center;
}

.next-icon {
  display: none;
}

.meter-bar {
  display: grid;
  width: 100%;
  grid-template-columns: 4px 1fr 4px;
  align-items: center;
  height: 20px;
}

.stop {
  background: #666;
  width: 100%;
  height: 100%;
}

.gauge {
  display: flex;
  height: 12px;
}

.gauge .done {
  background-color: #ededed;
  width: 50%;
  height: 100%;
}

.gauge .left {
  background-color: #00CE15;
  width: 50%;
  height: 100%;
}

.gauge .left.yellow {
  background-color: #EBFF03;
}

.gauge .left.red {
  background-color: #CE0000;
}

.gauge .left.orange {
  background-color: #FF8C17;
}

.add-maintenance {
  color: #FF9F2F;
  text-align: right;
  margin-bottom: 0.5em;
}

.warning {
  display: flex;
  background: #FFF1CE;
  border-radius: 3px;
  font-size: 12px;
  padding: 1em;
  align-items: center;
  margin-bottom: 1em;
  width: -moz-fit-content;
  width: fit-content;
}

.deeper-table {
  border: 1px solid #dedede;
}

.high-severity {
  color: red;
}

.medium-severity {
  color: #efcb00;
}

.low-severity {
  color: green;
}

.unknown-severity {
  color: darkgrey;
}

@media screen and (min-width: 1024px) {
  .mat-table td {
    font-size: 14px;
  }
  .grid {
    display: grid;
    grid-template-columns: 4fr 10fr;
  }
  .grid > div {
    padding: 1em;
  }
  .last-maintenance {
    display: grid;
    gap: 0.5em;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    margin-top: 1em;
  }
  .last-maintenance {
    grid-template-columns: 48px 2fr;
    justify-items: start;
  }
  .next-icon {
    display: grid;
    grid-column: 3/4;
    grid-row: 1/3;
    align-self: center;
    justify-self: end;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
