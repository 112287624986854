import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, tap } from 'rxjs/operators';
import * as fromActions from './actions';
import { Store } from '@ngrx/store';
import { MaintenanceService } from '@services/api.service';
import { promiseWrapper } from '@shared/_funtions/promiseWarapper.function';
import { Sort } from '@angular/material/sort';

@Injectable()
export class MaintenanceEffects {
  private pageSize: number;
  private pageIndex: number;
  private filters: any;
  private sort: Sort;
  public getData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getMaintenanceList),
      tap(action => {
        this.pageIndex = action.pageIndex;
        this.pageSize = action.pageSize;
        this.sort = action.sort;
      }),
      tap(() => this.store.dispatch(fromActions.setMaintenanceLoading({ value: true }))),
      switchMap(async () => {
        const { q, ...filterModel } = this.filters || {};
        const vehiclesData = await promiseWrapper(this.maintenanceService
          .getPagedMaintenancesList({
            startRow: (this.pageIndex - 1) * this.pageSize,
            endRow: this.pageIndex * this.pageSize - 1,
            qSearch: q,
            filterModel: {
              ...filterModel,
            },
            ...(this.sort ? {
              sortModel: [
                {
                  colId: this.sort.active,
                  sort: this.sort.direction,
                },
              ],

            } : {}),
          }));

        return [fromActions.setMaintenanceList({
          vehicles: vehiclesData.data,
          page: vehiclesData.currentPage,
          pageSize: this.pageSize,
          totalNumber: vehiclesData.totalCount,
        })];
      }),
      switchMap((actions) => [...actions, fromActions.setMaintenanceLoading({ value: false })]),
    );
  });
  public $setFilters = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.setMaintenanceFilters),
      tap(action => {
        this.filters = action.filters;
      }),
      switchMap((actions) => [fromActions.getMaintenanceList({
        pageIndex: 1,
        pageSize: this.pageSize,
      }), fromActions.getMaintenanceSummary()]),
    );
  });
  public $getSummary = createEffect(() => this.actions$.pipe(
    ofType(fromActions.getMaintenanceSummary),
    tap(() => this.store.dispatch(fromActions.setMaintenanceLoading({ value: true }))),
    switchMap(async () => {
      const dataTraffic = this.filters?.dataTraffic?.filter || 'Ctm';
      const {
        totalVehicles,
        expiringMaintenance,
        expiredMaintenance,
      } = await promiseWrapper(this.maintenanceService.getMaintenancesCounters());
      return [fromActions.setMaintenanceSummary({
        value: {
          totalVehicles,
          expiringMaintenance,
          expiredMaintenance,
        },
      })];
    }),
    switchMap((actions) => [...actions]),
  ));

  constructor(
    private actions$: Actions,
    private store: Store,
    private maintenanceService: MaintenanceService,
  ) {
    this.pageIndex = undefined;
    this.pageSize = undefined;
  }
}
