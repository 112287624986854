// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --mat-form-field-container-height: 40px;
}

.filters-menu {
  display: grid;
  padding: 10px 0 0;
  gap: 8px;
  width: 100%;
}
.filters-menu div {
  background: white;
  border: 1px solid #FF8C17;
  border-radius: 0;
  color: #FF8C17;
  overflow: hidden;
  flex-grow: 1;
}
.filters-menu div .mat-mdc-button {
  font-weight: bold;
  font-size: 14px;
  padding: 0 16px;
  height: 30px;
  width: 100%;
  color: inherit;
}
.filters-menu .divider {
  grid-column: 1/4;
  justify-self: center;
  width: 80%;
  opacity: 0.5;
  border-radius: 1px;
}
.filters-menu .active {
  background: #FF8C17;
  color: white;
  border: none;
}

.filters-menu-panel {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 8px;
}

:host {
  position: absolute;
  top: 0px;
  left: 11px;
  z-index: 999;
}
:host .opened {
  max-width: 400px !important;
  height: calc(100vh - 80px - 48px);
}
:host .closed {
  max-width: 60px;
  padding: 0px 0;
  height: 60px;
}
:host .mat-expansion-panel {
  transition: width 0.75s, height 0.25s;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  padding-top: 0;
}
:host .mat-expansion-panel ::ng-deep .mat-expansion-panel-header {
  height: auto;
}
:host .mat-expansion-panel ::ng-deep .mat-expansion-panel-header ::ng-deep .mat-mdc-form-field-wrapper {
  padding: 0 !important;
}
:host .mat-expansion-panel ::ng-deep .mat-expansion-panel-content {
  display: flex;
  flex: 1;
  overflow: auto;
}
:host .mat-expansion-panel ::ng-deep .mat-expansion-panel-content ::ng-deep .mat-expansion-panel-body {
  padding: 0;
  display: flex;
  flex: 1;
  overflow: auto;
}
:host .mat-expansion-panel ::ng-deep .mat-expansion-panel-content ::ng-deep .mat-expansion-panel-body ::ng-deep .mat-mdc-form-field-flex {
  align-items: flex-end;
}
:host .mat-list-icon {
  color: rgba(0, 0, 0, 0.54);
  padding-left: 5px;
}
:host .icon-display {
  transform: scale(1.5);
}
:host .avatar {
  height: 50px;
  width: 50px;
}
:host .container {
  height: 100%;
  width: 100%;
}
:host .fullwidth {
  display: flex;
}
:host .bold {
  font-size: 16px;
  font-weight: bold;
}
:host .cdk-virtual-scroll-viewport {
  height: 100%;
  width: 100%;
  max-width: 100%;
}
:host ::ng-deep .mat-list-item-content {
  padding: 6px !important;
}
:host .example-form {
  min-width: 150px;
  max-width: 500px;
  width: 100%;
}
:host .example-full-width {
  width: 100%;
}
:host .example-section {
  margin: 12px 0;
}
:host .example-margin {
  margin: 0 12px;
}
:host .green-icon {
  color: green;
}
:host .grey-icon {
  color: grey;
}
:host .ngx-skeleton-height {
  max-height: 40vh;
  height: 40vh;
}
:host .fb-item {
  width: auto;
  height: 95px;
  border: 1px solid #eaeaea;
  padding: 5px;
}
:host .gravatar {
  width: 50px;
  height: 40px;
}
:host .gravatar-title {
  width: 400px;
  padding: 5px;
  height: 50px;
}
:host .first-section-wrapper,
:host .second-section-wrapper {
  width: 100%;
  height: auto;
  flex: 1;
}
:host .first-section-wrapper {
  display: inline-flex;
}
:host .second-section-wrapper {
  margin-top: 30px;
}
:host .active-vehicle {
  margin-top: 10px;
}
:host mat-form-field {
  width: 70%;
}
:host .high-severity {
  color: red;
}
:host .medium-severity {
  color: #efcb00;
}
:host .low-severity {
  color: green;
}
:host .unknown-severity {
  color: darkgrey;
}
:host .example-viewport {
  height: 100%;
  width: 100%;
}
:host .example-viewport .cdk-virtual-scroll-content-wrapper {
  max-width: 100% !important;
}
:host .example-alternate {
  background: rgba(127, 127, 127, 0.3);
}

@media screen and (min-width: 490px) {
  :host .opened {
    min-width: 400px !important;
  }
}
/* Runelab */
* {
  box-sizing: border-box;
}

.mat-expansion-panel-header {
  background: hsl(30, 100%, 90%) !important;
  border-bottom: transparent;
  padding: 11px 14px;
}
.mat-expansion-panel-header ::ng-deep .mat-content.mat-content-hide-toggle {
  margin-right: 0;
}
.mat-expansion-panel-header .mat-expansion-panel-header-title {
  align-items: flex-start;
}

::ng-deep .mat-mdc-form-field-infix {
  margin: 0;
  border-top: none;
  box-sizing: border-box;
  padding: 0 0 0.5em;
}

.search-input {
  background: #fff;
  box-sizing: border-box;
}

::ng-deep .mat-mdc-form-field-label-wrapper {
  top: 0;
  left: 12px !important;
  height: 40px !important;
  padding-top: 0;
}

::ng-deep .mat-form-field-empty.mat-form-field-label {
  top: 50%;
  transform: translateY(-50%);
}

.mat-list-base .mat-list-item.mat-list-item-with-avatar.mat-list-option .mat-list-item-content-reverse .mat-list-text, .mat-list-base .mat-list-item.mat-list-item-with-avatar.mat-list-option .mat-list-item-content .mat-list-text, .mat-list-base .mat-list-option.mat-list-item-with-avatar.mat-list-option .mat-list-item-content-reverse .mat-list-text, .mat-list-base .mat-list-option.mat-list-item-with-avatar.mat-list-option .mat-list-item-content .mat-list-text {
  padding: 0 !important;
}

.mat-list-base .mat-list-item.mat-multi-line .mat-list-item-content, .mat-list-base .mat-list-option.mat-multi-line .mat-list-item-content {
  padding: 16px 0 !important;
}

.mat-expansion-panel-header-title {
  margin-right: 0;
}

.mat-content.mat-content-hide-toggle {
  margin-right: 0 !important;
}

.vehicle-wrapper {
  border: solid #AFAFAF;
  border-width: 0px 0px 1px 0px;
}
.vehicle-wrapper.selected {
  border: solid #FF8C17;
  border-width: 3px 0 4px;
}

.mat-selection-list {
  min-height: calc(100% - 24px);
  height: calc(100% - 24px);
  max-width: 100%;
  overflow: auto;
}

.list-status-bar {
  border-top: 1px solid #ddd;
  background: #efefef;
  height: 24px;
  font-size: 11px;
  padding: 0.1em 0.5em;
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.vehicle-item {
  display: grid;
  gap: 0.5em;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: start;
  padding: 8px;
}

.vehicle-item .pic {
  border-radius: 50%;
  border: solid 3px;
  overflow: hidden;
  padding: 4px;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vehicle-item .pic img {
  width: 100%;
}

.details {
  font-size: 0.7em;
  text-align: center;
}
.details .icons-row {
  display: flex;
  font-size: 1rem;
  align-items: center;
  gap: 1em;
}

.details b {
  font-size: 0.8rem;
  word-break: break-all;
}

.actions > div {
  display: grid;
  justify-content: end;
}

.actions button {
  width: auto !important;
  height: auto !important;
  line-height: 1.7;
  padding: 0;
  font-size: inherit;
}
.actions button svg {
  width: initial;
  height: 1em;
}

.active-filter {
  background: #fefefe;
  border-radius: 22px;
  border: 1px solid #999;
  overflow: hidden;
  padding: 0.3em 1em;
  margin-left: 0.5em;
  text-align: center;
}

.pill-filter-selected {
  background: #C8C8C8;
  color: #fff;
}

.equip-card {
  background: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1490196078);
  color: #7D7D7D;
  cursor: pointer;
  display: grid;
  font-size: 13px;
  grid-template-columns: 80px 1fr 24px;
  gap: 0.5rem;
  padding: 10px 8px;
  align-items: center;
}
.equip-card .title {
  color: #212121;
  font-family: Helvetica Neue;
  font-weight: 500;
  text-align: left;
}
.equip-card .icon-circle {
  background: #C4C4C4;
  border-radius: 50%;
  box-shadow: 16px 4px 24px 0px rgba(23, 33, 38, 0.2);
  box-shadow: 0px 0px 1px 0px rgba(23, 33, 38, 0.1607843137);
  border: 2px solid 47C20A;
  color: #fff;
  overflow: hidden;
  padding: 4px;
  width: 80px;
  height: 80px;
  display: grid;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}
.equip-card .icon-circle.linked {
  background: #47C20A;
}
.equip-card .menu-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.equip-card .status {
  font-size: 0.9em;
}

img {
  max-width: 100%;
}

.field-card {
  cursor: pointer;
  background: #fff;
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5em;
  align-items: center;
  padding: 10px 8px;
}
.field-card .title {
  font-family: Helvetica Neue;
  line-height: 24px;
  text-align: left;
  margin-bottom: 0;
  word-break: break-word;
}
.field-card .found-vins-label {
  word-wrap: break-word;
  word-break: break-all;
}
.field-card .vins-wrapper {
  padding-left: 3px;
}
.field-card .vins {
  display: flex;
  padding-left: 10px;
}
.field-card .vin-circle {
  background: #fff;
  border: 2px solid #ccc;
  border-radius: 50%;
  box-shadow: 16px 4px 24px 0px rgba(23, 33, 38, 0.2);
  box-shadow: 0px 0px 1px 0px rgba(23, 33, 38, 0.1607843137);
  overflow: hidden;
  padding: 4px;
  width: 30px;
  height: 30px;
  margin-left: -10px;
}
.field-card .map {
  background: #ccc;
  overflow: hidden;
  border-radius: 50%;
  border: solid 3px;
  overflow: hidden;
  padding: 4px;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.field-card .map img {
  object-fit: cover;
  height: 80px;
  max-width: none;
}

.title {
  color: rgba(0, 0, 0, 0.87);
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
}

.details {
  font-size: 0.8rem;
  text-align: left;
}

.details b {
  font-size: 0.9rem;
  text-align: left;
}

@media screen and (min-width: 600px) {
  .field-card {
    grid-template-columns: 80px 1fr;
  }
}
@media screen and (min-width: 640px) {
  .filters-menu {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .vehicle-item {
    display: grid;
    gap: 0.5em;
    grid-template-columns: 80px 1fr 38px;
    justify-items: start;
    align-items: center;
    padding: 10px 8px;
  }
  .actions {
    padding-right: 3px;
    display: block;
    margin: 0 0 0 auto;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
