import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { EditDialogComponent } from '@portal/equip-mgmt/edit-dialog/edit-dialog.component';
import { typesIcons } from '@shared/_types/EquipDTO';
import { CustomerEquipmentDto } from '@services/api.service';

@Component({
  selector: 'app-equip-card',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule, MatDialogModule, MatButtonModule],
  templateUrl: './equip-card.component.html',
  styleUrl: './equip-card.component.scss'
})

export class EquipCardComponent {
  @Input() equip: CustomerEquipmentDto;
  @Input() linked: boolean;
  @Input() editable: boolean = true;
  constructor(
    private router: Router,
    private dialog: MatDialog
  ) {  }
  goToEquipDetails(id) {
    if (!this.editable) return;
    console.log(id);
    this.router.navigate([`/portal/equip-mgmt/equip-detail/${id}`]);
  }
  getIconClass(type: string): string {
    const iconObj = typesIcons[type]
    return iconObj && iconObj.icon ? `fa-regular ${iconObj.icon}` : 'fa-regular fa-question-circle';
  }
  openEditDialog(event: MouseEvent): void {
    event.stopPropagation();
    if (!this.editable) return;
    const dialogRef = this.dialog.open(EditDialogComponent, {
      height: '80px',
      width: '100px',
      position: {
        top: `${event.clientY}px`,
        left: `${event.clientX - 100}px`,
      },
      backdropClass: 'dialog-backdrop',
      panelClass: 'no-padding-dialog',
      data: { equip: this.equip }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog closed');
    });
  }
}