import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserDataDialog } from '@models/utility';
import { ManageUserFormComponent } from '../add-user-form/manage-user-form.component';
import { TranslateService } from '@ngx-translate/core';
import { Auth0UserDetailDto } from '@shared/_services/api.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  submitted = false;
  newUser: Auth0UserDetailDto;
  pwdVisible = false;
  passwordTooltip: string;

  constructor(
    public dialogRef: MatDialogRef<ManageUserFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserDataDialog,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    // create Tooltip
    this.passwordTooltip = this.translate.instant('UserForm.Notifications.PwdMustBe')+'\n\n' + '• '+ this.translate.instant('UserForm.Notifications.NumberOfChars')+'\n'
                          + '• ' + this.translate.instant('UserForm.Notifications.FormatLetters') +'\n' + '• '+ this.translate.instant('UserForm.Notifications.FormatNumbers')+'\n'


    this.changePasswordForm = this.formBuilder.group({
      password: ['', [Validators.minLength(8), Validators.pattern('^(?=(.*[0-9]))(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}$')]],
      confirmPassword: ['', Validators.minLength(8)]
    }, { validator: this.checkPasswords });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  get changePwdF() {
    return this.changePasswordForm.controls;
  }

  checkPasswords(control: AbstractControl) {
    const password = control.get('password').value;
    const confirmPassword = control.get('confirmPassword').value;
    if (password !== confirmPassword) {
      control.get('confirmPassword').setErrors({ NoPasswordMatch: true });
    }
  }

  changePassword() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.changePasswordForm.invalid) {
      return;
    }

    this.newUser = {};
    this.newUser.password = this.changePwdF.password.value;
    this.dialogRef.close(this.newUser);
  }

}
