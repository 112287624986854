// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-container {
  padding: 1em;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: auto;
  height: auto;
  z-index: 1;
}

.always-mobile .spinner-container {
  background: rgb(255, 255, 255);
}

:host {
  display: grid;
  gap: 1em;
  grid-template-rows: auto 1fr 120px;
  height: 100%;
}

.input-wrapper {
  margin: 1em 0;
}
.input-wrapper label {
  display: block;
}
.input-wrapper input {
  border: none;
  padding: 0.4em 0.7em;
  margin: 0.5em 0 0;
  min-width: 18em;
}

.map-element {
  background: rgba(0, 0, 0, 0.2);
  height: 100%;
  width: 100%;
  display: grid;
  align-items: end;
  position: relative;
}
.map-element ::ng-deep .leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  height: 100%;
}

.input-with-button {
  display: flex;
  gap: 1em;
  align-items: flex-end;
  flex-wrap: wrap;
}
.input-with-button input {
  height: 40px;
}

.keys {
  display: flex;
  gap: 1em;
  margin: 1em 0 1.5em;
}
.keys .circle {
  background-color: #ccc;
  border-radius: 50%;
  height: 18px;
  width: 18px;
}
.keys .actual {
  border: 1px solid #FF8C17;
}
.keys .actual .circle {
  background: #FF8C17;
}
.keys .imported {
  border: 1px solid #0099FF;
}
.keys .imported .circle {
  background: #0099FF;
}

.keys > div {
  display: flex;
  gap: 0.5em;
  align-items: center;
  border-radius: 6px;
  font-size: 0.8em;
  padding: 0.5em;
}

.details-wrapper {
  padding: 1rem;
  background: #fff;
  border-radius: 0 0 8px 8px;
  margin-bottom: 1em;
}

.details {
  display: grid;
  gap: 0.5em 2.5em;
  grid-template-columns: 1fr auto;
  align-items: center;
  height: -moz-fit-content;
  height: fit-content;
  margin-bottom: 1em;
}
.details .label {
  color: #7d7d7d;
  font-weight: 700;
}
.details .value {
  color: #333;
  font-size: 1.2rem;
  font-weight: 700;
}
.details .vins {
  display: flex;
  padding-left: 15px;
}
.details .vin-circle {
  background: #fff;
  border: 2px solid #ccc;
  border-radius: 50%;
  box-shadow: 16px 4px 24px 0px rgba(23, 33, 38, 0.2);
  box-shadow: 0px 0px 1px 0px rgba(23, 33, 38, 0.1607843137);
  overflow: hidden;
  padding: 4px;
  width: 40px;
  height: 40px;
  margin-left: -15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sdf-button {
  border-radius: 3px;
  padding: 0.5em 1em;
}

.btn-negative {
  background: #fff;
  color: #D50B0F;
  border: 1px solid #D50B0F;
}

.btn-default-outline {
  background: #fff;
  color: rgba(34, 41, 48, 0.9);
  border: 1px solid rgb(242, 242, 242);
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

.action-buttons {
  text-align: center;
  display: flex;
  gap: 1em;
  justify-content: center;
  flex-wrap: wrap;
}
.action-buttons .sdf-button {
  width: 230px;
  height: 32px;
}

.page-container.body .map {
  height: 75%;
}

@media screen and (min-width: 1024px) {
  .page-container.body {
    display: grid;
    gap: 1.5em;
    grid-template-columns: 350px 1fr;
  }
  .page-container.body .map {
    height: 100%;
  }
  .details-wrapper {
    margin-bottom: 0;
  }
  .details {
    margin-bottom: 0;
  }
  .action-buttons {
    text-align: center;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
