import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'app-paginator',
  template: `
    <div class='paginator'>
      <button (click)='firstPage()'><i class='fas fa-angle-double-left'></i></button>
      <button (click)='previousPage()' [disabled]='currentPage <= 1'><i class='fas fa-angle-left'></i></button>
      <span class='paginator-info'>{{'VehicleManagement.MaintenanceAlarms.Table.Page' | translate}} {{currentPage}} {{'VehicleManagement.MaintenanceAlarms.Table.Of' | translate}} {{numberOfPages}}</span>
      <button (click)='nextPage()' [disabled]='currentPage >= numberOfPages'><i class='fas fa-angle-right'></i></button>
      <button (click)='lastPage()'><i class='fas fa-angle-double-right'></i></button>
    </div>
  `,
  styles: [`
    .paginator {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1em;
    }

    button {
      padding: 0.5em 1em;
      margin: 0 0.5em;
      border: 1px solid #333;
      border-radius: 5px;
      background-color: #fff;
      color: #333;
      cursor: pointer;
    }

    button[disabled] {
      cursor: not-allowed;
      opacity: 0.5;
    }

    .paginator-info {
      margin: 0 1em;
    }
  `],
})
export class PaginatorComponent implements OnChanges {
  @Input() totalEntries: number;
  @Input() rowsPerPage: number;
  @Output() pageChanged = new EventEmitter<number>();


  currentPage = 1;
  numberOfPages: number;

  ngOnChanges() {
    this.numberOfPages = Math.ceil(this.totalEntries / this.rowsPerPage);
  }

  firstPage() {
    this.currentPage = 1;
    this.pageChanged.emit(this.currentPage);
  }

  resetPage() {
    this.currentPage = 1;
  }


  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.pageChanged.emit(this.currentPage);
    }
  }

  nextPage() {
    if (this.currentPage < this.numberOfPages) {
      this.currentPage++;
      this.pageChanged.emit(this.currentPage);
    }
  }

  lastPage() {
    this.currentPage = this.numberOfPages;
    this.pageChanged.emit(this.currentPage);
  }
}
