import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { getActiveAlarmList, getActiveAlarmSummary } from '@redux/modules/alarms/actions';
import {
  selectActiveAlarmPage,
  selectActiveAlarmTotalNumber,
  selectActiveAlarmVehicles,
  selectIsActiveAlarmLoading,
} from '@redux/modules/alarms';
import { ActiveAlarmDto } from '@shared/_services/api.service';
import { Sort } from '@angular/material/sort';
import { PaginatorComponent } from '@components/paginator.component';
import { TranslateService } from '@ngx-translate/core';
import { localeMap } from '@models/user';

@Component({
  selector: 'app-alarms-list',
  templateUrl: './alarms-list.component.html',
  styleUrls: ['./alarms-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AlarmsListComponent implements OnInit, AfterViewInit {
  expandedElement: PeriodicElement | null;
  dataSource = new MatTableDataSource<ActiveAlarmDto>();
  currentVin: string = null;
  locale;
  @ViewChild('paginator', { static: false }) paginator: PaginatorComponent;
  page = 1;
  maintenanceHighSeverity = 'EXPIRED';
  maintenanceMediumSeverity = 'EXPIRING';
  maintenanceLowSeverity = 'OK';
  totalNumber: number;
  showModal: boolean;
  maintenanceStatusData: object;
  columnsToDisplay: string[] = [
    'vin',
    'businessName',
    'lowAlarmCounter',
    'mediumAlarmCounter',
    'highAlarmCounter',
    'actions',

  ];
  isLoading: boolean;
  sort: Sort;

  constructor(
    private store: Store,
    private translate: TranslateService,
  ) {

    this.locale = localeMap[this.translate.currentLang];
  }

  ngOnInit(): void {
  }

  closeModal() {
  }

  pageChanged(event) {
    this.store.dispatch(getActiveAlarmList({ pageIndex: event, pageSize: 10, sort: this.sort }));
  }

  ngAfterViewInit() {

    this.store.dispatch(getActiveAlarmList({ pageIndex: 1, pageSize: 10 }));
    this.store.dispatch(getActiveAlarmSummary());

    this.store.select(selectIsActiveAlarmLoading).subscribe((isLoading) => {
      this.isLoading = isLoading;
    });
    this.store.select(selectActiveAlarmPage).subscribe((page) => {
      console.log('page', page);
      this.page = page;
      if (page === 1) {
        this.paginator.resetPage();
      }
    });
    this.store.select(selectActiveAlarmVehicles).subscribe((vehicles) => {
      this.dataSource.data = vehicles;

    });

    this.store.select(selectActiveAlarmTotalNumber).subscribe((totalNumber) => {
      console.log('totalNumber', totalNumber);
      this.totalNumber = totalNumber;
    });
  }

  isUnknown(severity) {
    return severity === 'Unknown';
  }

  updateList() {
    this.store.dispatch(getActiveAlarmList({ pageIndex: this.page, pageSize: 10, sort: this.sort }));
  }

  sortTable(sort: Sort) {
    this.paginator.resetPage();
    this.sort = sort;
    this.store.dispatch(getActiveAlarmList({ pageIndex: 1, pageSize: 10, sort }));
  }

}

export interface PeriodicElement {
  vin: string;
  customer: string;
  brand: string;
  model: string;
  engineHours: number;
  maintenanceHours: number;
  alarms: number;
  alarmsSeverity: string;
  lastAlarmDate: string;
  lastAlarmHours: number;
  nextMaintenaceDate: string;
  missingHoursToNextAlarm: number;
  nextAlarmDays: number;
}
