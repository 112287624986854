import { Auth0UserDetailDto } from "@shared/_services/api.service";

export interface Address {
  street?: string;
  number?: string;
  postal_code?: string;
  city?: string;
  country?: string;
}

export interface Phone {
  office?: string;
  mobile?: string;
}

export interface PrivacyAcceptance {
  box1?: boolean;
  box2?: boolean;
  box3?: boolean;
  box4?: boolean;
}

export interface MetaData {
  language?: string;
  phone_number?: Phone;
  address?: Address;
  fiscal_code?: string;
  business_name?: string;
  privacy_acceptance?: PrivacyAcceptance;
  contact_email?: string;
  roles?: UserRole[];
  parent?: Auth0UserDetailDto;
}

export enum UserRole {
  Customer = 'customer',
  Dealer = 'dealer',
  Subsidiary = 'subsidiary',
  Factory = 'factory'
}

export enum UserRoleUpper {
  customer = 'Customer',
  dealer = 'Dealer',
  subsidiary = 'Subsidiary',
  factory = 'Factory'
}

export enum Languages {
  Dutch = 'nl',
  English = 'en',
  French = 'fr',
  German = 'de',
  Italian = 'it',
  Polish = 'pl',
  Portuguese = 'pt',
  Romanian = 'ro',
  Russian = 'ru',
  Spanish = 'es',
  Danish = 'da'
}

export const localeMap = {
  [Languages.Dutch]: 'nl',
  [Languages.English]: 'en-US',
  [Languages.French]: 'fr-FR',
  [Languages.German]: 'de',
  [Languages.Italian]: 'it',
  [Languages.Polish]: 'pl-PL',
  [Languages.Portuguese]: 'pt-PT',
  [Languages.Romanian]: 'ro',
  [Languages.Russian]: 'ru',
  [Languages.Spanish]: 'es',
  [Languages.Danish]: 'da',
};
export interface CounterUser {
  customer?: number;
  dealer?: number;
  subsidiary?: number;
  factory?: number;
}

export const userHierarchy = {
  factory: UserRole.Subsidiary,
  subsidiary: UserRole.Dealer,
  dealer: UserRole.Customer,
  customer: '',
};

export const userHierarchyParent = {
  factory: UserRole.Factory,
  subsidiary: UserRole.Factory,
  dealer: UserRole.Subsidiary,
  customer: UserRole.Dealer,
};

export interface UserGroup {
  letter: string;
  users: Auth0UserDetailDto[];
}
