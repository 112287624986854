import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { getGeoviewCustomerVehicles } from '@redux/modules/geoview-customer/actions';

@Component({
  selector: 'app-geoview-customer',
  templateUrl: './geoview.component.html',
  styleUrls: ['./geoview.component.scss'],
})

export class GeoviewCustomerComponent implements OnInit {



  constructor(
    private store: Store,
  ) {
  }


  ngOnInit() {

    this.store.dispatch(getGeoviewCustomerVehicles({ filters: {} }));

  }



}
