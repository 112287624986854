import { createReducer, on } from '@ngrx/store';
import * as Actions from './actions';
import * as RootActions from '../../actions';
import { VehicleAlarmHistoryViewDto } from '@services/api.service';

export interface State {
  page: number;
  pageSize: number
  isLoading: boolean,
  alarms: VehicleAlarmHistoryViewDto[];
  totalNumber: number;
  filters: any
}


export const initialState: State = {
  isLoading: false,
  page: 1,
  pageSize: 10,
  alarms: [],
  totalNumber: 0,
  filters: {},
};

export const selectIsAlarmLoading = state => state.alarm.isLoading;
export const selectAlarmSummaryData = state => state.alarm.summaryData;
export const selectAlarms = state => state.alarm.vehicles;
export const selectAlarmPage = state => state.alarm.page;
export const selectAlarmPageSize = state => state.alarm.pageSize;
export const selectAlarmTotalNumber = state => state.alarm.totalNumber;
export const selectAlarmFilters = state => state.alarm.filters;


export const alarmReducer = createReducer(
  initialState,
  on(RootActions.resetState, () => ({ ...initialState })),
  on(Actions.setAlarmLoading, (state, { value }) => ({ ...state, isLoading: value })),
  on(Actions.setAlarmList, (state, {
    vehicles,
    page,
    pageSize,
    totalNumber,
  }) => ({
    ...state, vehicles,
    page,
    pageSize,
    totalNumber,
  })),
);


