import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OverviewService {
  private subjectVehicleOverviewList = new Subject<any>();


  sendRefreshVehicleOverviewList() {
    this.subjectVehicleOverviewList.next({ text: 'refresh Vehicle overview List ' });
  }

  getRefreshVehicleOverviewListChanged(): Observable<any> {
    return this.subjectVehicleOverviewList.asObservable();
  }
}



