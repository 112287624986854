// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-toolbar {
  min-height: 53px !important;
  height: 53px !important;
}

.mat-toolbar-row {
  min-height: 53px !important;
  height: 53px !important;
}

.toolbar-button {
  margin-left: 5px;
  margin-right: 5px;
}

.example-button-row {
  display: table-cell;
  width: 100%;
}

.example-button-row .mat-button-base {
  margin: 8px 8px 8px 0;
}

.toolbar {
  background-color: #F8F8FA;
  padding: 0px;
}

.menu-button {
  color: #666466;
  font-size: large;
}

.active-button {
  color: #FA8C17;
  font-weight: bold;
  font-size: large;
}

.mat-divider {
  border-top-color: #FA8C17;
  border-top-width: 3px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
