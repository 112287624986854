import { createReducer, on } from '@ngrx/store';
import * as Actions from './actions';
import * as RootActions from '../../actions';
import { ActiveAlarmCounterDto, ActiveAlarmDto } from '@services/api.service';

export interface State {
  page: number;
  pageSize: number
  isLoading: boolean,
  vehicles: ActiveAlarmDto[];
  totalNumber: number;
  summaryData: ActiveAlarmCounterDto
  filters: any
}


export const initialState: State = {
  isLoading: false,
  page: 1,
  summaryData: {},
  pageSize: 10,
  vehicles: [],
  totalNumber: 0,
  filters: {},
};

export const selectIsActiveAlarmLoading = state => state.vehiclesActiveAlarms.isLoading;
export const selectActiveAlarmSummaryData = state => state.vehiclesActiveAlarms.summaryData;
export const selectActiveAlarmVehicles = state => state.vehiclesActiveAlarms.vehicles;
export const selectActiveAlarmPage = state => state.vehiclesActiveAlarms.page;
export const selectActiveAlarmPageSize = state => state.vehiclesActiveAlarms.pageSize;
export const selectActiveAlarmTotalNumber = state => state.vehiclesActiveAlarms.totalNumber;
export const selectActiveAlarmFilters = state => state.vehiclesActiveAlarms.filters;


export const alarmsReducer = createReducer(
  initialState,
  on(RootActions.resetState, () => ({ ...initialState })),
  on(Actions.setActiveAlarmSummary, (state, { value }) => ({ ...state, summaryData: value })),
  on(Actions.setActiveAlarmLoading, (state, { value }) => ({ ...state, isLoading: value })),
  on(Actions.setActiveAlarmFilters, (state, { filters }) => ({ ...state, filters })),

  on(Actions.setActiveAlarmList, (state, {
    vehicles,
    page,
    pageSize,
    totalNumber,
  }) => ({
    ...state, vehicles,
    page,
    pageSize,
    totalNumber,
  })),
);


