// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
  border-left: 2px solid;
  border-right: 2px solid;
  border-color: #565656;
  height: 10px;
  display: flex;
  align-items: center;
}
:host .progress {
  height: 100%;
  border-radius: unset !important;
}
:host .remaining {
  height: 2px;
  flex: 1;
  background: #DEDEDE;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
