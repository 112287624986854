import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserService } from '@services/user.service';
import { AuthService } from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '@services/loader.service';
import { UserRole } from '@models/user';
import { selectUser, selectUserPreferences } from '@redux/index';
import { setUserPreferences } from '@redux/actions';
import { UserPreferences } from '@shared/_types/UserPreferences';
import { Auth0UserDetailDto } from '@shared/_services/api.service';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss']
})
export class PortalComponent implements OnInit {

  appLoaded = false;
  user: Auth0UserDetailDto;
  userRole: string;
  menuOpen = true

  private preferences: UserPreferences;
  constructor(
    public store: Store,
    public router: Router,
    private userService: UserService,
    public translate: TranslateService,
    private auth: AuthService,
    private loaderService: LoaderService
  ) {
    translate.addLangs(['en', 'it']);
    translate.setDefaultLang('en');
  }

  toggleMenu (value) {
    this.store.dispatch(setUserPreferences({ value: { ...this.preferences, sidebar: !this.menuOpen } }));
  }

  ngOnInit(): void {
    this.loaderService.show();
    this.store.select(selectUserPreferences).subscribe(preferences => {
      this.preferences = preferences;
      this.menuOpen = preferences.sidebar;
    });
    this.store.select(selectUser).pipe(filter(user => user != null)).subscribe(user => {
      this.user = user;
      this.userRole = this.user.roles[0];
    });
    this.auth.isAuthenticated$.subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        this.auth.user$.pipe(first()).subscribe((userInfo) => {
          this.userService.setUserInfo(userInfo.sub).then(() => {
            // Setting the Role for the HasDealer call
            if (this.userRole === UserRole.Customer) {
              this.userService.setUserHasDealerInfo(userInfo.sub).then(() => {
                this.appLoaded = true;
                this.loaderService.hide();
              }).catch(() => {
                this.loaderService.hide();
              })
            }
            else {
              this.appLoaded = true;
              this.loaderService.hide();
            }
          }).catch(() => {
            this.loaderService.hide();
          })
        })
      }
    })
  }
}
