import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { PortalComponent } from '@portal/portal.component';
import { LegalPagesComponent } from './legal-pages/legal-pages.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'portal',
    pathMatch: 'full',
  },
  {
    path: 'portal',
    canActivate: [AuthGuard],
    component: PortalComponent,
    loadChildren: () => import('@portal/portal.module').then(m => m.PortalModule),
  },
  {
    path: 'legals/:type/:language',
    component: LegalPagesComponent,
  },
  {
    path: 'error',
    loadChildren: () => import('./error-pages/error-pages.module').then(m => m.ErrorPagesModule),
  },

  { path: '**', redirectTo: 'portal' }, // this needs to be after other routes
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
