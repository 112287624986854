import { Component, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Chart, registerables } from 'chart.js';
import { CustomerEquipmentService, CustomerEquipmentDto, CustomerEquipmentLastPositionDto, CustomerEquipmentWorkedHoursDto } from '@services/api.service';

Chart.register(...registerables);
@Component({
  selector: 'app-equip-detail',
  templateUrl: './equip-detail.component.html',
  styleUrl: './equip-detail.component.scss'
})
export class EquipDetailComponent implements OnInit, OnDestroy {
  @ViewChild('workedHoursChart', { static: false }) workedHoursChartRef!: ElementRef<HTMLCanvasElement>;
  id: string;
  showForm = false;
  defaultImplement: CustomerEquipmentDto = { name: '', macAddress: '', purchaseDate: null, type: '', model: '', brand: '', vehicles: [ { vin: '' }]};
  equip: CustomerEquipmentDto = this.defaultImplement;
  lastPosition: CustomerEquipmentLastPositionDto;
  workedHours: CustomerEquipmentWorkedHoursDto[];

  // Charts
  private charts: {
    workedHours?: any;
  } = {};
  private readonly chartConfig = {
    colors: {
      consumption: ['#4CAF50', '#2196F3'],
      profile: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
      equipment: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'bottom' as const,
          padding: 10,
          labels: {
            padding: 20,
          }
        },
      },
    },
  };

  constructor (
    public route: ActivatedRoute,
    private equipmentService: CustomerEquipmentService,
    public router: Router,
    public store: Store
  ) {
    this.route.params.subscribe(params => {
      this.id = params.id;
      if (this.id === 'new') this.showForm = true;
    });
  }

  ngOnInit() {
    if (this.id && this.id !== 'new') {
      this.getCurrentEquip();
      this.getEquipmentLastPosition();
      this.getEquipmentWorkedHours()
    }
  }

  ngOnDestroy(): void {
    // Cleanup charts
    Object.values(this.charts).forEach(chart => chart?.destroy());
  }

  getCurrentEquip() {
    this.equipmentService.getEquipment(this.id).subscribe((equipment) => {
      this.equip = equipment as CustomerEquipmentDto;
    });
  }
  getEquipmentLastPosition () {
    this.equipmentService.getEquipmentLastPosition(this.id).subscribe((lastPosition) => {
      this.lastPosition = lastPosition as CustomerEquipmentLastPositionDto;
    });
  }
  getEquipmentWorkedHours () {
    this.equipmentService.getEquipmentWorkedHoursByVin(this.id).subscribe((workedHours) => {
      this.workedHours = workedHours as CustomerEquipmentWorkedHoursDto[];
      console.log(this.workedHours)
      if (this.workedHours) {
        this.processChartsData(this.workedHours);
      }
    });
  }

  getImageSrc(): string {
    switch (this.equip.type) {
      case 'Baler':
        return '/assets/images/baler.jpg';
      case 'Mower':
        return '/assets/images/mower.jpg';
      case 'Sprayer':
        return '/assets/images/sprayer.jpg';
      default:
        return '/assets/images/generic-equip.png';
    }
  }

  private processChartsData(data: any): void {
    this.initializeWorkedHoursChart();
    this.updateWorkedHoursChart(data);
  }

  private initializeWorkedHoursChart(): void {
    console.log('workedHoursChartRef', this.workedHoursChartRef);
    const ctx = this.workedHoursChartRef?.nativeElement?.getContext('2d');
    if (ctx) {
      this.charts.workedHours = new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: [],
          datasets: [{
            data: [],
            backgroundColor: this.chartConfig.colors.profile,
          }],
        },
        options: this.chartConfig.options,
      });
    }
  }

  private updateWorkedHoursChart(data: CustomerEquipmentWorkedHoursDto[]): void {
    console.log('chart data: ', data)
    console.log('labels: ', data.map((w) => { return w.vin }))
    console.log('datasets: ', data.map((w) => { return w.workedHours }))
    if (!this.charts.workedHours) {
      this.initializeWorkedHoursChart();
    }
    console.log('this.charts.workedHours: ', this.charts.workedHours)
    console.log('this.workedHoursChartRef 2: ', this.workedHoursChartRef)
    if (this.charts.workedHours && data) {
      this.charts.workedHours.data.labels = data.map((w) => { return w.vin });
      this.charts.workedHours.data.datasets[0] = {
        data: data.map((w) => { return w.workedHours }),
        backgroundColor: [
          '#5B8FF9',
          '#FF9F7F',
          '#37C6C0',
          '#F7BD2A',
          '#5D7092',
          '#79D8B8',
          '#95A2FF',
          '#FFB980',
        ],
        borderWidth: 0,
        cutout: '70%',
      };
      this.charts.workedHours.update('none');
    }
  }
}
