import { NgModule } from '@angular/core';
import { FieldDetailComponent } from '@portal/fields-mgmt/field-detail/field-detail.component';
import { FieldsListComponent } from '@portal/fields-mgmt/fields-list/fields-list.component';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    component: FieldsListComponent,
    data: {
      title: 'Fields Management'
    }
  },
  {
    data: {
    },
    path: 'field-detail/:id',
    component: FieldDetailComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FieldsMgmtRoutingModule {
}
