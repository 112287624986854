// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `svg {
  max-width: 100%;
}

:host {
  position: relative;
}

.hidden {
  display: none;
}

.mat-table {
  cursor: pointer;
  width: 100%;
}

::ng-deep .header-right .mat-sort-header-container {
  justify-content: flex-end !important;
}

::ng-deep th.mat-header-cell:last-of-type {
  padding-right: 6px !important;
}

.mat-header-row {
  min-height: 36px;
}

.mat-row, .mat-footer-row {
  min-height: 36px;
}

.mat-table td {
  padding: 0 1em;
  font-size: 12px;
}

tr.detail-row {
  height: 0;
}

tr.element-row:not(.expanded-row):hover {
  background: whitesmoke;
}

tr.element-row:not(.expanded-row):active {
  background: #efefef;
}

.element-row td {
  border-bottom-width: 0;
}

.element-detail {
  overflow: hidden;
}

.element-diagram {
  min-width: 80px;
  border: 2px solid black;
  padding: 8px;
  font-weight: lighter;
  margin: 8px 0;
  height: 104px;
}

.element-symbol {
  font-weight: bold;
  font-size: 40px;
  line-height: normal;
}

.element-description {
  padding: 16px;
}

.element-description-attribution {
  opacity: 0.5;
}

.high-severity {
  color: red;
}

.medium-severity {
  color: #efcb00;
}

.low-severity {
  color: green;
}

.unknown-severity {
  color: rgba(0, 0, 0, 0.24);
}

.add-maintenance {
  color: #FF9F2F;
  text-align: right;
  margin-bottom: 0.5em;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
}

.modal-overlay .content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  min-width: 450px;
  width: 70%;
  background: #fff;
  max-height: 100%;
  overflow-y: scroll;
}

@media screen and (min-width: 1024px) {
  .mat-table td {
    font-size: 12px;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
