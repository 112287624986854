import { FormControl } from '@angular/forms';

export const _noWhitespaceValidator = (control: FormControl) => {
  const isWhitespace = (control.value || '').trim().length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : { 'required': true };
}

export const _dateAfterToday = (control: FormControl) => {
  if (!control.value) {
    return null;
  }
  const today = new Date();
  const target = new Date(control.value);
  return target <= today ? null : { 'outOfRange': true };
}

export const _numberOnly = (event): boolean => {
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode >= 48 && charCode <= 57) {
    return true;
  }
  return false;
}

export const _numbersAndPlus = (event): boolean => {
  const charCode = (event.which) ? event.which : event.keyCode;
  if ((charCode >= 48 && charCode <= 57) || charCode === 43) {
    return true;
  }
  return false;
}

export const _numbersAndLetters = (event): boolean => {
  const charCode = (event.which) ? event.which : event.keyCode;
  if ((charCode >= 48 && charCode <= 57) || (charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122)) {
    return true;
  }
  return false;
}
