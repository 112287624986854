// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-container {
  padding: 15px;
}

.user-name {
  margin-bottom: 12px;
}

a.logout {
  cursor: pointer;
  color: #5F9AFF;
  font-weight: 700;
  font-size: 0.9em;
  text-transform: uppercase;
}

.app-version {
  font-size: 0.7em;
}

.app-version span {
  font-weight: 500;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
