// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-account-dialog-content {
  padding: 0 !important;
}

.add-account-container {
  padding: 35px;
  height: 100%;
}

select.form-control.is-invalid {
  background-image: none;
}

.mat-dialog-content {
  padding: 20px;
  font-size: 1rem;
  font-weight: 400;
}

.info-icon {
  font-size: 18px;
  color: #5F9AFF;
  vertical-align: text-bottom;
}

.select-language {
  padding-left: 25px;
}

.is-selected .mat-select-value {
  color: #757d85;
}

.section-title {
  font-weight: 600;
}

.form-control {
  font-size: 1rem;
}

.edit-label {
  margin-bottom: 0;
  text-align: left;
  width: 100%;
  font-size: 0.6rem;
  margin-left: 0;
  font-weight: 600;
  color: #666466;
  text-transform: uppercase;
}

.mat-dialog-actions {
  justify-content: center;
}

.action-button {
  padding: 0.5rem 3rem;
  margin-left: 8px;
}

.add-account-dialog-content {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.restore-language-icon {
  margin-top: 40px;
  cursor: pointer;
}

::ng-deep .material-tooltip {
  white-space: pre-line;
}

button svg {
  display: block;
}

button .material-icons {
  height: auto;
  width: auto;
}

.header-title {
  background: #C8C8C8;
  font-weight: 600;
  height: 45px;
}

.header-title .title-text {
  padding: 10px;
}

.close-button {
  padding: 6px !important;
  width: 30px !important;
  height: 30px !important;
}

.action-buttons {
  display: grid;
  gap: 1em;
  grid-template-columns: repeat(2, 190px);
  margin-top: 1em;
}

.form-group {
  align-items: center;
}

.checkbox-description {
  margin-bottom: 0;
}

.input-wrapper {
  background: #F2F2F2;
  padding: 10px 16px;
  width: 100%;
}

.edit-label {
  margin-left: 0;
}

.basic-input {
  border: none;
  padding: 0.3em;
  font-size: 14px;
  background: #fff;
  width: 100%;
  box-sizing: border-box;
}

.basic-input.basic-input-select {
  padding: 0.2em 0.3em;
}

.icon-form-group {
  display: grid;
  gap: 1em;
  grid-template-columns: 30px 1fr;
  align-items: end;
}

.btn-primary, .btn-secondary {
  border-radius: 0;
}

.new-password-wrapper {
  position: relative;
}

.mat-icon.password-visibility {
  position: absolute;
  right: 0;
  top: 5px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
