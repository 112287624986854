const requestScopes = 'openid profile email offline_access';

export const environment = {
  production: false,
  Version: require('../../package.json').version,
  apiURL: 'https://eu-de.functions.appdomain.cloud/api/v1/web/093662e6-71b6-4c4f-9be7-7f1ee6fdebaa',
  apiURLV2: 'https://api-platform-dev.sdf-dataplatform-stag.com',
  notificationApiURL: 'https://sdf-notification-rule-engine.m8b1kn07z4h.eu-de.codeengine.appdomain.cloud',
  agrirouterApiURLV2: 'https://sdf-imonitor-datamanagement-be.m8b1kn07z4h.eu-de.codeengine.appdomain.cloud',
  novuURL: 'https://api-notification-service.sdf-dataplatform.com',
  novuWsURL: 'https://ws-notification-service.sdf-dataplatform.com',
  novuSubEnv: 'dev',
  novuApplicationIdentifier: 'ywJdkTQ0ijQB',
  topconWsUrl: 'https://sdf-dataplatform-topcon-websockets.m8b1kn07z4h.eu-de.codeengine.appdomain.cloud/hubs/telemetry',
  btmWsUrl: 'https://sdf-dataplatform-btm-websockets.m8b1kn07z4h.eu-de.codeengine.appdomain.cloud/hubs/telemetry',
  sdfWsUrl: 'https://sdf-dataplatform-websockets.m8b1kn07z4h.eu-de.codeengine.appdomain.cloud/hubs/telemetry',
  firebaseConfig: {
    apiKey: 'AIzaSyBWz2mf0vtuUt6-XvwiQuIm6tbn2M8iNvA',
    authDomain: 'sdf-data-platform-dev.firebaseapp.com',
    projectId: 'sdf-data-platform-dev',
    storageBucket: 'sdf-data-platform-dev.appspot.com',
    messagingSenderId: '686930578465',
    appId: '1:686930578465:web:5f607f56c8cc187f7e6760',
    measurementId: 'G-LY JG6PB20X'
  },
  envName: 'dev',
  vehicleDefaultImageBucket:'https://default-vehicles-img-bucket-prod.s3.eu-de.cloud-object-storage.appdomain.cloud/',
  auth: {
    domain: 'sdf-dev-test.eu.auth0.com',
    clientId: 'E1CMplDyVqT5JEHIwZBR79w89zhB7rdl',
    scope: requestScopes,
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: 'https://sdf-dev-test.eu.auth0-test.com/sdf-platform-api',
    },
  },
  httpInterceptor: {
    allowedList: [
      {
        uri: 'https://eu-de.functions.appdomain.cloud/api/v1/web/093662e6-71b6-4c4f-9be7-7f1ee6fdebaa/sdf_protected/*',
        tokenOptions: { scope: requestScopes, audience: 'https://sdf-dev-test.eu.auth0-test.com/sdf-platform-api' },
      },
      {
        uri: 'https://sdf-notification-rule-engine.m8b1kn07z4h.eu-de.codeengine.appdomain.cloud/*',
        tokenOptions: { scope: requestScopes, audience: 'https://sdf-dev-test.eu.auth0-test.com/sdf-platform-api' },
      },
      {
        uri: 'https://eu-de.functions.appdomain.cloud/api/v1/web/093662e6-71b6-4c4f-9be7-7f1ee6fdebaa/ar_protected/*',
        tokenOptions: { scope: requestScopes, audience: 'https://sdf-dev-test.eu.auth0-test.com/sdf-platform-api' },
      },
      {
        uri: 'https://api-platform-dev.sdf-dataplatform-stag.com/*',
        tokenOptions: { scope: requestScopes, audience: 'https://sdf-dev-test.eu.auth0-test.com/sdf-platform-api' },
      },
      {
        uri: 'https://localhost:5001/*',
        tokenOptions: { scope: requestScopes, audience: 'https://sdf-dev-test.eu.auth0-test.com/sdf-platform-api' },
      },
      {
        uri: 'https://sdf-imonitor-datamanagement-be.m8b1kn07z4h.eu-de.codeengine.appdomain.cloud/*',
        tokenOptions: { scope: requestScopes, audience: 'https://sdf-dev-test.eu.auth0-test.com/sdf-platform-api' },
      },
    ],
  },
};
