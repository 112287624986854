import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import * as fromActions from './actions';
import { UserManagementService } from '@services/api.service';
import { UserPreferences } from '@shared/_types/UserPreferences';

@Injectable()
export class CommonEffects {

  public syncUserPreferences$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.setUserPreferences),
    filter(action => !action.skipEffect),
    debounceTime(1000),
    tap(action => {
      this.userManagementService.patchUiPreferences(action.value).subscribe();
    }),
  ), { dispatch: false });

  public getUserPreferences$ = createEffect(() => this.actions$.pipe(
    ofType(fromActions.setUser),
    switchMap(() =>
      this.userManagementService.getUiPreferences(),
    ),
    switchMap((value: string) => [fromActions.setUserPreferences({ value: value as unknown as UserPreferences, skipEffect: true })]),
  ));

  constructor(
    private actions$: Actions,
    public userManagementService: UserManagementService,
  ) {

  }
}
