import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserRole } from '@models/user';
import { Store } from '@ngrx/store';
import { _updateJSONObject, countryList, fieldsByRole, languageList, removeEmptyandNulls } from 'src/app/shared/_utils/users';
import { UserDataDialog } from '@models/utility';
import { MessageService } from '@services/message.service';
import { TranslateService } from '@ngx-translate/core';
import { FilesService } from '@services/files.service';
import { _noWhitespaceValidator, _numberOnly, _numbersAndLetters, _numbersAndPlus } from 'src/app/shared/_utils/general';
import { selectRolesCanCreate, selectUser } from '@redux/index';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { Auth0UserDetailDto } from '@shared/_services/api.service';

@Component({
  selector: 'app-manage-user-form',
  templateUrl: './manage-user-form.component.html',
  styleUrls: ['./manage-user-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class ManageUserFormComponent implements OnInit {
  submitted = false;
  userForm: FormGroup;
  userTypeList = [];
  user: Auth0UserDetailDto;
  currentUser: Auth0UserDetailDto;
  typeForm: string;
  action = '';
  Role = UserRole;
  fieldsByRole = fieldsByRole;
  myself = false;
  languageList = languageList;
  countryList = countryList;
  languageSelected = false;
  countrySelected = false;
  userImage = '#';
  imageUploaded = false;
  imageToUpload;
  errorFileSize = false;

  numberOnly = _numberOnly;
  numbersAndPlus = _numbersAndPlus;
  numbersAndLetters = _numbersAndLetters;

  constructor(
    public dialogRef: MatDialogRef<ManageUserFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserDataDialog,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private store: Store,
    private translate: TranslateService,
    private filesService: FilesService,
  ) {
  }

  ngOnInit() {

    this.store.select(selectUser).pipe(filter(user => user != null), distinctUntilChanged()).subscribe(user => {
      this.currentUser = user;
      if (this.data.action === 'edit') {
        this.languageSelected = true;
        this.countrySelected = true;

        if (this.data.item.email === user.email) {
          this.myself = true;
        }
      }
    });
    this.action = this.data.action === 'edit' ? this.translate.instant('UserMgmt.Dialogs.Actions.Save') : this.translate.instant('UserMgmt.Dialogs.Actions.Add');
    this.store.select(selectRolesCanCreate).pipe(filter(rolesCanCreate => rolesCanCreate != null)).subscribe(rolesCanCreate => {
      this.userTypeList = rolesCanCreate.slice().map(role => {
        return { name: role.charAt(0).toUpperCase() + role.slice(1), value: role };
      });
    });
    this.typeForm = this.data.item.roles[0];
    this.updateDialogDimension();
    this.buildForm();
  }

  restoreLanguage() {

  }

  setLanguageFlag() {
    this.languageSelected = true;
  }

  setCountryFlag() {
    this.countrySelected = true;
  }

  goToPage(path) {
    let language = 'en';
    if (this.currentUser.user_metadata && this.currentUser.user_metadata.language) {
      language = this.currentUser.user_metadata.language;
    }
    window.open(path + '/' + language, '_blank', 'width=800,height=400,top=200,left=400');
  }

  buildForm() {
    this.userImage = this.data.item.picture;
    this.userForm = this.formBuilder.group({
        name: [this.data.action === 'edit' && this.data.item.given_name ? this.data.item.given_name : '', fieldsByRole.name[this.typeForm] ? [_noWhitespaceValidator, Validators.required] : [() => {
        }]],
        surname: [this.data.action === 'edit' && this.data.item.family_name ? this.data.item.family_name : '', fieldsByRole.surname[this.typeForm] ? [_noWhitespaceValidator, Validators.required] : [() => {
        }]],
        email: [this.data.action === 'edit' && this.data.item.email ? this.data.item.email : '', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
        language: [this.data.action === 'edit' && this.data.item.user_metadata && this.data.item.user_metadata.language ? this.data.item.user_metadata.language : this.data.action === 'edit' ? 'en' : '', Validators.required],
        phoneOffice: [this.data.action === 'edit' && this.data.item.user_metadata && this.data.item.user_metadata.phone_number && this.data.item.user_metadata.phone_number.office ? this.data.item.user_metadata.phone_number.office : '', [fieldsByRole.phoneOffice[this.typeForm] !== undefined ? Validators.pattern('(([+?(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})') : () => {
        }, fieldsByRole.phoneOffice[this.typeForm] ? Validators.required : () => {
        }]],
        phoneMobile: [this.data.action === 'edit' && this.data.item.user_metadata && this.data.item.user_metadata.phone_number && this.data.item.user_metadata.phone_number.mobile ? this.data.item.user_metadata.phone_number.mobile : '', [Validators.pattern('(([+?(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})'), fieldsByRole.phoneMobile[this.typeForm] ? Validators.required : () => {
        }]],
        fiscalCode: [this.data.action === 'edit' && this.data.item.user_metadata && this.data.item.user_metadata.fiscal_code ? this.data.item.user_metadata.fiscal_code : '', fieldsByRole.fiscalCode[this.typeForm] ? [_noWhitespaceValidator, Validators.required] : [() => {
        }]],
        businessName: [this.data.action === 'edit' && this.data.item.user_metadata && this.data.item.user_metadata.business_name ? this.data.item.user_metadata.business_name : '', fieldsByRole.businessName[this.typeForm] ? [_noWhitespaceValidator, Validators.required] : [() => {
        }]],
        city: [this.data.action === 'edit' && this.data.item.user_metadata && this.data.item.user_metadata.address && this.data.item.user_metadata.address.city ? this.data.item.user_metadata.address.city : '', fieldsByRole.city[this.typeForm] ? [_noWhitespaceValidator, Validators.required] : [() => {
        }]],
        country: [this.data.action === 'edit' ? this.data.item.user_metadata && this.data.item.user_metadata.address && this.data.item.user_metadata.address.country ? this.data.item.user_metadata.address.country : '' : '', [fieldsByRole.country[this.typeForm] ? Validators.required : () => {
        }]],
        addressStreet: [this.data.action === 'edit' && this.data.item.user_metadata && this.data.item.user_metadata.address && this.data.item.user_metadata.address.street ? this.data.item.user_metadata.address.street : '', fieldsByRole.addressStreet[this.typeForm] ? [_noWhitespaceValidator, Validators.required] : [() => {
        }]],
        addressNumber: [this.data.action === 'edit' && this.data.item.user_metadata && this.data.item.user_metadata.address && this.data.item.user_metadata.address.number ? this.data.item.user_metadata.address.number : '', [fieldsByRole.addressNumber[this.typeForm] ? Validators.required : () => {
        }]],
        addressPostalCode: [this.data.action === 'edit' && this.data.item.user_metadata && this.data.item.user_metadata.address && this.data.item.user_metadata.address.postal_code ? this.data.item.user_metadata.address.postal_code : '', [Validators.pattern('^[a-zA-Z0-9]+$'), fieldsByRole.addressPostalCode[this.typeForm] ? Validators.required : () => {
        }]],
        contactEmail: [this.data.action === 'edit' && this.data.item.user_metadata && this.data.item.user_metadata.contact_email ? this.data.item.user_metadata.contact_email : '', [Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'), fieldsByRole.contactEmail[this.typeForm] ? Validators.required : () => {
        }]],
        /*password: ['', [Validators.minLength(8), Validators.pattern('^(?=(.*[0-9]))(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}$')]],
        confirmPassword: ['', Validators.minLength(8)],*/
        termsOfUse: [true],
        privacy: [true],
        privacyOne: [this.data.action === 'edit' && this.data.item.user_metadata && this.data.item.user_metadata.privacy_acceptance && this.data.item.user_metadata.privacy_acceptance.box2 ? this.data.item.user_metadata.privacy_acceptance.box2 : false],
        privacyTwo: [this.data.action === 'edit' && this.data.item.user_metadata && this.data.item.user_metadata.privacy_acceptance && this.data.item.user_metadata.privacy_acceptance.box3 ? this.data.item.user_metadata.privacy_acceptance.box3 : false],
        privacyThree: [this.data.action === 'edit' && this.data.item.user_metadata && this.data.item.user_metadata.privacy_acceptance && this.data.item.user_metadata.privacy_acceptance.box4 ? this.data.item.user_metadata.privacy_acceptance.box4 : false],
        userType: [this.typeForm],
      },

      // { validator: this.checkPasswords }
    );
  }

  get userF() {
    return this.userForm.controls;
  }

  onNoClick(): void {
    if (this.data.action === 'edit' && (this.userForm.dirty || this.imageUploaded)) {
      const text = this.translate.instant('UserMgmt.Dialogs.Titles.ChangesNotSaved');
      const dialogRef = this.messageService.openDialog(' ', text, true);
      dialogRef.afterClosed().subscribe((confirm: boolean) => {
        if (confirm) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  updateTypeForm(selectedUserRole) {
    this.submitted = false;
    this.typeForm = selectedUserRole;
    this.updateDialogDimension();
    this.buildForm();
  }

  updateDialogDimension() {
    if (this.typeForm === this.Role.Factory) {
      this.dialogRef.updateSize('700px', '600px');
    } else if (this.typeForm === this.Role.Customer || this.typeForm === this.Role.Dealer || this.typeForm === this.Role.Subsidiary) {
      this.dialogRef.updateSize('700px', '850px');
    }
  }

  checkPasswords(control: AbstractControl) {
    const password = control.get('password').value;
    const confirmPassword = control.get('confirmPassword').value;
    if (password !== confirmPassword) {
      control.get('confirmPassword').setErrors({ NoPasswordMatch: true });
    }
  }

  uploadImage(input) {
    this.errorFileSize = false;
    const file = input.files[0];
    if (file.size > 3145728) {
      this.errorFileSize = true;
    } else {
      this.filesService.blobToBase64(file)
        .subscribe((base64Image) => {
          this.userImage = base64Image;
          this.imageToUpload = this.userImage;
          this.imageUploaded = true;
        });
    }
  }

  cancelImage() {
    this.filesService.assetToBase64('./assets/icons/no_photo.png')
      .subscribe((base64Image) => {
        this.errorFileSize = false;
        this.userImage = base64Image;
        this.imageToUpload = this.userImage;
        this.imageUploaded = true;
      });
  }

  manageUser() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.userForm.invalid) {
      return;
    }

    this.user = {};
    if (this.data.action === 'create') {
      this.user.given_name = this.userForm.value.name;
      this.user.family_name = this.userForm.value.surname;
      this.user.email = this.userForm.value.email;
      this.user.user_metadata = {
        roles: [this.userForm.value.userType],
        language: this.userForm.value.language,
        phone_number: {
          mobile: this.userForm.value.phoneMobile,
          office: this.userForm.value.phoneOffice,
        },
        address: {
          city: this.userForm.value.city,
          country: this.userForm.value.country,
          street: this.userForm.value.addressStreet,
          number: this.userForm.value.addressNumber,
          postal_code: this.userForm.value.addressPostalCode,
        },
        fiscal_code: this.userForm.value.fiscalCode,
        privacy_acceptance: {
          box1: true,
          box2: this.userForm.value.privacyOne ? this.userForm.value.privacyOne : false,
          box3: this.userForm.value.privacyTwo ? this.userForm.value.privacyTwo : false,
          box4: this.userForm.value.privacyThree ? this.userForm.value.privacyThree : false,
        },
        business_name: this.userForm.value.businessName,
        contact_email: this.userForm.value.contactEmail,
      };
    } else {
      if (!this.myself) {
        this.user.user_id = this.data.item.user_id;
      }

      this.user.picture = this.data.item.picture;
      if (this.imageUploaded) {
        this.user.picture = this.imageToUpload;
      }
      if (!this.data.item.user_metadata.language) {
        this.user = _updateJSONObject({
          user_metadata: {
            language: this.userF.language.value,
          },
        }, this.user);
      }
      if (this.userF.name.dirty) {
        this.user = _updateJSONObject({
          given_name: this.userF.name.value,
        }, this.user);
      }
      if (this.userF.surname.dirty) {
        this.user = _updateJSONObject({
          family_name: this.userF.surname.value,
        }, this.user);
      }
      if (this.userF.language.dirty) {
        this.user = _updateJSONObject({
          user_metadata: {
            language: this.userF.language.value,
          },
        }, this.user);
      }
      if (this.userF.fiscalCode.dirty) {
        this.user = _updateJSONObject({
          user_metadata: {
            fiscal_code: this.userF.fiscalCode.value,
          },
        }, this.user);
      }
      if (this.userF.businessName.dirty) {
        this.user = _updateJSONObject({
          user_metadata: {
            business_name: this.userF.businessName.value,
          },
        }, this.user);
      }
      if (this.userF.email.dirty) {
        this.user = _updateJSONObject({
          email: this.userF.email.value,
        }, this.user);
      }
      /*if (this.userF.password.dirty) {
        this.user = _updateJSONObject({
          password: this.userF.password.value
        }, this.user);
      }*/

      if (this.userF.phoneMobile.dirty) {
        this.user = _updateJSONObject({
          user_metadata: {
            phone_number: {
              mobile: this.userF.phoneMobile.value,
            },
          },
        }, this.user);
      }
      if (this.userF.phoneOffice.dirty) {
        this.user = _updateJSONObject({
          user_metadata: {
            phone_number: {
              office: this.userF.phoneOffice.value,
            },
          },
        }, this.user);
      }
      if (this.userF.addressPostalCode.dirty) {
        this.user = _updateJSONObject({
          user_metadata: {
            address: {
              postal_code: this.userF.addressPostalCode.value ? this.userF.addressPostalCode.value : '',
            },
          },
        }, this.user);
      }
      if (this.userF.addressStreet.dirty) {
        this.user = _updateJSONObject({
          user_metadata: {
            address: {
              street: this.userF.addressStreet.value,
            },
          },
        }, this.user);
      }
      if (this.userF.addressNumber.dirty) {
        this.user = _updateJSONObject({
          user_metadata: {
            address: {
              number: this.userF.addressNumber.value ? this.userF.addressNumber.value : '',
            },
          },
        }, this.user);
      }
      if (this.userF.city.dirty) {
        this.user = _updateJSONObject({
          user_metadata: {
            address: {
              city: this.userF.city.value,
            },
          },
        }, this.user);

      }
      if (this.userF.country.dirty) {
        this.user = _updateJSONObject({
          user_metadata: {
            address: {
              country: this.userF.country.value,
            },
          },
        }, this.user);
      }
      if (this.userF.contactEmail.dirty) {
        this.user = _updateJSONObject({
          user_metadata: {
            contact_email: this.userF.contactEmail.value,
          },
        }, this.user);
      }
      if (this.userF.privacyOne.dirty) {
        this.user = _updateJSONObject({
          user_metadata: {
            privacy_acceptance: {
              box2: this.userF.privacyOne.value,
            },
          },
        }, this.user);
      }
      if (this.userF.privacyTwo.dirty) {
        this.user = _updateJSONObject({
          user_metadata: {
            privacy_acceptance: {
              box3: this.userF.privacyTwo.value,
            },
          },
        }, this.user);
      }
      if (this.userF.privacyThree.dirty) {
        this.user = _updateJSONObject({
          user_metadata: {
            privacy_acceptance: {
              box4: this.userF.privacyThree.value,
            },
          },
        }, this.user);
      }
    }

    if (this.data.action === 'create') {
      this.dialogRef.close(removeEmptyandNulls(this.user));
    } else {
      this.dialogRef.close(this.user);
    }
  }
}


