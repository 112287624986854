import { createReducer, on } from '@ngrx/store';
import * as Actions from './actions';
import * as RootActions from '../../actions';
import { Pagination } from '@shared/shared.interface';

export interface State {
  pagination: Pagination,
  isLoading: boolean,
  vehiclesAlarmsList: any[];
  totalNumber: number;
  summaryData: any;
  filters: any
}


export const initialState: State = {
  isLoading: false,
  pagination: {
    page: 1,
    pageSize: 10,
  },
  vehiclesAlarmsList: [],
  totalNumber: 0,
  filters: {},
  summaryData: {},
};

export const selectAlarmsLoading = state => state.vehiclesAlarms.isLoading;
export const selectVehiclesAlarmsList = state => state.vehiclesAlarms.vehiclesAlarmsList;
export const selectSummaryData = state => state.vehiclesAlarms.summaryData;
export const selectTotalNumber = state => state.vehiclesAlarms.totalNumber;
export const selectPagination = state => state.vehiclesAlarms.pagination;
export const selectFilters = state => state.vehiclesAlarms.filters;

export const vehicleAlarmsReducer = createReducer(
  initialState,
  on(RootActions.resetState, () => ({ ...initialState })),
  on(Actions.setAlarmList, (state, action) => ({
    ...state,
    alarmsList: action.alarmsList,
    page: action.page,
    pageSize: action.pageSize,
    totalNumber: action.totalNumber
  })),
  on(Actions.setAlarmLoading, (state, { value }) => ({ ...state, isLoading: value })),
  on(Actions.setSummaryData, (state, { summaryData }) => ({ ...state, summaryData })),
  on(Actions.setVehiclesAlarmsFilters, (state, { filters }) => ({ ...state, filters })),
);


