import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { UserDialogComponent } from './user-dialog/user-dialog.component';
import { Router } from '@angular/router';
import { UserRole } from '@models/user';
import { AuthService } from '@auth0/auth0-angular';
import { selectUser } from '@redux/index';
import { filter } from 'rxjs/operators';
import { NavigationService } from './navigation-service.service';
import { UserPreferences } from '@shared/_types/UserPreferences';
import { Auth0UserDetailDto } from '@shared/_services/api.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  title = 'Connections';
  user: Auth0UserDetailDto;
  userRole: string;
  Role = UserRole;

  private preferences: UserPreferences;
  constructor(
    public dialog: MatDialog,
    public store: Store,
    public router: Router,
    private auth: AuthService,
    private navigation: NavigationService
  ) {}

  back(): void {
    this.navigation.back()
  }

  ngOnInit() {
    this.store.select(selectUser).pipe(filter(user => user != null)).subscribe(user => {
      this.user = user;
      this.userRole = this.user.roles[0];
    });
  }


  openUserDialog(): void {
    const dialogRef = this.dialog.open(UserDialogComponent, {
      height: '130px',
      position: {
        top: '90px',
        right: '19px',
      },
      backdropClass: 'dialog-backdrop',
      panelClass: 'no-padding-dialog',
    });
    dialogRef.afterClosed().subscribe(() => {});
  }
}
