import { Component, Input, OnInit } from '@angular/core';
import { SbdDto } from '@services/api.service';

@Component({
  selector: 'app-sbd-score',
  templateUrl: './sbd-score.component.html',
  styleUrls: ['./sbd-score.component.scss'],
})
export class SbdScoreComponent implements OnInit {
  @Input() value: SbdDto;
  @Input() position: 'above' | 'below' = 'above';

  constructor() {
  }

  get getCircleColor(): string {
    if (this.value.sbdScore < 0) {
      return '#c9c9c9';
    } else if (this.value.sbdScore < 50) {
      return '#efcb00';
    } else if (this.value.sbdScore < 70) {
      return '#ffc107';
    } else if (this.value.sbdScore < 90) {
      return 'orange';
    } else {
      return 'red';
    }
  }

  ngOnInit(): void {
  }

}
