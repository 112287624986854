// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img {
  width: 100%;
}

.header-container {
  width: 100%;
  height: 80px;
}

.header-user-container {
  margin-right: 18px;
}

.header-title {
  background: transparent;
  position: relative;
  top: -3px;
  font-size: 32px;
  color: #000;
  font-weight: 700;
  margin-left: 18px;
  min-width: 204px;
}

.logo-image {
  display: none !important;
  cursor: pointer;
  opacity: 0.7;
  margin-left: 16px;
  min-width: 32px;
  width: 32px;
  transition: all 0.5s;
}

.rotate {
  opacity: 1;
  display: flex !important;
  transform: rotate(180deg);
}

.profile {
  flex-grow: 1;
}

.logo-header {
  height: 33px;
  margin-left: 33px;
  margin-top: 4px;
}

.material-icons {
  font-size: 3rem;
}

@media screen and (max-width: 710px) {
  .logo-image {
    display: flex !important;
  }
  .header-title {
    font-size: 1.5rem;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
