import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { FieldMapComponent } from '@portal/fields-mgmt/field-map/field-map.component';
import { FieldPositionDto } from '@shared/_types/FieldsDTO';
import { CoordinateDto, CustomerFieldService, CustomerFieldWithGeomAsCoordinatesDto } from '@services/api.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from '@services/message.service';
import { LoaderService } from '@services/loader.service';

@Component({
  selector: 'app-field-detail',
  templateUrl: './field-detail.component.html',
  styleUrl: './field-detail.component.scss',
})
export class FieldDetailComponent {
  @ViewChild(FieldMapComponent) fieldMapComponent: FieldMapComponent;

  id: string;
  field: CustomerFieldWithGeomAsCoordinatesDto;
  showRecalc = false;
  editableMap = true;
  saved = false;

  fieldPosition: FieldPositionDto;
  fieldBoundaries: CoordinateDto[];
  fieldBoundariesImported: CoordinateDto[] = [];

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private fieldsService: CustomerFieldService,
    public store: Store,
    private loaderService: LoaderService,
    private messageService: MessageService,
    private translate: TranslateService,
  ) {
    this.route.params.subscribe(params => {
      this.id = params.id;
    });
  }

  ngOnInit() {
    this.getCurrentField();
  }

  onRecalc() {
    console.log('Recalc');
    this.showRecalc = !this.showRecalc;
    // this.fieldBoundariesImported = [...this.fieldBoundariesSample1];
    this.editableMap = false;
  }

  getCurrentField() {
    this.fieldsService.getField(this.id).subscribe((field) => {
      this.field = field as CustomerFieldWithGeomAsCoordinatesDto;
      this.field.area = Number(this.field?.area?.toFixed(2)) || 0;
      this.fieldBoundaries = this.field.geometry;
      // calculate center of the field with turf.js

    });
  }

  downloadFieldFile() {
    this.fieldsService.getShapefile(this.id).subscribe((response) => {
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${this.field.name}.zip`;
      a.click();
      document.body.removeChild(a);

    })
  }
  onDeleteClick() {
    console.log('delete');
    const title = this.translate.instant('FieldsManagement.Dialogs.Messages.Warning');
    const text = this.translate.instant('FieldsManagement.Dialogs.RemoveField') + '?';
    const dialogRef = this.messageService.openDialog(title, text, true);
    dialogRef.afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        this.loaderService.show();
        this.fieldsService.deleteField(this.field).subscribe((response) => {
          this.loaderService.hide();
          this.router.navigate(['/portal/fields-mgmt']);
        }, _ => {
          this.loaderService.hide();
        });
      }
    });
  }

  onSave() {
    const updatedFieldBoundaries = this.fieldMapComponent.getUpdatedFieldBoundaries();
    console.log('Updated Field Boundaries:', updatedFieldBoundaries);
    const body = {
      id: this.id,
      name: this.field.name,
      geometry: updatedFieldBoundaries,
      originalFieldId: this.id
    }
    console.log('da field: ', this.field)
    this.saved = this.field?.saved;
    if (this.saved) {
      this.update(body);
    } else {
      this.create(body);
    }
  }
  update(body) {
    this.loaderService.show();
    this.fieldsService.updateField(body).subscribe(
      (result) => {
        this.loaderService.hide();
        console.log('updated: ', result);
        this.router.navigate(['/portal/fields-mgmt']);
      },
      (error) => {
        this.loaderService.hide();
        console.error('Error saving field: ', error);
      }
    );
  }
  create(body) {
    this.loaderService.show();
    this.fieldsService.createField(body).subscribe(
      (result) => {
        this.loaderService.hide();
        console.log('created: ', result);
        this.router.navigate(['/portal/fields-mgmt']);
      },
      (error) => {
        this.loaderService.hide();
        console.error('Error creating field: ', error);
      }
    );
  }
}
