import { createAction, props } from '@ngrx/store';


export const loadDashboard = createAction(
  'loadDashboard',
  props<{
    startDate?: Date,
    endDate?: Date,
  }>(),
);
export const setDashboardPeriod = createAction(
  'setDashboardPeriod',
  props<{
    startDate: Date,
    endDate: Date,
  }>(),
);
export const setDashboardData = createAction(
  'setDashboardData',
  props<{
    data: any,
  }>(),
);

export const setUserDashboardLoading = createAction(
  'setUserDashboardLoading',
  props<{ value: boolean }>(),
);
