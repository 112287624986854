// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-container {
  padding: 1em;
}

.item-title {
  font-size: 16px;
  font-weight: bolder;
  margin-bottom: 0.6em;
}

.content {
  padding: 1rem;
  background: white;
  border-radius: 0 0 8px 8px;
  overflow: auto;
  flex: 1;
  width: 100%;
}
.content .chart-container {
  height: 100%;
  position: relative;
}
.content .chart-container canvas {
  width: 100% !important;
  height: 100% !important;
}

.map-element {
  background: rgba(0, 0, 0, 0.2);
  height: 500px;
  width: 100%;
  display: grid;
  align-items: end;
  position: relative;
  margin-inline: -1.1rem;
  margin-bottom: -1.1rem;
  width: calc(100% + 2.2rem);
}
.map-element ::ng-deep .leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  height: 100%;
}

.grid-container {
  display: grid;
  gap: 1em;
  grid-template-columns: repeat(2, 1fr);
  padding-bottom: 8px;
  margin: 1em;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.grid-item {
  background-color: #fff;
  padding: 1.1rem;
  grid-column: span 2;
}

.full-width-item {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  padding: 0 1rem;
  grid-column: span 2;
}

.map-item {
  grid-column: span 2;
}

.photo-item {
  grid-column: span 2;
}

.form-item {
  grid-column: span 2;
}

:ng-deep input, :ng-deep select {
  background: #F2F2F2 !important;
}

@media screen and (min-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 1024px) {
  .grid-container {
    grid-template-columns: repeat(4, 1fr);
  }
  .grid-item {
    grid-column: span 4;
  }
  .map-item {
    grid-column: span 4;
  }
  .full-width-item {
    grid-column: span 4;
  }
  .photo-item {
    grid-column: span 4;
  }
  .form-item {
    grid-column: span 4;
  }
}
@media screen and (min-width: 1200px) {
  .grid-container {
    grid-template-columns: repeat(12, 1fr);
  }
  .grid-item {
    grid-column: span 4;
  }
  .map-item {
    grid-column: span 8;
  }
  .full-width-item {
    grid-column: span 12;
  }
  .photo-item {
    grid-column: span 5;
  }
  .form-item {
    grid-column: span 7;
  }
}
@media screen and (min-width: 1800px) {
  .grid-container {
    grid-template-columns: repeat(16, 1fr);
  }
  .grid-item {
    grid-column: span 4;
  }
  .map-item {
    grid-column: span 11;
  }
  .full-width-item {
    grid-column: span 16;
  }
  .photo-item {
    grid-column: span 6;
  }
  .form-item {
    grid-column: span 10;
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
