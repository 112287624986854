// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overview-container {
  height: calc(100vh - 80px - 15px - 53px);
  padding: 1em;
}

.filters {
  display: grid;
  gap: 1em;
  margin-bottom: 1em;
}

.filters > div {
  background: #fff;
  border-radius: 0;
  cursor: pointer;
  font-size: 0.8em;
  padding: 1em 1.5em;
}

.filter-data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5em;
  margin-bottom: 0.5em;
}

.filter-selected {
  background: #ccc !important;
}

.item-value {
  font-size: 36px;
  font-weight: bold;
  line-height: 1;
}

.high-severity {
  color: red;
}

.medium-severity {
  color: #efcb00;
}

.low-severity {
  color: green;
}

.panel-title {
  color: #fff;
  background: #C8C8C8;
  padding: 0.3em 0.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5em;
}

.search-input-wrapper {
  background: hsl(30, 100%, 97%);
  padding: 10px 16px;
}

.vin-search {
  border: none;
  padding: 0.3em;
  font-size: 14px;
}

@media screen and (min-width: 1024px) {
  .filters {
    grid-template-columns: repeat(4, 1fr);
  }
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
