import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerFieldService } from '@services/api.service';

@Component({
  selector: 'app-fields-list',
  templateUrl: './fields-list.component.html',
  styleUrl: './fields-list.component.scss',
})
export class FieldsListComponent implements OnInit {
  @Input() alwaysMobile: boolean = false;
  @Input() verySmall: boolean = false;
  @Input() showActions: boolean = true;
  @Input() showTitles: boolean = true;
  
  loading = false;
  detectedFields = [];

  showDeleted = false;
  savedFields = [];
  deletedFields = [];

  constructor(
    private router: Router,
    private fieldsService: CustomerFieldService,
  ) {
  }

  ngOnInit() {
    this.getSavedFields();
    this.getDetectedFields();
  }


  getSavedFields() {
    this.loading = true;
    this.fieldsService.getFields(true, false).subscribe((fields) => {
      this.savedFields = fields;
      this.loading = false;
    });
  }

  getDetectedFields() {
    this.loading = true;
    this.fieldsService.getFields(false, false).subscribe((fields) => {
      this.detectedFields = fields;
      this.loading = false;
    });
  }

  getDeletedFields() {
    this.loading = true;
    this.fieldsService.getFields(undefined, true).subscribe((fields) => {
      this.deletedFields = fields;
      this.loading = false;
    });
  }

  showDeletedFields() {
    if (!this.showDeleted) this.getDeletedFields();
    this.showDeleted = !this.showDeleted;
  }

  goToCreateNewField() {
    console.log(this.detectedFields);
    console.log(this.savedFields);
    const id = 'new';
    this.router.navigate([`/portal/fields-mgmt/field-detail/${id}`]);
  }
}
