import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { CustomerFieldDto } from '@services/api.service';
import { MatButton } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-field-card',
  standalone: true,
  imports: [CommonModule, RouterModule, MatButton, TranslateModule, MatTooltipModule],
  templateUrl: './field-card.component.html',
  styleUrl: './field-card.component.scss'
})

export class FieldCardComponent {
  @Input() field: CustomerFieldDto;
  constructor(
    private router: Router
  ) {  }
  goToFieldsDetails(id) {
    console.log(id);
    // console.log(this.savedFields);
    // const vin = 'WSXFT40200LD10624';
    // console.log(`/portal/fields-mgmt/field-detail/${id}`)
    this.router.navigate([`/portal/fields-mgmt/field-detail/${id}`]);
    // this.showDetail = true
  }
}
