import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserRole } from '../_models/user';
import { UserService } from '../_services/user.service';

@Directive({ selector: '[appUserRole]' })
export class UserRoleDirective implements OnInit {
  constructor(
    private templateRef: TemplateRef<any>,
    private userService: UserService,
    private viewContainer: ViewContainerRef,
  ) {
  }

  userRoles: UserRole[];

  @Input()
  set appUserRole(roles: UserRole[]) {
    if (!roles || !roles.length) {
      throw new Error('Roles value is empty or missed');
    }

    this.userRoles = roles;
  }

  ngOnInit() {

    this.userService.hasRole(this.userRoles || []).then((hasAccess) => {


      if (hasAccess) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }
}
