import { Component, Injector, OnInit } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { UserRole } from '@models/user';
import { Analytics, ScreenTrackingService, setUserProperties } from '@angular/fire/analytics';
import { Performance } from '@angular/fire/performance';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  user: User;


  constructor(
    screenTracking: ScreenTrackingService,
    private auth: AuthService,
    injector: Injector,
  ) {
    const performance = injector.get(Performance);

    console.log('performance', performance);
    performance.dataCollectionEnabled = true;



    this.auth.user$.subscribe(user => {
      this.user = user;
      if (!this.user?.['sdf-user-roles']?.includes(UserRole.Customer)) {
        setUserProperties(injector.get(Analytics), {
          email: user?.email,
          name: user?.name,
          roles: user?.['sdf-user-roles'],
        });
      }
    });
    // this.route.fragment.subscribe(url => {
    //   if (!this.user?.['sdf-user-roles']?.includes(UserRole.Customer)) {
    //     console.log('page_view', url);
    //     logEvent(window.analytics, 'page_view', {
    //       url: url.join('/'),
    //     });
    //   }
    // });
  }

  ngOnInit(): void {
  }
}
