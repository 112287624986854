// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  height: 100%;
  width: 100%;
}

.map {
  height: 100%;
  width: 100%;
}

.overlay {
  border-radius: 25px;
  height: 60%;
  z-index: 1000;
  position: absolute;
  top: 250px;
  width: 385px;
  margin: 0 15px;
  background: #F8F8FA;
}

::ng-deep .marker-cluster-small {
  background-color: rgba(234, 173, 109, 0.6);
}

::ng-deep .marker-cluster-small div {
  background-color: rgba(234, 173, 109, 0.6);
}

::ng-deep .marker-cluster-medium {
  background-color: rgba(232, 152, 68, 0.6);
}

::ng-deep .marker-cluster-medium div {
  background-color: rgba(232, 152, 68, 0.6);
}

::ng-deep .marker-cluster-large {
  background-color: rgba(245, 124, 0, 0.6);
}

::ng-deep .marker-cluster-large div {
  background-color: rgba(245, 124, 0, 0.6);
}

::ng-deep .stacked-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
}
::ng-deep .stacked-icon .main {
  position: absolute;
  font-size: 1em;
  color: #999999;
}
::ng-deep .stacked-icon .small {
  position: absolute;
  font-size: 0.7em;
  margin-top: -0.1em;
  margin-left: 0.02em;
}
::ng-deep .stacked-icon .supersmall {
  position: absolute;
  font-size: 0.3em;
  margin-left: 0.02em;
  margin-top: -0.8em;
}

::ng-deep .leaflet-marker-icon {
  background: transparent;
  border: none;
}

/* IE 6-8 fallback colors */
::ng-deep .leaflet-oldie .marker-cluster-small {
  background-color: rgb(181, 226, 140);
}

::ng-deep .leaflet-oldie .marker-cluster-small div {
  background-color: rgb(110, 204, 57);
}

::ng-deep .leaflet-oldie .marker-cluster-medium {
  background-color: rgb(241, 211, 87);
}

::ng-deep .leaflet-oldie .marker-cluster-medium div {
  background-color: rgb(240, 194, 12);
}

::ng-deep .leaflet-oldie .marker-cluster-large {
  background-color: rgb(253, 156, 115);
}

::ng-deep .leaflet-oldie .marker-cluster-large div {
  background-color: rgb(241, 128, 23);
}

::ng-deep .marker-cluster {
  background-clip: padding-box;
  border-radius: 20px;
}

::ng-deep .marker-cluster div {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-top: 5px;
  text-align: center;
  border-radius: 15px;
  font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
}

::ng-deep .marker-cluster span {
  line-height: 30px;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
