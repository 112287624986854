import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, tap } from 'rxjs/operators';
import * as fromActions from './actions';
import { Store } from '@ngrx/store';
import { VehicleService } from '@services/api.service';
import { promiseWrapper } from '@shared/_funtions/promiseWarapper.function';
import { Sort } from '@angular/material/sort';
import { selectUser } from '@redux/index';

@Injectable()
export class AlarmEffects {
  private pageSize: number;
  private pageIndex: number;
  private filters: any;
  private sort: Sort;
  private language: string;
  public getData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getAlarmList),
      tap(action => {
        this.pageIndex = action.pageIndex;
        this.pageSize = action.pageSize;
        this.sort = action.sort || {
          active: 'alarmStartDateTime',
          direction: 'desc',
        };
      }),
      tap(() => this.store.dispatch(fromActions.setAlarmLoading({ value: true }))),
      switchMap(async () => {
        const { q, ...filterModel } = this.filters || {};
        const vehiclesData = await promiseWrapper(this.vehicleService
          .getVehicleAlarmsHistory({
            startRow: (this.pageIndex - 1) * this.pageSize,
            endRow: this.pageIndex * this.pageSize - 1,
            language: this.language,
            qSearch: q,
            filterModel,
            ...(this.sort ? {
              sortModel: [
                {
                  colId: this.sort.active,
                  sort: this.sort.direction,
                },
              ],

            } : {}),
          }));

        return [fromActions.setAlarmList({
          vehicles: vehiclesData.data,
          page: vehiclesData.currentPage,
          pageSize: this.pageSize,
          totalNumber: vehiclesData.totalCount,
        })];
      }),
      switchMap((actions) => [...actions, fromActions.setAlarmLoading({ value: false })]),
    );
  });
  public $setFilters = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.setAlarmFilters),
      tap(action => {
        this.filters = action.filters;
      }),
      switchMap((actions) => [fromActions.getAlarmList({ pageIndex: 1, pageSize: this.pageSize || 10 })]),
    );
  });


  constructor(
    private actions$: Actions,
    private store: Store,
    private vehicleService: VehicleService,
  ) {
    this.pageIndex = undefined;
    this.pageSize = undefined;
    this.store.select(selectUser).subscribe(user => {
      if (!user?.user_metadata) {
        return;
      }
      this.language = user?.user_metadata?.language || 'en';

    });
  }
}
