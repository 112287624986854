import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CreateDialogComponent } from '@portal/equip-mgmt/create-dialog/create-dialog.component';
import { CustomerEquipmentService } from '@services/api.service';
import { CustomRouteReuseStrategy } from '@portal/equip-mgmt/reoute-invalidator';


@Component({
  selector: 'app-equip-list',
  templateUrl: './equip-list.component.html',
  styleUrl: './equip-list.component.scss'
})
export class EquipListComponent implements OnInit {
  @Input() alwaysMobile: boolean = false;
  @Input() showActions: boolean = true;
  @Input() showTitles: boolean = true;
  loading = false;
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private equipmentService: CustomerEquipmentService,
    private routeReuseStrategy: CustomRouteReuseStrategy,
  ) {
    this.routeReuseStrategy.routeInvalidated$.subscribe((path) => {
      this.getEquips();
    });
  }
  showDetail = false;
  linkedEquipmentList = [];
  equipmentList = [];
  ngOnInit() {
    // this.getLinkedEquips()
    this.getEquips()
  }

  getLinkedEquips() {
    this.equipmentService.getEquipments().subscribe((equipments) => {
      this.linkedEquipmentList = equipments;
    });
  }

  getEquips() {
    this.loading = true;
    this.equipmentService.getEquipments().subscribe((equipments) => {
      this.equipmentList = equipments;
      this.loading = false;
    });
  }

  goToCreateNewEquip() {
    console.log(this.equipmentList);
    const id = 'new';
    this.router.navigate([`/portal/equip-mgmt/equip-detail/${id}`]);
  }
  openCreateDialogDialog(event: MouseEvent): void {
    const dialogRef = this.dialog.open(CreateDialogComponent, {
      width: '50em',
      maxHeight: 'calc(100vh - 40px)',
      backdropClass: 'dialog-backdrop',
      panelClass: 'no-padding-dialog'
    });

    dialogRef.afterClosed().subscribe(result => {
      // Handle any actions after the dialog is closed, if needed
      this.getEquips();
      console.log('Dialog closed');
    });
  }
}
