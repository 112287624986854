import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldsMgmtComponent } from '@portal/fields-mgmt/fields-mgmt.component';
import { FieldCardComponent } from './field-card/field-card.component';
import { FieldDetailComponent } from '@portal/fields-mgmt/field-detail/field-detail.component';
import { FieldsListComponent } from '@portal/fields-mgmt/fields-list/fields-list.component';
import { FieldsMgmtRoutingModule } from '@portal/fields-mgmt/fields-mgmt-routing.module';
import { FieldMapComponent } from '@portal/fields-mgmt/field-map/field-map.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from '@helpers/error.interceptor';
import { MatIconModule } from '@angular/material/icon';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletMarkerClusterModule } from '@asymmetrik/ngx-leaflet-markercluster';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
  declarations: [
    FieldDetailComponent,
    FieldsMgmtComponent,
    FieldsListComponent,
    FieldMapComponent
  ],
  imports: [
    FieldCardComponent,
    FieldsMgmtRoutingModule,
    MatIconModule,
    CommonModule,
    LeafletModule,
    LeafletMarkerClusterModule,
    FormsModule,
    TranslateModule,
    RouterModule,
    MatButtonModule,
    MatProgressSpinner,
    MatTooltipModule
  ],
  exports: [
    FieldsMgmtComponent,
    FieldDetailComponent,
    FieldsMgmtComponent,
    FieldsListComponent,
    FieldMapComponent
  ], providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }],

})
export class FieldsMgmtModule {
}
