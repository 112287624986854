import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import * as L from 'leaflet';
import { Marker } from 'leaflet';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-field-map',
  templateUrl: './field-map.component.html',
  styleUrls: ['./field-map.component.scss'],
})
export class FieldMapComponent implements OnInit, OnChanges, OnDestroy {

  @Input() fieldBoundaries: Array<{ latitude: number, longitude: number }>
  @Input() fieldBoundariesImported: Array<{ latitude: number, longitude: number }>
  @Input() editableMap

  ngOnChanges(changes: SimpleChanges) {
    if (changes.fieldBoundariesImported && !changes.fieldBoundariesImported.firstChange) {
      this.drawHandlesImported();
      this.fieldBoundariesMarkers.forEach(marker => {
        marker.dragging.disable(); // Disable dragging for each marker
      });
    }
  }

  map;
  options: any;
  fieldPositionMarker: Marker;
  // fieldBoundariesMarkers: Marker;
  animateCircleMarker: Marker;
  fieldBoundariesMarkers: L.Marker[] = [];
  fieldBoundariesImportedMarkers: L.Marker[] = [];
  isfieldBoundariesImportedEditable: boolean = false;
  polyline: L.Polyline;
  polylineImported: L.Polyline;
  constructor(
    public store: Store,
  ) {
  }

  ngOnInit() {
    this.showMap();
  }

  ngOnDestroy() {
  }

  getUpdatedFieldBoundaries(): Array<{ latitude: number, longitude: number }> {
    return this.fieldBoundariesMarkers.map(marker => {
      const latLng = marker.getLatLng();
      return { latitude: latLng.lat, longitude: latLng.lng };
    });
  }

  /*
  * Show map.
  */
  showMap() {

    this.options = {
      layers: [
        L.tileLayer('https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', {
          maxZoom: 20,
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        }),
      ],
      zoom: 16,
      center: L.latLng(51.505, -0.09),
    };
  }

  drawHandles() {
    this.fieldBoundaries.forEach((data, index) => {
      const fieldMarker = L.marker([data.latitude, data.longitude], {
        icon: L.icon({
          iconUrl: 'assets/leaflet/circle-orange.svg',
          iconSize: [25, 25],
          shadowSize: [25, 25],
        }),
        draggable: this.editableMap,
      });

      // Handle marker drag
      fieldMarker.on('dragend', () => {
        this.drawLineBetweenMarkers();
      });

      // Right-click (contextmenu) event for deletion
      fieldMarker.on('contextmenu', (event) => {
        this.confirmDeleteMarker(fieldMarker);
      });

      fieldMarker.addTo(this.map);
      this.fieldBoundariesMarkers.push(fieldMarker);
    });
    this.drawLineBetweenMarkers();
  }

  drawHandlesImported() {
    if (!this.fieldBoundariesImported || this.fieldBoundariesImported.length === 0) {
      return; // Don't draw if empty
    }

    this.fieldBoundariesImported.forEach((data) => {
      const fieldMarker = L.marker([data.latitude, data.longitude], {
        icon: L.icon({
          iconUrl: 'assets/leaflet/circle-blue.svg', // Different color marker for second shape
          iconSize: [25, 25],
          shadowSize: [25, 25],
        }),
        draggable: this.isfieldBoundariesImportedEditable, // Make draggable only if editable is true
      });

      if (this.isfieldBoundariesImportedEditable) {
        fieldMarker.on('dragend', () => {
          this.drawLineBetweenMarkersImported(); // Redraw the second line if editable
        });
      }

      fieldMarker.addTo(this.map);
      this.fieldBoundariesImportedMarkers.push(fieldMarker);
    });
    this.drawLineBetweenMarkersImported(); // Draw the initial blue line
  }

  onMapReady(map: L.Map): void {
    setTimeout(() => {
      map.invalidateSize();
    }, 100);
    this.map = map;
    this.drawHandlesImported();
    this.drawHandles();
    const center = this.polyline.getCenter();
    this.map.panTo(center);

  }

  drawLineBetweenMarkers(): void {
    // Remove the old line if it exists
    if (this.polyline) {
      this.map.removeLayer(this.polyline);
    }

    if (this.fieldBoundariesMarkers.length > 1) {
      const latlngs = this.fieldBoundariesMarkers.map(marker => marker.getLatLng());
      latlngs.push(latlngs[0]); // Close the polygon

      // Draw the polyline
      this.polyline = L.polyline(latlngs, { color: '#FF8C17', weight: 5 }).addTo(this.map); // orange line

      this.polyline.on('click', (event) => {
        const clickedPoint = event.latlng;
        this.addMarkerBetweenPoints(clickedPoint);
      });
    }
  }

  drawLineBetweenMarkersImported() {
    if (this.polylineImported) {
      this.map.removeLayer(this.polylineImported);
    }

    if (this.fieldBoundariesImportedMarkers.length > 1) {
      const latlngs = this.fieldBoundariesImportedMarkers.map(marker => marker.getLatLng());
      latlngs.push(latlngs[0]);

      this.polylineImported = L.polyline(latlngs, { color: '#0099FF', weight: 5 }).addTo(this.map); // Blue line
    }
  }

  addMarkerBetweenPoints(clickedPoint: L.LatLng): void {
    if (!this.editableMap) return;
    let closestSegmentIndex = -1;
    let minDistance = Infinity;

    // Find the closest segment to the clicked point
    for (let i = 0; i < this.fieldBoundariesMarkers.length; i++) {
      const p1 = this.fieldBoundariesMarkers[i].getLatLng();
      const p2 = this.fieldBoundariesMarkers[(i + 1) % this.fieldBoundariesMarkers.length].getLatLng();

      const midpoint = L.latLng((p1.lat + p2.lat) / 2, (p1.lng + p2.lng) / 2);
      const distance = clickedPoint.distanceTo(midpoint);

      if (distance < minDistance) {
        minDistance = distance;
        closestSegmentIndex = i; // Store the index of the closest segment
      }
    }

    if (closestSegmentIndex !== -1) {
      const p1 = this.fieldBoundariesMarkers[closestSegmentIndex].getLatLng();
      const p2 = this.fieldBoundariesMarkers[(closestSegmentIndex + 1) % this.fieldBoundariesMarkers.length].getLatLng();

      // Calculate midpoint
      const midpoint = L.latLng((p1.lat + p2.lat) / 2, (p1.lng + p2.lng) / 2);

      // Create and add new marker at midpoint
      const newMarker = L.marker(midpoint, {
        icon: L.icon({
          iconUrl: 'assets/leaflet/circle-orange.svg',
          iconSize: [25, 25],
          shadowSize: [25, 25],
        }),
        draggable: true,
      }).addTo(this.map);

      newMarker.on('dragend', () => {
        this.drawLineBetweenMarkers();
      });

      // Add contextmenu (right-click) event for deletion
      newMarker.on('contextmenu', (event) => {
        this.confirmDeleteMarker(newMarker); // Insert new marker at the correct position
      });

      this.fieldBoundariesMarkers.splice(closestSegmentIndex + 1, 0, newMarker);

      this.drawLineBetweenMarkers();
    }
  }

  confirmDeleteMarker(marker: L.Marker) {
    const markerId = (marker as any)._leaflet_id;
    const popupContent = `<button id="confirm-delete-btn-${markerId}"
                              class="plain-btn"
                              style="background: #D50B0F;
                              border: none;
                              border-radius: 7px;
                              color: #fff;
                              width: 27px;
                              padding: 4px 3px;"><i class="fa-solid fa-trash"></i></button>`;

    marker.bindPopup(popupContent).openPopup();

    const deleteButton = document.getElementById(`confirm-delete-btn-${markerId}`);

    if (deleteButton) {
      deleteButton.onclick = () => {
        this.deleteMarker(marker);
        marker.closePopup();
      };
    }
  }

  deleteMarker(marker: L.Marker): void {
    const markerId = (marker as any)._leaflet_id;
    marker.off();
    this.map.removeLayer(marker);

    const markerIndex = this.fieldBoundariesMarkers.findIndex(m => (m as any)._leaflet_id === markerId);
    if (markerIndex !== -1) {
      this.fieldBoundariesMarkers.splice(markerIndex, 1);
    }

    this.drawLineBetweenMarkers();
  }

}
