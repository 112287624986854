import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CustomerEquipmentService } from '@services/api.service';

@Component({
  selector: 'app-equip-edit',
  templateUrl: './equip-edit.component.html',
  styleUrl: './equip-edit.component.scss'
})
export class EquipEditComponent {

  id: string;
  showForm = false;
  // {
  //   id: '1',
  //   name: 'Tractor',
  //   vin: 'WSXFT40200LD10624',
  //   status: 'linked',
  //   type: 'tractor',
  //   picture: 'https://media.istockphoto.com/id/114334381/photo/tractor-in-plowed-field.jpg?s=612x612&w=0&k=20&c=9lOeCjl3sv3JrAanSCp6RB3wwRjpIdwejZN9FoCApqU=',
  //   position: {
  //     latitude : 45.55419309532645,
  //     longitude : 9.723028638170465
  //   }
  // }
  constructor (
    public route: ActivatedRoute,
    private equipmentService: CustomerEquipmentService,
    public router: Router,
    public store: Store
  ) {
    this.route.params.subscribe(params => {
      this.id = params.id;
      if (this.id === 'new') this.showForm = true
    });
  }

  ngOnInit() {
  }
}
