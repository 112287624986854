import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerDto, GeoViewVehicleDto, VehicleDto } from '@services/api.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { OverviewService } from '@services/overview.service';
import { Store } from '@ngrx/store';
import { selectFilters, selectGeoviewVehicles, selectSelectedVehicle } from '@redux/modules/geoview';
import { setGeoviewFilters, setSelectVehicle } from '@redux/modules/geoview/actions';
import { StatusValue } from '@shared/_types/StatusMatrix';
import * as _ from 'lodash';
import { Item, makeDatasource, Workflow } from 'vscroll';
import { UserRole } from '@models/user';

const Datasource = makeDatasource();

@Component({
  selector: 'app-search-tractor',
  templateUrl: './search-tractor.component.html',
  styleUrls: ['./search-tractor.component.scss'],
})
export class SearchTractorComponent implements OnInit, OnDestroy {

  vehicles;
  items: Item<GeoViewVehicleDto>[] = [];
  @Input() activeVehicles: number;
  @Input() totalVehicles: number;
  // @Input() customers: CustomerDto[];
  @Input() selectedVehicle: VehicleDto;
  @Input() isLoading: boolean;
  @Output() zoomOnThisVehicleEvent = new EventEmitter<VehicleDto>();
  @Output() selectedCustomerEvent = new EventEmitter<CustomerDto>();
  @Output() selectedVinEvent = new EventEmitter<string>();
  @Output() selectedVehicleStatusEvent = new EventEmitter<StatusValue>();
  opened: boolean;
  selectedItem: VehicleDto;
  allVehicles: GeoViewVehicleDto[];
  myControl = new FormControl('');
  filteredVins: Observable<VehicleDto[]>;

  enableSelectByCustomer = new FormControl(false);

  form = new FormGroup({
    auth0UserId: new FormControl(null),
    auth0UserEmail: new FormControl(null),
  });
  initialCustomer = '';
  selectedVin: string;
  subscription: Subscription;
  runningOnly = undefined;
  alarmsOnly = undefined;
  maintenanceOnly = undefined;
  workflow: Workflow;
  dataTraffic: string;
  Role = UserRole;
  constructor(
    private router: Router,
    private overviewService: OverviewService,
    private store: Store,
  ) {
    this.vehicles = new Datasource({
      get: (index, count, success) => {
        console.log('get: ' + index + ' ' + count);
        const data = _.slice(this.allVehicles, index, index + count);
        success(data);

      },
      settings: {
        startIndex: 0,
        bufferSize: 10,
        minIndex: 0,
        padding: 0.5,

      },
    });

  }

  ngOnDestroy(): void {
    this.store.dispatch(setSelectVehicle({ value: null }));
  }

  ngOnInit(): void {


    this.store.select(selectFilters).pipe(filter(d => d)).subscribe((filters) => {
      if (!_.isNil(filters.vehicleStatus)) {
        this.runningOnly = filters.vehicleStatus?.active;
        this.alarmsOnly = filters.vehicleStatus?.alarm;
        this.maintenanceOnly = filters.vehicleStatus?.maintenance;
      }
      this.opened = filters.opened;
      this.selectedVin = filters.q;
      this.dataTraffic = filters.dataTraffic;
    });
    this.store.select(selectGeoviewVehicles).pipe(filter(vehicles => vehicles)).subscribe(vehicles => {

      this.totalVehicles = vehicles.length;
      this.allVehicles = vehicles;
      this.vehicles.adapter.reload(0);
      this.isLoading = false;
    });
    this.store.select(selectSelectedVehicle).pipe(filter(v => v)).subscribe((vehicle) => {
      console.log('selectedItem: ' + vehicle.vin);

      this.selectedItem = vehicle;
      if (vehicle) {
        setTimeout(() => {
          this.vehicles.adapter.reload(this.allVehicles.findIndex(v => v.vin === vehicle.vin));

        }, 10);
      }
    });
    this.myControl.valueChanges.pipe(debounceTime(500), filter(v => !!v)).subscribe((value) => {
      this.store.dispatch(setGeoviewFilters({ filters: { q: value } }));
    });
  }

  onEdit(event, item) {
    event.stopPropagation();
    this.selectedItem = item;

  }

  onDetailClick(vehicle) {
    this.router.navigate([`/portal/tractor-mgmt/tractor-detail/${vehicle.vin}`]);
  }

  onCenterZoomClick() {
    this.zoomOnThisVehicleEvent.emit(this.selectedItem);
  }

  onVehicleSelection(vehicle: GeoViewVehicleDto) {
    this.selectedItem = vehicle;
    this.zoomOnThisVehicleEvent.emit(vehicle);
  }

  saveOpen(event) {
    this.store.dispatch(setGeoviewFilters({ filters: { opened: event } }));
  }

  onCustomerFilterSelection(customer: CustomerDto) {
    this.selectedCustomerEvent.emit(customer);
  }

  onVinFilterSelection(vin: string) {
    this.selectedVinEvent.emit(vin);
  }

  // onVehicleStatusFilterSelection($event: MatSlideToggleChange) {
  onVehicleStatusFilterSelection(val, key) {
    this[key] = val;
    console.log('runningOnly: ' + this.runningOnly);
    console.log('alarmsOnly: ' + this.alarmsOnly);
    console.log('maintenanceOnly: ' + this.maintenanceOnly);
    ///find value from finalStatusMatrix
    this.selectedVehicleStatusEvent.emit({
      active: this.runningOnly,
      alarm: this.alarmsOnly,
      maintenance: this.maintenanceOnly,
    });
  }

  updateDataTraffic(event) {
    this.store.dispatch(setGeoviewFilters({ filters: { dataTraffic: event } }));
  }

  resetFilters() {
    this.runningOnly = undefined;
    this.alarmsOnly = undefined;
    this.maintenanceOnly = undefined;
    this.selectedVehicleStatusEvent.emit({
      active: this.runningOnly,
      alarm: this.alarmsOnly,
      maintenance: this.maintenanceOnly,
    });
  }


  deselectCustomerFilter($event: MatCheckboxChange) {
    if (!$event.checked) {
      // emit a null select for disabling the Customer Filter
      this.initialCustomer = '';
      this.selectedCustomerEvent.emit(null);
    }
  }

  onVinFilterClear() {
    this.selectedVin = '';
    this.store.dispatch(setGeoviewFilters({ filters: { q: null } }));
  }

  isUnknown(severity) {
    return severity === 'Unknown';
  }

}
