import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as Actions from './actions';
import * as RootActions from '../../actions';

export interface State {
  period: {
    startDate?: Date,
    endDate?: Date,
  },
  data: any;
  loading: boolean;

}


export const initialState: State = {
  period: {
    startDate: null,
    endDate: null,
  },
  data: {},
  loading: false,
};
export const selectUserDashboardState = createFeatureSelector<State>('userDashboard');


export const selectDashboardData = createSelector(
  selectUserDashboardState,
  (state: State) => state.data,
);
export const selectDashboardPeriod = createSelector(
  selectUserDashboardState,
  (state: State) => state.period,
);
export const selectDashboardLoading = createSelector(
  selectUserDashboardState,
  (state: State) => state.loading,
);

export const userDashboardReducer = createReducer(
  initialState,
  on(RootActions.resetState, () => ({ ...initialState })),
  on(Actions.setDashboardPeriod, (state, { startDate, endDate }) => ({ ...state, period: { startDate, endDate } })),
  on(Actions.setDashboardData, (state, { data }) => ({
    ...state, data: {
      ...data,
    },
  })),
  on(Actions.setUserDashboardLoading, (state, { value }) => ({ ...state, loading: value })),
);
