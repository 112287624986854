import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectUser } from '@redux/index';
import { selectSbdLoading, selectSbdVehicles, selectSbdVehiclesCoords } from '@redux/modules/sbd';
import { getSbdVehicles, setSbdLoading, setSbdFilters } from '@redux/modules/sbd/actions';
import { VehicleSbdCoords } from '@shared/_models/overview';
import { SbdDto, Auth0UserDetailDto } from '@shared/_services/api.service';
import { StatusValue } from '@shared/_types/StatusMatrix';
import { filter } from 'rxjs';

@Component({
  selector: 'app-sbd-page',
  templateUrl: './sbd-page.component.html',
  styleUrls: ['./sbd-page.component.scss']
})
export class SbdPageComponent  implements OnInit {

  vehicles: SbdDto[];
  isLoading: boolean;
  user: Auth0UserDetailDto;
  vehicleToZoom: SbdDto;
  vehiclesCoords: VehicleSbdCoords[];

  constructor(
    private store: Store,) { }

  ngOnInit() {
    this.isLoading = true;
    this.store.select(selectUser).pipe(filter(user => user != null)).subscribe(user => {
      this.user = user;
    });
    this.store.dispatch(getSbdVehicles({ user_id: this.user.user_id }));
    this.store.dispatch(setSbdLoading({ value: true }));

    this.store.select(selectSbdLoading).subscribe(isLoading => {
      this.isLoading = isLoading;
    });
    this.store.select(selectSbdVehicles).pipe(filter(vehicles => vehicles)).subscribe(vehicles => {
      this.vehicles = vehicles;
      this.isLoading = false;
    });
    this.store.select(selectSbdVehiclesCoords).subscribe(vehiclesCoords => {
      this.vehiclesCoords = vehiclesCoords;
    });
  }

  zoomOnVehicle(vehicle: SbdDto) {
    this.vehicleToZoom = vehicle;
  }

  filterByVehicleStatus(vehicleStatus: StatusValue) {
    this.store.dispatch(setSbdFilters({ filters: { vehicleStatus } }));
  }

}
