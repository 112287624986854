// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hidden {
  visibility: hidden;
}

.loader-overlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 1001;
  top: 0;
  background-color: #999999;
  opacity: 0.9;
}

.lds-hourglass {
  display: inline-block;
  position: relative;
  left: calc(50vw - 50px);
  top: calc(50vh - 80px);
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
