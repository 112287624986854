import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as fromActions from './actions';
import { Store } from '@ngrx/store';
import { VehicleService } from '@services/api.service';
import { promiseWrapper } from '@shared/_funtions/promiseWarapper.function';
import { Sort } from '@angular/material/sort';
import { selectUser } from '@redux/index';

@Injectable()
export class VehicleAlarmsEffectsEffects {
  private filters: any;
  private sort: Sort;
  public getData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getAlarmList),
      tap(action => {
        this.sort = action.sort || {
          active: 'alarmStartDateTime',
          direction: 'desc',
        };
      }),
      tap(() => this.store.dispatch(fromActions.setAlarmLoading({ value: true }))),
      withLatestFrom(selectUser),
      switchMap(async ([action, user]) => {
        const { q, ...filterModel } = this.filters || {};
        const vehiclesData = await promiseWrapper(this.vehicleService
          .getVehicleAlarmsHistory({
            startRow: (action.pageIndex - 1) * action.pageSize,
            endRow: action.pageIndex * action.pageSize - 1,
            language: user?.user_metadata?.language || 'en',
            qSearch: q,
            filterModel,
            ...(this.sort ? {
              sortModel: [
                {
                  colId: this.sort.active,
                  sort: this.sort.direction,
                },
              ],

            } : {}),
          }));

        return [fromActions.setAlarmList({
          alarmsList: vehiclesData.data,
          page: vehiclesData.currentPage,
          pageSize: action.pageSize,
          totalNumber: vehiclesData.totalCount,
        })];
      }),
      switchMap((actions) => [...actions, fromActions.setAlarmLoading({ value: false })]),
    );
  });

  // public $setFilters = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(fromActions.setAlarmFilters),
  //     tap(action => {
  //       this.filters = action.filters;
  //     }),
  //     switchMap((actions) => [fromActions.getAlarmList({ pageIndex: 1, pageSize: this.pageSize || 10 })]),
  //   );
  // });

  constructor(
    private actions$: Actions,
    private store: Store,
    private vehicleService: VehicleService,
  ) {
  }
}
