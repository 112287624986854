// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container {
  background-color: #F8F8FA;
  overflow: hidden;
}

.main-body {
  background: #f6f6f6;
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.sidebar {
  transition: 0.3s all ease;
  width: 200px;
}

.width0 {
  width: 0 !important;
  opacity: 0;
  transition: 0.3s all ease;
}

.widthIcons {
  width: 60px !important;
  transition: 0.3s all ease;
  overflow-x: hidden;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
