import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, tap } from 'rxjs/operators';
import * as fromActions from './actions';
import { Store } from '@ngrx/store';
import { CustomerKpiService } from '@services/api.service';
import { promiseWrapper } from '@shared/_funtions/promiseWarapper.function';

@Injectable()
export class UserDashboardEffects {
  private filters: any;

  public $setPeriod = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.setDashboardPeriod),
      tap(action => {
        this.filters = action;
      }),
      switchMap((actions) => [fromActions.loadDashboard({
        startDate: actions.startDate,
        endDate: actions.endDate,
      })]),
    );
  });


  public getData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.loadDashboard),
      tap(action => {
        console.log('loadDashboard', action);
        this.filters = action;
      }),
      tap(() => this.store.dispatch(fromActions.setUserDashboardLoading({ value: true }))),
      switchMap(async () => {
        const { startDate, endDate } = this.filters;
        const fuelConsumption = await promiseWrapper(this.kpiService.getVehiclesFuelConsumptionPerDay(startDate, endDate));
        const profileMission = await promiseWrapper(this.kpiService.getVehiclesMissionProfile(startDate, endDate));
        const equipmentUsage = await promiseWrapper(this.kpiService.getEquipmentsUsage(startDate, endDate));
        const co2Produced = await promiseWrapper(this.kpiService.getVehiclesCo2Produced(startDate, endDate));
        const vehicleUsage = await promiseWrapper(this.kpiService.getWorkedVehicle(startDate, endDate));
        const totalVehicle = await promiseWrapper(this.kpiService.getWorkedVehiclesTotal(startDate, endDate));
        const maintenanceStatus = await promiseWrapper(this.kpiService.getMaintenanceStatus());
        const treatmentsTotal = await promiseWrapper(this.kpiService.getTreatmentsTotal(startDate, endDate));
        return [fromActions.setDashboardData({
          data: {
            fuelConsumption,
            profileMission,
            equipmentUsage,
            co2Produced,
            totalVehicle,
            vehicleUsage,
            maintenanceStatus,
            treatmentsTotal
          },
        })];
      }),
      switchMap((actions) => [...actions, fromActions.setUserDashboardLoading({ value: false })]),
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store,
    private kpiService: CustomerKpiService,
  ) {
  }
}
