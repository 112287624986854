import { Component, OnInit } from '@angular/core';
import { ActiveAlarmCounterDto, FileResponse, VehicleService } from '@services/api.service';
import { Store } from '@ngrx/store';
import { selectPagination } from '@redux/modules/vehicleAlarms';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Pagination } from '@shared/shared.interface';
import { selectActiveAlarmSummaryData } from '@redux/modules/alarms';
import { setActiveAlarmFilters } from '@redux/modules/alarms/actions';

@Component({
  selector: 'app-alarms',
  templateUrl: './alarms.component.html',
  styleUrls: ['./alarms.component.scss'],
})
export class AlarmsComponent implements OnInit {
  currentFilter = 'total';
  isExportDownloading = false;
  summaryData: ActiveAlarmCounterDto;
  pagination$: Observable<Pagination> = this.store.select(selectPagination);
  vin = new FormControl();
  ecu = new FormControl();
  spn = new FormControl();
  fmi = new FormControl();
  storeFilters: any;

  constructor(
    private vehicleService: VehicleService,
    private store: Store,
  ) {
  }

  ngOnInit(): void {
    this.store.select(selectActiveAlarmSummaryData).subscribe((data) => {
      this.summaryData = data;
    });
    this.vin.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      this.store.dispatch(setActiveAlarmFilters({
        filters: {
          ...this.storeFilters,
          q: value || undefined,
        },
      }));
    });
    this.ecu.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      this.store.dispatch(setActiveAlarmFilters({
        filters: {
          ...this.storeFilters,
          ...(
            value
              ? {
                concatenatedEcuAddress: {
                  filterType: 'string',
                  type: 'contains',
                  filter: value,
                },
              }
              : { concatenatedEcuAddress: undefined }
          ),
        },
      }));

    });
    this.spn.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      this.store.dispatch(setActiveAlarmFilters({
        filters: {
          ...this.storeFilters,
          ...(
            value
              ? {
                concatenatedSpn: {
                  filterType: 'string',
                  type: 'contains',
                  filter: value,
                },
              }
              : { concatenatedSpn: undefined }
          ),
        },
      }));
    });
    this.fmi.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
      this.store.dispatch(setActiveAlarmFilters({
        filters: {
          ...this.storeFilters,
          ...(
            value
              ? {
                concatenatedFmi: {
                  filterType: 'string',
                  type: 'contains',
                  filter: value,
                },
              }
              : { concatenatedFmi: undefined }
          ),
        },
      }));
    });
  }


  updateCurrentFilter(value) {
    if (this.currentFilter === value) {
      value = 'total';
    }
    this.currentFilter = value;
    switch (value) {
      case 'total':
        this.store.dispatch(setActiveAlarmFilters({
          filters: {
            ...this.storeFilters,
            highAlarmCounter: undefined,
            lowAlarmCounter: undefined,
            mediumAlarmCounter: undefined,
          },
        }));
        break;
      case 'low':
        this.store.dispatch(setActiveAlarmFilters({
          filters: {
            ...this.storeFilters,
            lowAlarmCounter: {
              filterType: 'integer',
              type: 'greaterThan',
              filter: '0',
            },
            mediumAlarmCounter: undefined,
            highAlarmCounter: undefined,
          },
        }));
        break;
      case 'medium':
        this.store.dispatch(setActiveAlarmFilters({
          filters: {
            ...this.storeFilters,
            lowAlarmCounter: undefined,
            mediumAlarmCounter: {
              filterType: 'integer',
              type: 'greaterThan',
              filter: '0',
            },
            highAlarmCounter: undefined,
          },
        }));
        break;
      case 'high':
        this.store.dispatch(setActiveAlarmFilters({
          filters: {
            ...this.storeFilters,
            lowAlarmCounter: undefined,
            mediumAlarmCounter: undefined,
            highAlarmCounter: {
              filterType: 'integer',
              type: 'greaterThan',
              filter: '0',
            },
          },
        }));
        break;
    }

  }

  getExport() {
    this.isExportDownloading = true;
    this.vehicleService.getAllVehicleAlarmsExportXlsx('it').subscribe((response: FileResponse) => {
      this.isExportDownloading = false;
      const dataType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const binaryData = [];
      binaryData.push(response.data);
      const downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
      const filename = 'export_' + new Date().toLocaleString();
      downloadLink.setAttribute('download', filename);
      document.body.appendChild(downloadLink);
      downloadLink.click();
    });
  }
}
