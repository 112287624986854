import { inject, Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { promiseWrapper } from '@shared/_funtions/promiseWarapper.function';

@Injectable()
export class RoleGuard {
  constructor(public auth: AuthService, public router: Router,
  ) {
  }

  async canActivate(route): Promise<boolean> {
    if (!this.auth.isAuthenticated$) {
      this.router.navigate(['login']);
      return false;
    }
    const userRoles = (await promiseWrapper(this.auth.user$));
    console.log(userRoles);
    if (route.data.allowedRoles.includes(userRoles['sdf-user-roles'][0])) {
      return true;
    } else {
      this.router.navigate(['/portal/user-entry']);
      return false;
    }
  }
}

export const canActivateRole: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  return inject(RoleGuard).canActivate(route);
};
