import { Component, Inject, OnInit } from '@angular/core';
import { MaintenanceOperationDto, MaintenanceService } from '@services/api.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { selectUser } from '@redux/index';
import { filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';

@Component({
  selector: 'app-maintenance-steps-modal',
  templateUrl: './maintenance-steps-modal.component.html',
  styleUrls: ['./maintenance-steps-modal.component.scss'],
})
export class MaintenanceStepsModalComponent implements OnInit {
  groupedChecks: { [key: string]: MaintenanceOperationDto[] };
  private language: string;

  constructor(
    public dialogRef: MatDialogRef<MaintenanceStepsModalComponent>,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public data: any, private maintenanceService: MaintenanceService) {
  }

  ngOnInit(): void {
    this.store.select(selectUser).pipe(filter(user => user != null)).subscribe(user => {
      this.language = user.user_metadata.language;
      this.getChecks(this.data.interval);

    });

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  copy(text: string) {
    navigator.clipboard.writeText(text);
  }
  getChecks(inteval) {
    this.maintenanceService.getMaintenanceOperations(inteval, this.language)
      .subscribe(result => {
        console.log(result);
        if (result) {
          this.groupedChecks = _.groupBy(result, 'type');
        }
      });
  }

}
