import { Injectable } from '@angular/core';
import { MessageComponent } from '../components/message/message.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private dialog: MatDialog) { }

  openDialog(title: string, text: string, isConfirm: boolean): MatDialogRef<MessageComponent, any> {
    return this.dialog.open(MessageComponent, {
      width: '520px',
      height: '350px',
      panelClass: 'message-modal',
      data: {title, text, isConfirm},
    });
  }

}
